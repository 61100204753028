import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import Layout from "../Layout/Layout";
import PopupScreen from "./PopupScreen";
import { notify } from "../Listing/Toast";
import styles from "./seller.module.css";

import {
  sellTicketDataPost,
  addPrePostTicketData,
  removePrePostTicketData,
  sellTicketDataVerifyPost,
} from "../../redux/actions/ticket";
import Label from "./Label";
import ProgressBar from "../ProgressBar/ProgressBar";
import axios from '../../axios-api'
import setMixPanel from "../../utils/setMixPanel";

const PriceTicketForm = ({
  auth: { userData },
  ticket: { listTicketPrePostData },
  event: { sellerFlowEvent },
  sellTicketDataPost,
  addPrePostTicketData,
  removePrePostTicketData,
  sellTicketDataVerifyPost,
}) => {
  useEffect(() => {    
  },[])

  const Headerr = ({ head, showParty }) => {
    const party = showParty ? "\u{1F973}" : "";

    return (
      <div className={`${styles.headdContainer}`}>
        <h1 className={styles.headerr}>
          {head}
          {party}
        </h1>
      </div>
    );
  };
  const navigate = useNavigate();
  const [price, setPrice] = useState("");
  const [priceError, setPriceError] = useState("");
  const [popToggle, setPopToggle] = useState(false);
  const [termsChecked, setTermsCheck] = useState(false);
  const [numberOfTickets, setNumberOfTickets] = useState("");
  const [numberOfTicketsError, setNumberOfTicketsError] = useState("");
  const categoryData = sellerFlowEvent?.category?.filter(
    (cat) => cat.categoryName === listTicketPrePostData.categoryID
  )[0];
  const head = `Price your ${listTicketPrePostData.categoryID} tickets`;

  // Function to round a number to the nearest specified multiple
  function roundToNearest(number, multiples) {
    const roundedValues = multiples.map(
      (multiple) => Math.round(number / multiple) * multiple
    );
    const differences = roundedValues.map((value) => Math.abs(number - value));
    const minDifference = Math.min(...differences);
    const index = differences.indexOf(minDifference);
    return roundedValues[index];
  }

  const mrp = listTicketPrePostData?.categoryPrice || 0;

  const upperBoundMrp = +mrp + mrp * 0.07;

  const firstSuggestedPrice =
    roundToNearest(Math.floor(mrp - 100), [50, 100]) < 0
      ? 0
      : roundToNearest(Math.floor(mrp - 100), [50, 100]);
  const secondSuggestedPrice =
    roundToNearest(Math.floor(mrp - 50), [50, 100]) < 0
      ? 0
      : roundToNearest(Math.floor(mrp - 50), [50, 100]);
  const thirdSuggestedPrice =
    Math.floor(mrp) < 0
      ? 0
      : Math.floor(mrp);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    let hasError = false;
    if (numberOfTickets === "") {
      hasError = true;
      setNumberOfTicketsError("This field is required");
    } else {
      setNumberOfTicketsError("");
    }

    if (price === "" || price === "0") {
      hasError = true;
      setPriceError("This field is required");
    } else {
      setPriceError("");
    }

    let data = {
      ...listTicketPrePostData,
      ticketPrice: Number(price),
      ticketCount: Number(numberOfTickets),
    };

    if (hasError) {
      notify("Oops!! some error has occured please try again by refreshing!!");
    }
    await addPrePostTicketData(data);
    if (!hasError) {
      if (
        userData.mobile &&
        userData.mobile != null &&
        userData.email &&
        userData.email != null
      ) {

        setPopToggle(true);
      } else {
        navigate("/account-details");
      }
    }
  };

  const handleBack = () => {
    window.history.back();
  };

  const handlePopupClose = () => {
    setPopToggle(false);
  };

  const handlePopupConfirm = async () => {
    if (sellerFlowEvent.isVerificationEvent) {
      const payload = {
        eventID: sellerFlowEvent._id,
        verificationId:listTicketPrePostData.verificationId,
        ticketCount: listTicketPrePostData.ticketCount,
        categoryName: listTicketPrePostData.categoryID,
        ticketPrice: listTicketPrePostData.ticketPrice,
        termsAgreed: termsChecked,
        maxPrice: mrp,
        maxQuantity: listTicketPrePostData.maxTicketCount,
      };

      const advance = await sellTicketDataVerifyPost(payload);
      if (advance) {
        await removePrePostTicketData();
      }
      return advance;
    }

    const data = {
      ...listTicketPrePostData,
      termsAgreed: termsChecked,
      categoryID: categoryData._id,
    };

    const advance = await sellTicketDataPost(data);
    if (advance) {
      await removePrePostTicketData();
    }
    return advance;
  };

  return (
    <Layout className={styles.priceTicketFormLayout} isfooter={window.innerWidth < 600?false:true}>
      <Helmet>
        <title>Sell tickets</title>
        <meta
          name="Sell Tickets Easily in India | Reach Your Audience on Take My Tickets"
          content="Start selling your event tickets hassle-free on Take My Tickets, India's trusted ticket resale platform. Add seller contact details effortlessly."
        />
        <meta charset="UTF-8" />
      </Helmet>
      <div
        className={`${styles.second} ${priceError ? styles.second_height : ""}`}
      >
        <div className={`${styles.formContainer}`}>
          <Headerr head={head} showParty={false} className="ml-32 " />
          <form onSubmit={handleFormSubmit}>
            <div className={styles.inputContainer}>
              <Label
                className={styles.label}
                question="How many tickets do you want to sell?"
              />

              <select
                className={`${styles.numberInput} ${styles.frame} ${
                  styles.inputContainer
                } ${numberOfTicketsError ? styles.error : ""} ${
                  styles.selectCont
                }`}
                value={numberOfTickets}
                onChange={(e) => {
                  setNumberOfTickets(e.target.value);
                  setNumberOfTicketsError("");
                }}
                required
              >
                <option value="" disabled hidden style={{ color: "#A1A1A1" }}>
                  Count
                </option>
                {Array.from(
                  { length: listTicketPrePostData?.ticketCount || 10 },
                  (_, index) => (
                    <option key={index + 1} value={index + 1}>
                      {index + 1} {index + 1 > 1 ? "tickets" : "ticket"}
                    </option>
                  )
                )}
              </select>

              {numberOfTicketsError && (
                <div className={styles.errorMessage}>
                  <img
                    src="/images/icons/warn.svg"
                    alt="Error"
                    className={styles.errorI}
                    loading="lazy"
                  />
                  {numberOfTicketsError}
                </div>
              )}
            </div>

            <Label className={styles.label} question="Set price per ticket" />
            <div
              style={{ height: "60px" }}
              className={`${styles.priceInput} ${styles.frame} flex ${
                priceError ? styles.error : ""
              }`}
            >
              <span className={styles.rupee}>₹</span>
              <input
                className={styles.Input}
                type="number"
                maxLength={6}
                inputMode="numeric"
                placeholder="Enter Price"
                value={price}
                required
                style={{ padding: "8px 0px 10px" }}
                onChange={(e) => {
                  const input = e.target.value.replace(/,/g, "");
                  const regex = /^(?:[0-9]+(?:,[0-9]*)*)?$/;

                  if (
                    regex.test(input) &&
                    (input === "" || Number(input) !== 0)
                  ) {
                    if (input > +mrp) {
                      setPrice(input);
                      setPriceError(
                        "Please enter a price less than what you paid per ticket"
                      );
                      return;
                    }

                    const formatIn = input.replace(
                      /\B(?=(\d{7})+(?!\d))/g,
                      ","
                    );

                    setPrice(formatIn);
                    setPriceError("");
                  }
                }}
              />
              {/* <span className={styles.divider}> | </span> */}
              <span className={styles.divtick}>per ticket</span>
            </div>
            {priceError && (
              <div className={styles.errorMessage}>
                <img
                  src="/images/icons/warn.svg"
                  alt="Error"
                  className={styles.errorI}
                  loading="lazy"
                />
                {priceError}
              </div>
            )}

            {/* 						<p className={styles.tagline}>
							Tickets listed between{' '}
							<span className={styles.price}>₹{lowerBoundMrp} - ₹{upperBoundMrp}</span> sell faster.{' '}
						</p> */}

            <div style={{ marginTop: "28px" }}>
              <p className="text-sm">Suggested prices</p>
              <div className={styles.suggestedPriceContainer}>
                <div
                  className={`${styles.suggestedPrice} ${
                    price == firstSuggestedPrice && styles.focusState
                  }`}
                  onClick={() => {
                    setPriceError("");
                    setPrice(String(firstSuggestedPrice));
                  }}
                >
                  {firstSuggestedPrice}
                  <div className={styles.priceBadge}>Sells Faster</div>
                </div>
                <div
                  className={`${styles.suggestedPrice} ${
                    price == secondSuggestedPrice && styles.focusState
                  }`}
                  onClick={() => {
                    setPriceError("");
                    setPrice(String(secondSuggestedPrice));
                  }}
                >
                  {secondSuggestedPrice}
                </div>
                <div
                  className={`${styles.suggestedPrice} ${
                    price == thirdSuggestedPrice && styles.focusState
                  }`}
                  onClick={() => {
                    setPriceError("");
                    setPrice(String(thirdSuggestedPrice));
                  }}
                >
                  {thirdSuggestedPrice}
                </div>
              </div>
            </div>
            <div className={`${styles.labelWarning}`}>
              Please price your tickets at what you paid, or lower. Selling
              above{" "}
              <b className={`${styles.labelWarningBold}`}>what you paid</b> is
              against the law.
            </div>
            {/* <div className={styles.labelWithWarning}>
							<span className={styles.info}> 💡</span>
							You can edit the price later.
						</div> */}

            <div className={styles.buttonContainer1}>

            <ProgressBar progress={100} bottom={`${window.innerWidth <600? 94: 100}`}/>
            <div
              className={`flex relative justify-between m-auto ${styles.submitButtondiv} ${styles.submitButtondiv2}`}
            >

              <button
                onClick={handleFormSubmit}
                className={`${styles.submitButton} ${
                  price === "" ||
                  Number(price) === 0 ||
                  Number(price) > upperBoundMrp ||
                  numberOfTickets === "" ||
                  priceError !== ""
                    ? styles.btndisabled
                    : ""
                } ${styles["sec-button"]}`}
                // disabled={}
              >
                Proceed
              </button>
            </div>
            </div>
 
          </form>
        </div>
      </div>
      {popToggle ? (
        <PopupScreen
          title="Woohoo! Almost there✨"
          userData={userData}
          listTicketPrePostData={listTicketPrePostData}
          sellerFlowEvent={sellerFlowEvent}          
          message="Before proceeding, make sure you have the tickets and they're authentic."
          confirmButtonText="Agree & Proceed"
          onClose={handlePopupClose}
          setTermsCheck={setTermsCheck}
          onCreateListing={handlePopupConfirm}
        />
      ) : null}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  event: state.event,
  auth: state.auth,
  ticket: state.ticket,
});

export default connect(mapStateToProps, {
  sellTicketDataPost,
  addPrePostTicketData,
  removePrePostTicketData,
  sellTicketDataVerifyPost,
})(PriceTicketForm);
