import React from "react";
import useMenuModal from "../../Hooks/useMenuModal";
import { useState } from "react";
import Modal from "./Modalv2";
import Button from "../Button";
import DropDown from "../DropDown";
import { HiOutlineTicket } from "react-icons/hi";
import { CgProfile } from "react-icons/cg";
import { MdOutlineContactSupport } from "react-icons/md";
import { AiOutlineLogout } from "react-icons/ai";
import { TbCircle } from "react-icons/tb";
import { Link } from "react-router-dom";

const MenuModal = ({ currentUser }) => {
  const menuModal = useMenuModal();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const profile = [
    {
      label: "Listed Tickets",
      url: "my-listing",
      icon: HiOutlineTicket,
    },
    {
      label: "Profile",
      url: "my-profile",
      icon: CgProfile,
    },
    {
      label: "Support",
      url: "support",
      icon: MdOutlineContactSupport,
    },
  ];

  let bodyContent = (
    <div className="flex flex-col font-general-sans">
      <div className="grid grid-cols-1 ">
        {currentUser ? (
          <>
            <div className="absolute z-10 pr-8 mt-[-10px] w-full shadow-sm">
              <div className="rounded-lg bg-whit">
                <div className="px-4 py-2 ">
                  <Button
                    label={currentUser}
                    icon={TbCircle}
                    centered
                    outline
                  />
                </div>
                {profile.map((item) => (
                  <div key={item.label} className="px-4 py-2 ">
                    <Link to={item.url} onClick={menuModal.onClose}>
                      <Button label={item.label} icon={item.icon} outline />
                    </Link>
                  </div>
                ))}
                <hr />
                <div className="px-4 py-2  cursor-pointer">
                  <Button label="Logout" icon={AiOutlineLogout} outline />
                </div>
              </div>
            </div>
            <div className="mt-80">
              <Button label="Sell Tickets" onClick={() => {}} />
            </div>
          </>
        ) : (
          <>
            <div className="gap-4 space-y-4">
              <Button label="Login / Sign up" outline onClick={() => {}} />
              <div>
                <Button label="Sell Tickets" centered onClick={() => {}} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );

  return (
    <Modal
      disabled={false}
      isOpen={menuModal.isOpen}
      onClose={menuModal.onClose}
      body={bodyContent}
    />
  );
};

export default MenuModal;
