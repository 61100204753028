import React from 'react';
import styles from './listing.module.css';

const PopupScreen = ({ onClose, onConfirm, title, message, confirmButtonText }) => {
  return (
    <div className={styles["popup-container"]}>
      <div className={styles.popup}>
        <h2 className={styles["popup-title"]}>{title}</h2>
        <p className={styles["popup-message"]}>{message}</p>
        <div className={styles["popup-buttons"]}>
          <button className={`${styles["popup-button"]} ${styles["sec-button"]}`} onClick={onConfirm}>
            {confirmButtonText}
          </button>
          <button className={`${styles["popup-button"]} ${styles["primary-button"]}`} onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupScreen;
