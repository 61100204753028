import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import EventCard from "../EventCard";
import { Link } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import styles from './Trending.module.css'
import setMixPanel from "./../../utils/setMixPanel"

const Trending = ({ event: { allTrendingEvents, allTrendingEventsLoading }}) => {
  const [nextEl, setNextEl] = useState(null);
  const [prevEl, setPrevEl] = useState(null);
  const swiperRef = useRef(null);
  const [isHovered, setIsHovered] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.navigation.update();
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      const slidesPerView =
        windowWidth >= 1024 ? "auto" : windowWidth > 480 ? 2 : 1;
        if (swiperRef.current && swiperRef.current.params) {
          swiperRef.current.params.slidesPerView = slidesPerView;
          swiperRef.current.update();
        }
    };

    handleResize(); // Initial call

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobileView = window.innerWidth < 480;
  const isTabView = window.innerWidth < 1024;

  let slides = 4.5;
  if (isMobileView) {
    slides = 1;
  } else if (isTabView) {
    slides = 2;
  }

  const handleCardHover = (cardId) => {
    setIsHovered((prevIsHovered) => ({
      ...prevIsHovered,
      [cardId]: true,
    }));
    swiperRef.current.autoplay.stop();
  };

  const handleCardHoverLeave = (cardId) => {
    setIsHovered((prevIsHovered) => ({
      ...prevIsHovered,
      [cardId]: false,
    }));
    const isAnyCardHovered = Object.values(isHovered).some(
      (hovered) => hovered
    );
    if (!isAnyCardHovered) {
      swiperRef.current.autoplay.start();
    }
  };

  const onClickEvent = (location, slug) => {
    navigate(`/${location}/${slug}`);
  };
  return (
    <div className="relative top-[5px] left-[0px] w-full flex flex-col px-[24px] pt-[40px] pb-[40px] md:px-[130px] md:pt-[64px] md:pb-[120px] h-auto box-border items-center justify-start gap-[24px] md:gap-[36px] text-black mb-4 md:mb-0">
      <div className="self-stretch flex flex-row items-center justify-between gap-9">
        <div className="relative whitespace-nowrap text-black tracking-[-0.02em] leading-[40px] font-semibold text-2xl sm:text-2xl not-italic md:text-4xl font-general-sans">
          Trending events near you 🔥
        </div>

        {isMobileView ? null : (
          <Button
            colorScheme="brand"
            as={Link}
            to="/all-events"
            variant="outline"
          >
            Browse more events
          </Button>
        )}
      </div>
      <div className="self-stretch flex flex-row items-center w-full justify-start text-sm text-green-text relative px-2">
        {allTrendingEvents.length > slides && (
          <button
            className="absolute top-1/2 left-0 transform -translate-y-1/2 focus:outline-none z-10"
            onClick={() => swiperRef.current.slidePrev()}
          >
            <img
              src="/images/icons/arrow-circle-right.svg"
              alt="previous"
              className="w-12 h-12 rotate-180"
            />
          </button>
        )}
        {allTrendingEventsLoading === true ? (
          <div style={{color: "black", fontSize: "20px"}}>Loading....</div>
        ) : allTrendingEvents.length > 0 ? (
        <Swiper
          className="min-h-[250px]" // ARBITARY VALUE
          navigation={{
            prevEl: prevEl,
            nextEl: nextEl,
          }}
          spaceBetween={20}
          // need to use package prop will be updated in the future
          slidesPerView={Math.min(allTrendingEvents.length, slides)}
          autoplay={true}
          speed={1000}
          loop={true}
          modules={[Navigation, Autoplay]}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          style={{
            margin: "unset",
          }}
        >
          {allTrendingEvents.map((event) => (
            <SwiperSlide key={event._id}>
              <EventCard
                eventSlug={event.eventSlug}
                eventCity={event.eventCity}
                eventName={event.eventName}
                artistName={event.artistName}
                startPrice={event.startPrice}
                url={event.imageURL}
                eventDate={event.eventDate}
                onChange={onClickEvent}
                onCardHover={() => handleCardHover(event._id)}
                OnCardHoverEnd={() => handleCardHoverLeave(event._id)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        ) : (
          <div className={styles.noeventscontainer}>
              <img src="/images/notrendingevents.svg" alt="Oops, no trending events near you!" className={styles.noeventsimage} />
              <div style={{margin_bottom: '30px', textAlign: 'center', fontSize: '20px', lineHeight: '140%'}}>Oops, no trending events near you!</div>
              <button
              className={styles.noeventsbutton}
              onClick={() => navigate('/request')}>
              Request an Event
            </button>
          </div>
        )}
        {allTrendingEvents.length > slides && (
          <button
            className="absolute top-1/2 right-0 transform -translate-y-1/2 focus:outline-none z-10"
            onClick={() => swiperRef.current.slideNext()}
          >
            <img
              src="/images/icons/arrow-circle-right.svg"
              alt="next"
              className="w-12 h-12"
            />
          </button>
        )}
      </div>
      {isMobileView && (
        <Button
          mb={6}
          colorScheme="brand"
          as={Link}
          to="/all-events"
          variant="outline"
        >
          Browse more events
        </Button>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  event: state.event,
});

export default connect(mapStateToProps, {})(Trending);
