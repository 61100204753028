import React from 'react';

import TestimonialCard from './TestimonialCard';
import styles from "./home.module.css";

const Tweets = [
    {
        imgUrl: "/images/testimonials/@i_sreenivas.jpeg",
        username: "Jojjabijjan Sreenivas || ஸ்ரீநிவாஸ்",
        userId:"@i_sreenivas",
        tweet: "Would’ve sent the link of TakeMyTickets to atleast 100 people this week br Go get the last minute resale tickets for the ARR concert at takemytickets.co.in/chennai/marakk…"
    },
    {
        imgUrl: "/images/testimonials/@thaumaturgist77.jpeg",
        username: "MJ",
        userId:"@thaumaturgist77",
        tweet: "Makkale this site works like a charm. Just got two tickets @arrrahman concert. @takemy_tickets 🫡"
    },{
        imgUrl: "/images/testimonials/@arunsreenath.jpeg",
        username: "arun | spacewar",
        userId:"@arunsrreenath",
        tweet: "@takemy_tickets Randomly got know about this site from here. br Got connected with genuine people and smoothly sold my tickets. 🔥"
    },{
        imgUrl: "/images/testimonials/@beef_parotttaa.jpeg",
        username: "cranberrygin // new job era",
        userId:"@beef_parotttaa",
        tweet: `takemytickets.co.in is a life saver 😭😭❤️❤️ br Just bought ARR concert tickets. Hassle free, transparent process. ❤️`
    },{
        imgUrl: "/images/testimonials/@sd_ganesh.jpeg",
        username: "Kick",
        userId:"@sd_ganesh",
        tweet:"@takemy_tickets Thank you very much. I got my tags and I brought it by actual selling price. The person who sold the tickets to me is very genuine. "
    },{
        imgUrl: "/images/testimonials/@jasscrazy_15.jpeg",
        username: "Blueberry_Breezer",
        userId:"@jasscrazy_15",
        tweet: "You can also list it on takemytickets website"
    }
]

const  Testimonials2 = () => {


    return (
        <div className={styles.testimonialContainer}>
           <h1 className={styles.testimonialTitle}>
                Echoes of {window.innerWidth<600?<br/>:<></>}excitement: Our fans<br/> 
                <span className={styles.titleHighlight}>spread the word</span>
            </h1>

            <div className={styles.tweetContainer}>
                {Tweets.map((t, index)=>
                    <TestimonialCard
                        key={index}
                        srcUrl={t.imgUrl}
                        username={t.username}
                        userId={t.userId}
                        tweet={t.tweet}
                    />
                )}
            </div>
        </div>
    );
}

export default Testimonials2;