import React from 'react';
import styles from "./home.module.css";

const  FeatureCard = ({imgUrl,alt,title,text}) => {
    return (
        <div className={styles.verify_card}>
            <img src={imgUrl} alt={alt} className={styles.verify_cardImg}/>

            <div className={styles.verify_cardContainer}>
                <h3 className={styles.verify_cardTitle}>{title}</h3>
                <p className={styles.verify_cardText}>
                   {text}
                </p>
            </div>
        </div>
    );
}

export default FeatureCard;