import React, { useState } from "react";

function EventCard({
  artistName,
  eventName,
  startPrice,
  url,
  eventDate,
  onChange,
  onCardHover,
  OnCardHoverEnd,
  eventCity,
  eventSlug,
}) {
  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);

    const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      date
    );
    const monthAbbreviated = month.slice(0, 3);
    const day = date.getDate();

    return {
      monthAbbreviated,
      day,
    };
  }

  const { monthAbbreviated, day } = formatTimestamp(eventDate);

  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
    onCardHover && onCardHover();
  };

  const handleHoverLeave = () => {
    setIsHovered(false);
    OnCardHoverEnd && OnCardHoverEnd();
  };

  return (
    <div
      className="relative rounded-lg mb-5 bg-white shadow-sm w-full overflow-hidden shrink-0 cursor-pointer text-left text-sm text-black font-general-sans border border-date-stroke"
      onMouseEnter={handleHover}
      onMouseLeave={handleHoverLeave}
      onClick={() => onChange(eventCity.toLowerCase(), eventSlug)}
    >
      <div className="absolute top-[0px] left-[0px] filter blur-[18px] w-full h-166px" />
      <div
        className={`w-full h-[166px] overflow-hidden flex flex-row items-start justify-start transition-all duration-300 ${
          isHovered ? "scale-105" : "scale-100"
        }`}
      >
        <img
          className="flex-1 relative max-w-full overflow-hidden h-[166px] object-cover transform transition-all duration-300"
          alt={eventName}
          src={url}
        />
      </div>
      <div className="flex flex-col gap-1 px-4 py-2 pt-[37px]  w-full text-xl">
        <div className="tracking-[-0.5px] mt-3 font-semibold  min-h-[60px]">
          {eventName.length > 40
            ? eventName.substring(0, 36) + "..." // turncate length.
            : eventName}
        </div>
        <div className="w-full flex flex-col items-start justify-start text-base text-secondary-grey">
          <div className="self-stretch  flex flex-row items-center justify-start tracking-[-0.5px] truncate">
            {artistName ?? "artistName"}
          </div>
        </div>
      </div>
      <div className="absolute top-1/2 left-4 rounded-md bg-white shadow-sm flex flex-col p-1.5 mb-4 items-center justify-center gap-[2px] text-center text-brand border-[0.5px] border-solid border-date-stroke">
        <div className="relative tracking-[-1px] leading-[102.7%] font-semibold inline-block w-8">
          {monthAbbreviated}
        </div>
        <div className="relative text-xl tracking-[-1px] leading-[102.7%] font-semibold">
          {day}
        </div>
      </div>
      { ( startPrice !== '' && !/9{5,}/.test(startPrice) ) && 
        <div className="absolute top-2 left-2 rounded bg-bg-green flex flex-row py-1 px-2 items-center justify-start gap-[4px] text-green-text">
          <img
            className="relative w-5 h-5"
            alt="ticket-svg"
            src={"/images/icons/ticket-g.svg"}
          />
          <div className="relative leading-[15px] font-medium text-[#009D64]">
            ₹{startPrice} onwards
          </div>
        </div>
        
      }
    </div>
  );
}

export default EventCard;
