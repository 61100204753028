import React from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { FcOk } from 'react-icons/fc';

export const notify = (message) =>
	toast(<h5>{message ? message : "Update Successful"}</h5>, {
		icon: <FcOk />,
		duration: 2000,
		position: 'bottom-center',
		style: { fontSize: '13px' }
	});

const Alert = () => {
	return <Toaster />;
};
export default Alert;

