import React from "react";
import { HStack, Box, Text } from "@chakra-ui/react";
import { LuTicket } from "react-icons/lu";
import { ChevronRightIcon } from "@chakra-ui/icons";

const DisabledTicket = ({ title }) => {
  return (
    <HStack
      border="1px"
      bg="#e5e7eb"
      color="#9ca3af"
      cursor="not-allowed"
      borderColor="#F0F0F0"
      rounded="md"
      w="full"
      justifyContent="space-between"
      p={4}
    >
      <Box>
        <Text
          color="#9ca3af"
          fontSize={{ base: "normal", lg: "lg" }}
          fontWeight="semibold"
        >
          {title}
        </Text>
        <HStack color="#9ca3af">
          <LuTicket size={18} />
          <Text color="#9ca3af">No tickets available</Text>
        </HStack>
      </Box>
      <ChevronRightIcon boxSize={6} color="#9ca3af" />
    </HStack>
  );
};

export default DisabledTicket;
