import axios from "../../axios-api";
import {
  ADD_PRE_POST_TICKET_DATA,
  REMOVE_PRE_POST_TICKET_DATA,
  FETCH_LISTING_TICKETS,
  UPDATE_TICKET_SOLD_STATUS,
  UPDATE_TICKET_DATA,
  DELETE_TICKET_DATA,
  FETCH_EVENT_TICKETS,
  FETCH_TICKET_LISING_DATA,
} from "../actions/types";

export const sellTicketDataPost = (data) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post("/v1/ticket/sellTicket", data, config);
    if (res.status === 200) return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const sellTicketDataVerifyPost = (data) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post("/v1/ticket/book-ticket", data, config);
    if (res.status === 200) return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const fetchMyListingIndividualLisitingData =
  (eventID, categoryID) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.get(
        `/v1/ticket/details/${eventID}/${categoryID}`,
        config
      );
      localStorage.setItem(`mrp`, res.data.categoryPrice);
    } catch (err) {
      console.log(err);
    }
  };

export const addPrePostTicketData = (data) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_PRE_POST_TICKET_DATA,
      payload: data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const removePrePostTicketData = () => async (dispatch) => {
  try {
    dispatch({
      type: REMOVE_PRE_POST_TICKET_DATA,
      payload: {},
    });
  } catch (err) {
    console.log(err);
  }
};

export const fetchListingData = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.get(`/v1/ticket/listing`, config);

    dispatch({
      type: FETCH_LISTING_TICKETS,
      payload: res.data.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const updateMarkAsSold =
  (existingData, ticketID) => async (dispatch) => {
    try {
      const updatedData = existingData.map((data) => {
        if (data._id == ticketID) {
          return {
            ...data,
            isSold: true,
          };
        }
        return data;
      });

      dispatch({
        type: UPDATE_TICKET_SOLD_STATUS,
        payload: updatedData,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      await axios.put(`/v1/ticket/sold/${ticketID}`, config);
    } catch (err) {
      console.log(err);
    }
  };

export const updateTicketData =
  (existingData, id, price, count) => async (dispatch) => {
    try {
      const updatedData = existingData.map((data) => {
        if (data._id == id) {
          return {
            ...data,
            tickets: count,
            ticketPrice: price,
          };
        }
        return data;
      });

      dispatch({
        type: UPDATE_TICKET_DATA,
        payload: updatedData,
      });

      const data = {
        price,
        count,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      await axios.put(`/v1/ticket/${id}`, data, config);
    } catch (err) {
      console.log(err);
    }
  };

export const deleteTicketData = (existingData, id) => async (dispatch) => {
  try {
    const updatedData = existingData.filter((data) => data._id !== id);

    dispatch({
      type: DELETE_TICKET_DATA,
      payload: updatedData,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios.delete(`v1/ticket/delete/${id}`, config);
  } catch (error) {
    console.log(error);
  }
};

export const fetchTicketData =
  (eventID, categoryID, sortBy) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.get(
        `v1/ticket/${eventID}/${categoryID}?sortBy=${sortBy}`,
        config
      );

      dispatch({
        type: FETCH_EVENT_TICKETS,
        payload: res.data.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const fetchUnAuthTicketData =
  (eventID, categoryID, sortBy) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.get(
        `v1/ticket/unauth/${eventID}/${categoryID}?sortBy=${sortBy}`,
        config
      );

      dispatch({
        type: FETCH_EVENT_TICKETS,
        payload: res.data.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const verifyTickets = async (data) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post("/v1/ticket/verify-ticket", data, config);
    if (res.status === 200) return res;
  } catch (err) {
    console.log(err);
    return false;
  }
};
