import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';

const Input = ({
  id,
  label,
  type = 'text',
  disabled,
  register,
  required,
  errors = {},
  move,
}) => {
  const urlRegex = /^(https?:\/\/)?[\w.-]+\.[a-zA-Z]{2,}(\/\S*)?$/;

  return (
    <div className='w-full relative'>
      <input
        id={id}
        disabled={disabled}
        placeholder=' '
        type={type}
        {...register(id, {
          required: { value: required, message: `{label} is required` },
          pattern: {
            value: urlRegex,
            message: 'Invalid URL',
          },
        })}
        className={`
          peer
          w-[90vw]
          lg:w-[430px]
          h-[60px]
          ${move ? 'px-4 lg:px-0' : 'p-5'}
          
          font-light 
          bg-white 
          border-2
          rounded-md
          outline-none
          transition
          disabled:opacity-70
          disabled:cursor-not-allowed
          ${errors[id] ? 'border-rose-500' : 'border-neutral-300'}
          ${errors[id] ? 'focus:border-rose-500' : 'focus:border-black'}
        `}
      />
      {/* {errors[id] && <span className='text-red-500'>{errors[id].message}</span>} */}
    </div>
  );
};

export default Input;
