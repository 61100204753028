import React, { useState } from 'react';
import PopupScreen from './PopupScreen';
import styles from './listing.module.css';

const EditForm = ({
  initialNumberOfTickets,
  initialPrice,
  maxPrice,
  maxQuantity,
  onSave,
  category,
  onClose,
  onDelete,
}) => {
  const [numberOfTickets, setNumberOfTickets] = useState(
    initialNumberOfTickets
  );
  const [price, setPrice] = useState(initialPrice);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupAction, setPopupAction] = useState('');
  const [priceError, setPriceError] = useState('');
  const ticketPrice = Number(localStorage.getItem('mrp')) || 0;

  const saveHandler = async (e) => {
    e.preventDefault();
    let hasError = false;
    if (price === '') {
      setPriceError('Price is required');
      hasError = true;
    } else {
      setPriceError('');
    }

    if(Number(price) > maxPrice){
      setPriceError('Please enter a price less than what you paid per ticket');
    } else {
      if (!hasError) {
        onSave({
          numberOfTickets,
          price,
        });
  
        onClose();
      }
    }    
  };

  const delHandler = () => {
    openPopup('delete');
  };

  const onDeleteConfirm = () => {
    setIsPopupOpen(false);
    onDelete();
  };

  const onDeleteCancel = () => {
    setIsPopupOpen(false);
  };

  const openPopup = (action) => {
    setPopupAction(action);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setPopupAction('');
  };

  const handlePopupConfirm = () => {
    if (popupAction === 'delete') {
      onDeleteConfirm();
    }

    closePopup();
  };

  return (
    <div className={styles.editListContainer}>
      {isPopupOpen ? (
        <PopupScreen
          onClose={closePopup}
          onConfirm={handlePopupConfirm}
          title='Delete Listing'
          message='Are you sure you want to delete this post? This action cannot be undone.'
          confirmButtonText='Delete'
        />
      ) : (
        <form className={`${styles.listEditForm} ${styles.normal}`}>
          <label className={styles.formLabel}>
            How many <strong>{category}</strong> tickets?
          </label>

          <select
            className={`${styles.numberInput} ${styles.frame}`}
            value={numberOfTickets}
            onChange={(e) => setNumberOfTickets(parseInt(e.target.value))}
          >
            {Array.from({ length: maxQuantity|| 10 }, (_, index) => (
              <option key={index + 1} value={index + 1}>
                {index + 1}
              </option>
            ))}
          </select>

          <label className={styles.formLabel}>
            Enter the price of the ticket:
          </label>
          <div
            className={`${styles.priceInput} ${styles.flex} ${styles.frame}`}
          >
            <span className={styles.rupee}>₹</span>
            <input
              className={styles.textInputt}
              type='text'
              inputMode='numeric'
              pattern='[0-9,]*'
              value={price}
              onChange={(e) => {
                const inputValue = e.target.value.replace(/,/g, '');
                const regex = /^[0-9,]*$/;
                if (inputValue === '') {
                  setPrice('');
                  setPriceError('Price is required');
                } else if(regex.test(inputValue)) {
                  const newPrice = e.target.value.replace(/,/g, '');
                  if (newPrice > maxPrice) {
                    setPrice(newPrice);
                    setPriceError('Please enter a price less than what you paid per ticket');
                  } else {
                    setPrice(newPrice);
                    setPriceError('');
                  }
                }
              }}
            />
          </div>
          {priceError && (
            <div className={styles.errorMessage}>
              <img
                src='/images/icons/warn.svg'
                alt='Error'
                className={styles.errorI}
                loading='lazy'
              />
              {priceError}
            </div>
          )}
          <div className={styles.buttons}>
            <input
              className={styles['sec-button']}
              type='submit'
              value={'Update'}
              onClick={saveHandler}
            />

            <input
              className={styles['primary-button']}
              type='submit'
              value={'Delete Listing'}
              onClick={delHandler}
            />
          </div>
        </form>
      )}
    </div>
  );
};

export default EditForm;