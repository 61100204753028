import React from 'react'
import styles from "./home.module.css"

const Transfer = () => {
  return (
  // <>    
  //   <div className={styles.transfer} >
  //     <div className={styles.align}> 

  //       <div className={styles.title}> 
  //         <h2 className={styles.transhead}>Transfer tickets, say</h2> 
  //         <h3 className={styles.say}>no to screenshots! </h3> 
  //       </div>

  //       <div>
  //         <p className={styles.para}> 
  //           Learn the right way of sharing tickets. Gone are the days when a mere screenshot would do. 
  //           We value security & authenticity, just like you. 
  //         </p>
  //       </div>

  //       <div className={styles.transferbutton}>
  //         <div className={styles.bms}>
  //         <>
  //           <img src="images/banner/bms.svg" alt="bms"/>
  //           <h3 className={styles.til}>Bookmyshow</h3>
  //         </>
  //         <a  href="https://support.bookmyshow.com/support/solutions/articles/4000188966-information-on-m-ticket" target="_blank" rel="noopenner noreferrer" className={styles.learn}>Learn how</a>
  //       </div>
      
  //       <div className={styles.insider}>
  //       <>
  //       <img src="images/banner/insider.svg" alt="insider"/>
  //       <h3 className={styles.til}>Paytm Insider</h3>
  //       </>
  //     <a href="https://insider.in/transfer-ticket/article" target="_blank" rel="noopenner noreferrer" className={styles.learn}>Learn how</a>
  //     </div>
  //   </div>


  // </div>
  // <img src='images/banner/phone.png' alt="info" className={styles.phone}/>
 
  //   </div>
  //   </>

    <div className={styles.transfer}>

        <div className={styles.transferImgContainer}>
          <img src="/images/transfer.svg" alt='transfer-ticket' className={styles.transferImg}/>
        </div>

        <div className={styles.transferPlatform}>

          <div className={styles.transferHeader}>
            <h1 className={styles.transferTitle}>
              <span style={{color:"#FF2459"}}>Transfer tickets{" "}</span>
              {window.innerWidth > 600?<br/>:" "} the right {window.innerWidth < 600?<br/>:" "} way - Say no to Screenshots
            </h1>
            <p className={styles.transferSubTitle}>
              Gone are the days when a mere screenshot would do. Please get your tickets 
              transferred from the seller.
            </p>
          </div>

          <div className={styles.transferbutton}>
            <div className={styles.bms}>
              <>
                <img src="images/banner/bms.svg" alt="bms"/>
                <h3 className={styles.til}>Bookmyshow</h3>
              </>
             <a  href="https://support.bookmyshow.com/support/solutions/articles/4000188966-information-on-m-ticket" target="_blank" rel="noopenner noreferrer" className={styles.learn}>Learn how</a>
           </div>
      
            <div className={styles.insider}>
              <>
                <img src="images/banner/insider.svg" alt="insider"/>
                <h3 className={styles.til}>Paytm Insider</h3>
              </>
              <a href="https://insider.in/transfer-ticket/article" target="_blank" rel="noopenner noreferrer" className={styles.learn}>Learn how</a>
            </div>
          </div>

        </div>
    </div>
  )
}

export default Transfer