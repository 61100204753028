import React from "react";
import Av from "react-avatar";

function Avatar({ name, size = 112 }) {
  return (
    <Av
      name={name}
      size={size}
      color="#FFF2F2"
      fgColor="#FF3C6B"
      maxInitials={2}
    />
  );
}

export default Avatar;
