import {
  Box,
  Card,
  Flex,
  HStack,
  Heading,
  Image,
  Text,
  VStack,
  chakra,
} from "@chakra-ui/react";
import React from "react";
import Layout from "../Components/Layout/Layout";
import Alert from "../Components/Home/Alert"

const StyledChild = chakra("span", {
  baseStyle: {
    color: "brand.500", 
  },
});

const WhyTMTCard = ({ image, title, content }) => {
  return (
    <Card marginX={{sm:"auto", base:"auto"}} py={2} p={6} width="300px" height="320px" bg="white" rounded="sm">
      <Image boxSize="60px" src={image} />
      <Text py={4} fontWeight="semibold" fontSize={{  base: "xl" }}>
        {title}
      </Text >
      <Text fontSize={{  base: "md" }}>{content}</Text>
    </Card>
  );
};

const OurJourneyContent = ({ title, content }) => {
  return (
    <Flex columnGap={3} direction={{ base: "column", lg: "row" }} margin={{base:5, lg:0}}   >
      <Image boxSize="60px" src="/whyTMT/h31.png" />
      <VStack alignItems="flex-start" >
        <Text py={2} fontWeight="bold"  textAlign={{sm:"left",base:"left",lg:"center"}} fontSize={{base:"md", sm:"md", lg:"xl"}}>
          {title}
        </Text>
        <Text textAlign="justify" margin={{  lg:"2"}} fontSize={{base:"md", sm:"md", lg:"xl"}} px={1}>{content}</Text>
      </VStack>
    </Flex>
  );
};

const WhyTMT = () => {
  return (
    <Layout>
    <Alert />
      <Flex
        alignItems="center"
        justifyContent="center"
        height={{ base: "422px", lg: "534px" }}
        bg="black"
      >
        <Heading
          paddingX={{ base: 2, lg: 48 }}
          paddingY={{ base:2, sm:2, lg:0}}

          lineHeight={{ sm:3, base: "normal", lg: 1.6 }}
          color="white"
          textAlign={{sm:"left",lg:"center"}}
          marginTop={{ sm:4, base:7,lg:0 }}
          marginLeft={{ sm:4, base:5, lg:0}}
          fontWeight="bold"
          fontSize={{ sm:"4xl", base: "4xl", lg: "5xl" }}
        >
          Our mission is to connect <StyledChild>fans</StyledChild>
          {", "}
          <Image
            hideBelow="md"
            width={120}
            display="inline"
            src="/whyTMT/h11.png"
            alt=""
          />{" "}
          transforming{" "}
          <Image
            hideBelow="md"
            width={120}
            display="inline"
            src="/whyTMT/h12.png"
            alt=""
          />{" "}
          <StyledChild>idle tickets</StyledChild> into{" "}
          <StyledChild>unforgettable experiences.</StyledChild>
          <Image
            hideBelow="md"
            width={120}
            display="inline"
            src="/whyTMT/h13.png"
            alt=""
          />
        </Heading>
      </Flex>
      <Flex 
        h="100vh" 
        direction={{ base: "column-reverse", lg: "row" }} 
        
        
        px={0}
      >
        <Flex
         px={{ base: 2, lg: 16 }} 
         align="center" 
         textAlign={{sm:"left",lg:"center"}}
         justify="center" 
         
         flex={1}
        >
          <Text  marginLeft={{ base:3, lg:0 }} fontSize={{  base: "2xl" }}>
            Welcome to <StyledChild>Take My Tickets </StyledChild>, the
            friendliest ticket reselling platform you'll ever come across! We're
            here to make your ticket buying and selling experience as smooth as
            butter.
          </Text>
        </Flex>
        <Box
          minW="50%"
          minH={{ base: "300px", lg: "700px" }}
          backgroundImage="/whyTMT/h21.png"
          backgroundSize="cover"
          flex={1}
        ></Box>
      </Flex>
      <Flex py={8} gap={{ base: 4, lg: 8 }} bg="black" direction="column">
        <Flex alignItems="center" justifyContent="center">
          <Image src="/whyTMT/h41.png" boxSize="40px" />
          <Heading
            fontFamily={"cursive"}
            fontWeight="medium"
            textAlign="center"
            px={2}
            py={4}
            color="brand.500"
          >
            Few of the many reasons why fans love us!
          </Heading>
          <Image src="/whyTMT/h41.png" boxSize="40px" />
        </Flex>
        <Flex
          direction={{ base: "column", lg: "row" }}
          rowGap={4}
          px={{ base: 2, lg: 24 }}
          columnGap={10}
          justifyContent="center"
        >
          <WhyTMTCard
            image="/whyTMT/h31.png"
            title="We connect hearts, not just tickets!"
            content="We're all about connecting people, and making memories, one ticket at a time."
          />
          <WhyTMTCard
            image="/whyTMT/h32.png"
            title="You say goodbye to price gouging"
            content="We're all about fairness. We encourage our users to set reasonable prices, so you won't have to empty your savings account just to catch your favorite artist."
          />
          <WhyTMTCard
            image="/whyTMT/h33.png"
            title="No payment hassle"
            content="We connect  you directly with the seller. You negotiate the price, agree on the payment method, and you seal the deal. It's like having a secret handshake with your ticket buddy."
          />
        </Flex>
        <Flex
          direction={{ base: "column", lg: "row" }}
          rowGap={4}
          px={{ base: 2, lg: 24 }}
          columnGap={10}
          justifyContent="center"
        >
          <WhyTMTCard
            image="/whyTMT/h34.png"
            title="We’re just the matchmakers"
            content="We simply play cupid by connecting you with the sellers. What happens next? Well, that's where the magic begins! You get to chat, laugh, negotiate, and even become friends."
          />
          <WhyTMTCard
            image="/whyTMT/h35.png"
            title="We’re your trusty sidekick"
            content="While we can't promise surprises like finding a long-lost twin, we ensure a safe platform for your ticket journey. We actively safeguard your privacy, but remember, building trust with your match is in your hands."
          />
        </Flex>
      </Flex>
      <Box py={6}>
        <Heading py={3} textAlign={{ base:"left",sm:"left", lg:"center"}} marginLeft={{base:5, sm:5}}>
          Our journey has just begun 🚀
        </Heading>
        <VStack gap={6}  width={{ base: "full", lg: "50%" }} mx={{base:"auto", sm:"auto"}} margin={{lg:"auto"}} marginRight={{base:3, sm:3}} textAlign={{ base:"left",sm:"left", lg:"center"}}  >
          <Text py={3} px={1} fontSize={{base:"md", lg:"xl"}}  marginLeft={{base:5, sm:5}}>
            We have big plans to revolutionize the entire ticketing landscape in
            India. Here's a sneak peek into what's coming up in the next few
            months:
          </Text>
          <OurJourneyContent
            title="Comprehensive payment solutions"
            content="You can say goodbye to the hassle of coordinating payments with sellers. We're gearing up to introduce a seamless payment system that will handle all transactions securely and conveniently. No more juggling multiple apps or worrying about fraudulent transactions."
          />
          <OurJourneyContent
            title="Verified ticket guarantee"
            content="We will establish a strong verification process to combat ticket scams, ensuring only legitimate tickets on our platform. You will be able to buy with confidence, knowing your tickets will be verified and genuine"
          />
          <OurJourneyContent
            title="Streamlined user experience"
            content="We're all ears to your feedback, and we're committed to refining the ticket reselling process. Upcoming updates will focus on elevating user experience through intuitive navigation, advanced search, and a friendly interface"
          />
          <OurJourneyContent
            title="Enhanced Customer Support"
            content="Committed to exceptional customer service, our diligent support team works to promptly address your queries and concerns. We plan to introduce diverse support channels, like live chat and an extensive knowledge base, for comprehensive assistance."
          />
        </VStack>
      </Box>
      <Flex minH="400px" align="center" justify="center" bg="brand.500">
        <Heading
          paddingX={{ base: 2, lg: 48 }}
          textAlign={{base:"left",sm:"left",lg:"center"}}
          lineHeight={{ base: "normal", lg: 1.6 }}
          color="white"
          fontWeight="bold"
          fontSize={{ sm:"4xl", base: "4xl", lg: "5xl" }}
          marginLeft={{base:4}}
          px={1}
        >
          Join us on this incredible journey of redefining the ticketing
          experience, and making every event memorable.
        </Heading>
      </Flex>
    </Layout>
  );
};

export default WhyTMT;
