import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import useLocationModal from '../../../Hooks/useLocationModal';
import { userLogout } from '../../../redux/actions/auth';
import { BsFillCircleFill } from 'react-icons/bs';
import { notify } from '../../Listing/Toast';
import {
  Button,
  Divider,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  HStack,
  useToast,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

const UserDropDown = ({ currentUser, userLogout }) => {
  const locationModal = useLocationModal();
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    userLogout();
    navigate('/'); // Redirect to homepage first
    setTimeout(() => {
      toast({
        title: 'Logged Out',
        description: 'You have logged out successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'bottom-left',
      });
    }, 1000); // 1-second delay before showing the toast
  };

  const pathname = location.pathname;
  const locationName =
    capitalizeFirstLetter(pathname.split('/').pop()) || 'Location';

  function capitalizeFirstLetter(str) {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return null;
  }

  const profile = [
    {
      label: 'Listed Tickets',
      url: '/my-listing',
      icon: '/images/icons/my_listing.svg',
    },
    {
      label: 'Profile',
      url: '/my-profile',
      icon: '/images/icons/profile.svg',
    },
    {
      label: 'Support',
      url: 'mailto:rescue@takemytickets.co.in',
      icon: '/images/icons/support.svg',
    },
  ];

  const labelStyles = {
    color: 'var(--black, #101010)',
    fontFamily: 'General Sans',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
  };

  const iconStyles = {
    display: 'flex',
    width: '20px',
    height: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
  };

  return (
    <>
      <Menu>
        <MenuButton
          color='brand'
          as={Button}
          borderRadius={50}
          leftIcon={<BsFillCircleFill />}
          rightIcon={<ChevronDownIcon color='black' />}
        >
          <Text fontWeight='semibold' color='black'>
            {currentUser}
          </Text>
        </MenuButton>
        <MenuList>
          {profile.map((option, index) => {
            // If the label is not "Support", use the original Link and MenuItem
            if (option.label != 'Support') {
              return (
                <Link key={index} to={option.url}>
                  <MenuItem color='black' as={Text}>
                    <HStack>
                      <img src={option.icon} style={iconStyles} />
                      <Text style={labelStyles}>{option.label}</Text>
                    </HStack>
                  </MenuItem>
                </Link>
              );
            }

            // If the label is "Support", handle it differently
            else {
              return (
                <MenuItem
                  key={index}
                  color='black'
                  as={Text}
                  onClick={() => (window.location.href = option.url)}
                >
                  <HStack>
                    <img src={option.icon} style={iconStyles} />
                    <Text style={labelStyles}>{option.label}</Text>
                  </HStack>
                </MenuItem>
              );
            }
          })}

          <Divider />
          <Button
            width='full'
            variant='unstyled'
            onClick={() => {
              userLogout();
              navigate('/');
              setTimeout(() => {
                notify('Log Out Succesful');
              }, 500);
            }}
          >
            <MenuItem color='black' as={Text}>
              <HStack>
                <img src='/images/icons/logout.svg' style={iconStyles} />
                <Text style={labelStyles}>Log Out</Text>
              </HStack>
            </MenuItem>
          </Button>
        </MenuList>
      </Menu>
    </>
  );
};

export default connect(null, {
  userLogout,
})(UserDropDown);
