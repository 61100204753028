/* eslint-disable import/no-anonymous-default-export */
import {
    ADD_JWT_TOKEN, 
    REMOVE_JWT_TOKEN,
    FETCH_JWT_USER_DATA,
    USER_LOGOUT,
    UNAUTH_ERROR,
    USER_UPDATE_DATA
} from '../actions/types';

const initialState = {
    isAuthenticated: false,
    jwtToken: "",
    userData: {}
};

export default function(state = initialState, action){
    const { type, payload } = action;
    switch(type){
        case ADD_JWT_TOKEN:
            localStorage.setItem("token", payload);
            return {
                ...state,
                jwtToken: payload,
                isAuthenticated: true
            };
        case REMOVE_JWT_TOKEN:
            localStorage.removeItem("token");
            return {
                ...state,
                jwtToken: payload
            }
        case FETCH_JWT_USER_DATA:
            return {
                ...state,
                userData: payload,
                isAuthenticated: true
            }
        case USER_LOGOUT:
            localStorage.removeItem("token");
            return {
                ...state,
                jwtToken: payload,
                isAuthenticated: false,
                userData: {}
            }
        case UNAUTH_ERROR:
            localStorage.removeItem("token");
            return {
                ...state,
                jwtToken: "",
                isAuthenticated: false
            }
        case USER_UPDATE_DATA:
            return {
                ...state,
                userData: payload
            }
        default:
            return state;
    }
}