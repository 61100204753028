import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import Button from '../Button';
import './LocModal.css';
 
const Modal = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  body,
  footer,
  actionLabel,
  disabled,
  secondaryActionLabel,
  secondaryAction,
}) => {
  const [showModal, setShowModal] = useState(isOpen);

  useEffect(() => {
    setShowModal(isOpen);
  }, [isOpen]);

  const handleClose = useCallback(() => {
    if (disabled) {
      return;
    }

    setShowModal(false);
    setTimeout(() => {
      onClose();
    }, 300);
  }, [onClose, disabled]);

  const handleSubmit = useCallback(() => {
    if (disabled) {
      return;
    }

    onSubmit();
  }, [onSubmit, disabled]);

  const handleSecondaryAction = useCallback(() => {
    if (disabled || !secondaryAction) {
      return;
    }

    secondaryAction();
  }, [secondaryAction, disabled]);

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <div
        className='
          fixed 
          top-50
          justify-items-center 
          items-baseline
          flex 
          overflow-x-hidden 
          inset-0 
          z-50 
          outline-none 
          focus:outline-none
          bg-neutral-800/70
          font-general-sans
        '
      >
        <div
          className='
          relative 
          w-[327px]
          md:mt-[50%]
          lg:mt-[35%]
          xl:mt-[15%]
          mt-[40%]
          my-6
          mx-auto
          h-fit
          sm:mb-0
          lg:[40%]
          md:h-3/6
          '
        >
          {/*content*/}
          <div
            className={`
            translate
            duration-100
            h-full
            ${showModal ? 'translate-y-0' : 'translate-y-3/4'}
            ${showModal ? 'opacity-100' : 'opacity-0'}
          `}
          >
            <div
              className='
              translate
              h-full
              lg:h-auto
              md:h-auto
              border-0 
              rounded-lg 
              relative 
              flex 
              flex-col 
              w-full 
              bg-white 
              outline-none 
              focus:outline-none
              p-fixed
              p-6
            '
            >
              {/*header*/}
              <div
                className='
                flex 
                items-center 
                rounded-t
                relative
                '
              >
                <div className='text-semibold'>{title}</div>
              </div>
              {/*body*/}
              <div className='relative  '>
                {body}
              </div>
              {/*footer*/}
              <div className='flex flex-col overflow-y-auto'>
                <div
                  className='
                    flex 
                    flex-row 
                    items-center 
                    gap-4 
                    w-full
                    sm:w-6
                    sm:text-lg
                    sm:gap-1
                    text-xl
                    all:unset
                  '
                ></div>
                {footer}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
