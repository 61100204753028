import React from 'react';
import './progressBar.css';

const ProgressBar = ({progress,bottom, className}) => {

  return (
    <div className="progress-bar-container className" style={{ bottom: `${bottom}px` }}>
      <div className={`progress-bar`}>
        <div className="progress" style={{ width: `${progress}%` }}></div>
      </div>
    </div>
  );
};

export default ProgressBar;