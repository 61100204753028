import React from 'react';
import MobileBanner from './MobileBanner';
import styles from './home.module.css'

const LargeScreen = (
    <div className={styles.bannerContainer}>
        <div className={styles.contentContainer}>
            <div className={styles.innerContent}>
                <div className={styles.newPlatformContainer}>
                    <div className={styles.newPlatformBadge}>
                        <img className={styles.emoji} src='/images/icons/Sparkle.svg' alt='sparkle'/>
                        <span className={styles.newText}>New</span>
                    </div>
                    <p className={styles.platformText}>Event ticketing platform</p>
                </div>
                <div className={styles.textContainer}>
                    <h1 className={styles.heading}>Looking for a {" "}<br/> Better Ticketing <br/>Option?</h1>
                    <p className={styles.subheading}>Lowest fees and tailored features for your events!</p>
                </div>
            </div>
            <a id="Know_more" href='https://primary.takemytickets.co.in/' target='_blank' rel='noopener noreferrer' className={styles.gitButton}>
                Know more
            </a>
        </div>
        <div>
            <img src='/images/badge.svg' alt='badge' className={styles.badgeImage} />
        </div>
    </div>
)

const Banner2 = () => {
    return (
        window.innerWidth > 600 ? 
            LargeScreen : 
            <MobileBanner 
                id="Know_more"
                heading={`Looking for a Better \n Ticketing Option?`}
                subtitle="Lowest fees and tailored features for your events!"
                label="Know more"
            />
    );
}

export default Banner2;