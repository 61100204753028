import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import Event from './Event';
import Layout from '../Layout/Layout';
import styles from './seller.module.css';
import {
	sellTicketDataPost,
	addPrePostTicketData,
	removePrePostTicketData
} from '../../redux/actions/ticket';
import ProgressBar from '../ProgressBar/ProgressBar';
import axios from '../../axios-api'
import setMixPanel from '../../utils/setMixPanel';

const SellerForm = ({
	event: { sellerFlowEvent },
	auth: { userData },
	ticket: { listTicketPrePostData },
	sellTicketDataPost,
	addPrePostTicketData,
	removePrePostTicketData
}) => {
	useEffect(() => {		

		window.scrollTo(0, 0);
	}, []);

	const Headerr = ({ head, showParty }) => {
		const party = showParty ? '\u{1F973}' : '';

		return (
			<div className={`${styles.headdPlatform} ${window.innerWidth >600? 'mb-3':""}`}>
				<h1 className={styles.headerr}>
					{head}
					{party}
				</h1>
			</div>
		);
	};

	const navigate = useNavigate();

	const [isFocused, setFocused] = useState(null);
	const head = 'Select event to sell tickets';

	const handleClick = () => {
		navigate(`/verify-ticket/${isFocused}`);
		// navigate(`/verify-ticket/${sellerFlowEvent.platform}`);
	};

	const services = [
		{ id: 'BookMyShow', logo: '/images/banner/bms.svg', name: 'Book my show' },
		{ id: 'Paytm Insider', logo: '/images/banner/insider.svg', name: 'Paytm Insider' }
	];
	const handleFocus = (id) => {
		setFocused(id);
	};

	return (
		<Layout className={styles.backgroundLayout} isfooter={window.innerWidth < 600?false:true} >
			<Helmet>
				<title>Sell tickets</title>
				<meta
					name='Sell Tickets Easily in India | Reach Your Audience on Take My Tickets'
					content="Start selling your event tickets hassle-free on Take My Tickets, India's trusted ticket resale platform. Add seller contact details effortlessly."
				/>
				<meta charset='UTF-8' />
			</Helmet>

			<div className={`${styles.second}`}  style={{height:`${window.innerWidth > 600? '620px':'auto'}`}}>
				<Headerr
					head={head}
					showParty={false}
					className='ml-32 '
				/>
				<div className={`${styles.formContainer} flex flex-col gap-5 md:mt-[12px]`}>
					<div
						className='flex flex-col'
						style={{ gap: '6px' }}>
						<h4 className={styles.platform}>
							Which event do you want to sell tickets for?{' '}
						</h4>

						<div className={styles.eventContainer}>
							{sellerFlowEvent && (
								<Event
									id={sellerFlowEvent._id}
									eventName={sellerFlowEvent.eventName}
									date={sellerFlowEvent.eventDate}
								/>
							)}
						</div>
					</div>

					<div className={styles.platformMain}> 
						<p className={styles.platform}> 
							Which platform did you buy the tickets from? 
						</p> 
 
						<div className={styles.serviceContainer}> 
							{services.map((service) => ( 
								<div 
									key={service.id} 
									className={ 
										isFocused === service.id 
											? styles.serviceAppActive 
											: styles.serviceApp 
									} 
									onClick={() => handleFocus(service.id)}> 
									<img 
										src={service.logo} 
										alt={service.name} 
										className={styles.serviceLogo} 
									/> 
									<h3 className={styles.serviceName}>{service.name}</h3> 
								</div> 
							))} 
						</div> 
					</div> 
					
					<div className={styles.buttonContainer1}>
						<ProgressBar progress={33.33} bottom={94}/>
						<div className={`${styles.proceedButton} justify-center align-center h-full mt-4 flex`}>
							<button
								onClick={handleClick}
								disabled={!isFocused}
								className={isFocused !== null ? styles.proceed:`${styles.proceed} ${styles.inactive}`}>
								Proceed
							</button>
						</div>
					</div>
				</div>

			   
			</div>
		</Layout>
	);
};

const mapStateToProps = (state) => ({
	event: state.event,
	auth: state.auth,
	ticket: state.ticket
});

export default connect(mapStateToProps, {
	sellTicketDataPost,
	addPrePostTicketData,
	removePrePostTicketData
})(SellerForm);
