/* eslint-disable import/no-anonymous-default-export */
import {
    ADD_PRE_POST_TICKET_DATA,
    REMOVE_PRE_POST_TICKET_DATA,
    FETCH_LISTING_TICKETS,
    UPDATE_TICKET_SOLD_STATUS,
    UPDATE_TICKET_DATA,
    DELETE_TICKET_DATA,
    FETCH_EVENT_TICKETS
} from '../actions/types';

const initialState = {
    listTicketPrePostData: {},
    listingTickets: [],
    eventTicket: []
};

export default function(state = initialState, action){
    const { type, payload } = action;

    switch(type){
        case ADD_PRE_POST_TICKET_DATA:
            return {
                ...state,
                listTicketPrePostData: payload
            }
        case REMOVE_PRE_POST_TICKET_DATA:
            return {
                ...state,
                listTicketPrePostData: {}
            }
        case FETCH_LISTING_TICKETS:
        case UPDATE_TICKET_SOLD_STATUS:
        case UPDATE_TICKET_DATA:
        case DELETE_TICKET_DATA:
            return {
                ...state,
                listingTickets: payload
            }
        case FETCH_EVENT_TICKETS:
            return {
                ...state,
                eventTicket: payload
            }
        default:
            return state;
    }
}