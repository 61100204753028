import React, { useState } from 'react';
import { notify } from './Alert';
import styles from "./profile.module.css";

const Edit = ({ name, onSave }) => {
  const [username, setUsername] = useState(name);

  const saveHandler = (e) => {
    e.preventDefault();
    notify();
    onSave(username);
  };

  return (
    <div className={styles.editContainer}>
      <form className={`${styles.editForm} ${styles.normal}`}>
        <label className={styles.fullName}>Full name</label>
        <br />
        <input className={styles.textInput} type='text' name='fullName' value={username} onChange={(e) => setUsername(e.target.value)} />
        <br />
        <input className={styles.submitBtn} type='submit' value={'Save'} onClick={saveHandler} />
      </form>
    </div>
  );
};

export default Edit;
