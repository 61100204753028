import React from "react";
import { Box, Flex, Heading, Switch, Text } from "@chakra-ui/react";
import { BellIcon } from "@chakra-ui/icons";

const TicketsAlert = ({toggled, switchHandler}) => {
  return (
    <Flex
      align="flex-start"
      justify="space-between"
      gap={4}
      paddingX={5}
      paddingY={{ base: 6, lg: 6 }}
      border="1px"
      borderColor="#F0F0F0"
      rounded="md"
    >
      <BellIcon color="brand.500" boxSize={6} />
      <Box mr="auto">
        <Heading fontSize="lg" fontWeight={600}>Ticket Alerts</Heading>
        <Text fontSize="sm">
          Be the first to know when a tickets becomes available
        </Text>
      </Box>
      <Switch 
        isChecked={toggled}
        colorScheme="brand" 
        onChange={switchHandler}
        />

    </Flex>
  );
};

export default TicketsAlert;
