import {
  ADD_JWT_TOKEN,
  REMOVE_JWT_TOKEN,
  FETCH_JWT_USER_DATA,
  USER_LOGOUT,
  UNAUTH_ERROR,
  USER_UPDATE_DATA,
} from "./types";
import setAuthToken from "../../utils/setAuthToken";
import axios from "../../axios-api";

export const loadUser = () => async (dispatch) => {
  try {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (!localStorage.token) {
      dispatch({
        type: UNAUTH_ERROR,
        payload: {},
      });
      return;
    }

    const res = await axios.get(
      `/v1/user?location=${localStorage?.selectedLocation.toLowerCase()}`,
      config
    );
    window.email = res.data.data.email;
    window.name = res.data.data.name;

    localStorage.setItem("userEmail", res.data.data.email);
    localStorage.setItem("userName", res.data.data.name);

    dispatch({
      type: FETCH_JWT_USER_DATA,
      payload: res.data.data,
    });
  } catch (err) {
    console.log(err);
    if (err.response.status === 401) {
      dispatch({
        type: UNAUTH_ERROR,
        payload: {},
      });
    }
  }
};

export const registerClientJWT = (jwtToken) => async (dispatch) => {
  try {
    localStorage.setItem("token", jwtToken);
    dispatch({
      type: ADD_JWT_TOKEN,
      payload: jwtToken,
    });
  } catch (err) {
    console.log(err);
  }
};

export const destroyClientJWT = () => async (dispatch) => {
  try {
    localStorage.setItem("token");
    dispatch({
      type: REMOVE_JWT_TOKEN,
      payload: "",
    });
  } catch (err) {
    console.log(err);
  }
};

export const userLogout = () => async (dispatch) => {
  try {
    localStorage.removeItem("token");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userName");
    
    dispatch({
      type: USER_LOGOUT,
      payload: "",
    });
  } catch (err) {
    console.log(err);
  }
};

export const updateProfile = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const userData = await axios.put("/v1/profile", formData, config);

    dispatch({
      type: FETCH_JWT_USER_DATA,
      payload: userData.data.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const updateUserContactData = (data) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.put("/v1/user/contactDetails", data, config);

    dispatch({
      type: USER_UPDATE_DATA,
      payload: res.data.data,
    });

    if (res.status === 200) {
      return true;
    }

    return false;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const whatsappAuth = (data) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post("/v1/auth/whatsapp", data, config);

    dispatch({
      type: ADD_JWT_TOKEN,
      payload: res.data.token,
    });

    dispatch(loadUser());
  } catch (err) {
    console.log(err);
  }
};

export const fetchUserNumber = (id, ticketID) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.get(
      `/v1/user/number/${id}?ticketID=${ticketID}`,
      config
    );

    return res.data;
  } catch (err) {
    console.log(err);
  }
};
