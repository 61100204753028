import React from 'react';
import Layout from '../Layout/Layout';
import Header from './Header';
import Account from './Account';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import styles from "./profile.module.css";
import { useEffect } from 'react';

const Profile = ({ auth: {userData}}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <div className={`${styles.container} ${styles.all}`}>
        <Helmet>
          <title>My Profile</title>
          <meta 
            name="title"
            content="Personalize Your Ticket Resale Experience in India | Take My Tickets" 
          />
          <meta 
            name="description"
            content="Customize your profile on Take My Tickets and enjoy a personalized ticket resale experience for concerts, sports, and more in India." 
          />
        </Helmet>
        <Header />
        <Account
          name={userData.name}
          email={userData.email}
          imageURL={userData.picture}
          number={userData.mobile}
            />
      </div>
    </Layout>
  );
};


const mapStateToProps = (state) => ({
	auth: state.auth
});

export default connect(mapStateToProps, {})(Profile);