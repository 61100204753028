import React, { useEffect } from 'react';
import { TailSpin } from "react-loader-spinner";

const Loader = () => {
    useEffect(() =>{
        window.scrollTo(0, 0);
    },[]);
    return (
        <div className='flex justify-center items-center h-[100vh]'>
            <TailSpin color="#FF2459" height={40} width={40}/>
        </div>
    );
}

export default Loader;