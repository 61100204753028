/* eslint-disable import/no-anonymous-default-export */
import {
    FETCH_CITY_LIST
} from '../actions/types';

const initialState = {
    cityList: []
};

export default function(state = initialState, action){
    const { type, payload } = action;

    switch(type){
        case FETCH_CITY_LIST:
            return {
                ...state,
                cityList: payload
            }
        default:
            return state;
    }
}