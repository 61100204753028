import React from 'react';
import styles from "./profile.module.css";

const Verified = ({ email, number }) => {
  return (
    <div className={styles.verified}>
     {email && (
      <div className={styles.email}>
        <h2>Email ID</h2>
        <div className={styles.verifiedContainer}>
          <p>{email}</p>
          <p style={{ color: '#009D64' }}>Verified</p>
        </div>
      </div>
     )}
     {number && (
      <div className={styles.wpNo}>
        <h2>Whatsapp number</h2>
        <div className={styles.verifiedContainer}>
          <p>{number}</p>
          <p style={{ color: '#009D64' }}>Verified</p>
        </div>
      </div>
      )}
    </div>
     
  );
};

export default Verified;