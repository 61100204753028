import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import Label from "./Label";
import PopupScreen from "./PopupScreen";
import Layout from "../Layout/Layout";
import styles from "./seller.module.css";
import { updateUserContactData } from "../../redux/actions/auth";
import {
  sellTicketDataPost,
  removePrePostTicketData,
  sellTicketDataVerifyPost,
} from "../../redux/actions/ticket";

const AccountDetails = ({
  auth: { userData },
  ticket: { listTicketPrePostData },
  event: { sellerFlowEvent },
  updateUserContactData,
  sellTicketDataPost,
  removePrePostTicketData,
  sellTicketDataVerifyPost,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const Headerr = ({ head, showParty }) => {
    const party = showParty ? "\u{1F973}" : "";

    return (
      <div className={styles.headddContainer}>
        <h1 className={styles.headerr}>
          {head}
          {party}
        </h1>
      </div>
    );
  };

  const [wpNo, setWpNo] = useState(userData.mobile ? userData.mobile : null);
  const [nameError, setNameError] = useState("");
  const [name, setName] = useState(userData.name);
  const [wpError, setWpError] = useState("");
  const [email, setEmail] = useState(userData.email);
  const [mailError, setMailError] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [isVerified, setVerified] = useState(false);
  const [termsChecked, setTermsCheck] = useState(false);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const alphabeticRegex = /^[\w\-\s]+$/;

    if (e.target.name === "name") {
      if (alphabeticRegex.test(inputValue) || inputValue === "") {
        setName(inputValue);
        setNameError("");
      } else {
        setNameError("Please enter a valid name");
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let hasError = false;

    if (name === "") {
      setNameError("This field is required");
      hasError = true;
    } else {
      setNameError("");
    }

    if (wpNo === "") {
      setWpError("This field is required");
      hasError = true;
    } else if (!/^[0-9]{10}$/.test(wpNo)) {
      setWpError("Please enter a valid WhatsApp number");
      hasError = true;
    } else {
      setWpError("");
    }

    if (email === "") {
      setMailError("This field is required");
      hasError = true;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setMailError("Please enter a valid email address");
      hasError = true;
    } else {
      setMailError("");
    }

    if (!hasError) {
      setShowPopup(true);
    }
  };

  const handlePopupConfirm = async () => {    

    let data = {
      mobile: wpNo,
      name: name,
      email: email,
    };

    const advance = await updateUserContactData(data);

    if (advance) {
      if (sellerFlowEvent.isVerificationEvent) {
        const payload = {
          eventID: sellerFlowEvent._id,
          verificationId:listTicketPrePostData.verificationId,
          ticketCount: listTicketPrePostData.ticketCount,
          categoryName: listTicketPrePostData.categoryID,
          ticketPrice: listTicketPrePostData.ticketPrice,
          termsAgreed: termsChecked,
          maxPrice: listTicketPrePostData?.categoryPrice || 0,
          maxQuantity: listTicketPrePostData.maxTicketCount,
        };

        const advance = await sellTicketDataVerifyPost(payload);
        if (advance) {
          await removePrePostTicketData();
        }
        return advance;
      }

      const categoryData = sellerFlowEvent?.category?.filter(
        (cat) => cat.categoryName === listTicketPrePostData.categoryID
      )[0];

      let ticketData = {
        ...listTicketPrePostData,
        termsAgreed: termsChecked,
        categoryID: categoryData._id,
      };

      if (await sellTicketDataPost(ticketData)) {
        await removePrePostTicketData();
        return true;
      }

      return true;
    }
    return false;
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  return (
    <div>
      <Layout>
        <Helmet>
          <title>Sell tickets</title>
          <meta
            name="Sell Tickets Easily in India | Reach Your Audience on Take My Tickets"
            content="Start selling your event tickets hassle-free on Take My Tickets, India's trusted ticket resale platform. Add seller contact details effortlessly."
          />
          <meta charset="UTF-8" />
        </Helmet>
        <div className={styles.AccContainer}>
          <Headerr head="Add your details" showParty={false} />
          <form className={styles.formContainer} onSubmit={handleSubmit}>
            <Label className={styles.label} question="Name" />
            <div
              className={`${styles.Input} ${styles.flex} ${styles.frame} ${
                nameError ? styles.error : ""
              }`}
            >
              <input
                className={styles.text}
                type="text"
                value={name}
                onChange={handleInputChange}
                name="name"
                required
              />
            </div>

            <Label className={styles.label} question="Email ID" />
            <div
              className={`${userData.email ? styles.emailDiv : ""} ${
                styles.Input
              } ${styles.flex} ${styles.frame} ${
                mailError ? styles.error : ""
              }`}
            >
              <input
                disabled={
                  userData.email && userData.email.length > 2 ? true : false
                }
                className={`${styles.mail} ${
                  isVerified ? styles.verified : ""
                } ${userData.email ? styles.emailDiv : ""}`}
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setMailError("");
                  setVerified(false);
                }}
                required
              />
              {isVerified && (
                <div className={styles.verifiedContainer}>
                  <p style={{ color: "#009D64" }}>Verified</p>
                </div>
              )}
            </div>
            {mailError && (
              <div className={styles.errorMessage}>
                <img
                  src="/images/icons/warn.png"
                  alt="Error"
                  className={styles.errorI}
                />
                {mailError}
              </div>
            )}

            <Label className={styles.label} question="WhatsApp Number" />
            <div
              className={`${styles.Input} ${styles.flex} ${styles.frame} ${
                wpError ? styles.error : ""
              } ${userData.mobile ? styles.emailDiv : ""}`}
            >
              <span className={styles.rupee}>+91</span>
              <input
                className={`${styles.wp} ${isVerified ? styles.verified : ""} ${
                  userData.mobile ? styles.emailDiv : ""
                }`}
                type="text"
                disabled={userData.mobile ? true : false}
                inputMode="numeric"
                pattern="[0-9]*"
                maxLength="10"
                value={wpNo}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                  setWpNo(numericValue);
                  setWpError("");
                }}
                required
              />
            </div>
            {wpError && (
              <div className={styles.errorMessage}>
                <img
                  src="/images/icons/warn.png"
                  alt="Error"
                  className={styles.errorI}
                />{" "}
                {wpError}
              </div>
            )}

            <input
              type="submit"
              value="Save"
              className={`${styles.secButton} ${styles.saveButton} ${
                nameError || mailError || wpError ? styles.disabled : ""
              }`}
              disabled={nameError || mailError || wpError}
            />
          </form>

          {showPopup && (
            <PopupScreen
              title="Woohoo! Almost there✨"
              confirmButtonText="Agree & Proceed"
              onClose={handlePopupClose}
              onCreateListing={handlePopupConfirm}
              setTermsCheck={setTermsCheck}
            />
          )}
        </div>
      </Layout>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  ticket: state.ticket,
  event: state.event,
});

export default connect(mapStateToProps, {
  updateUserContactData,
  sellTicketDataPost,
  removePrePostTicketData,
  sellTicketDataVerifyPost,
})(AccountDetails);
