import { extendTheme } from "@chakra-ui/react";

const themeConfig = extendTheme({
  colors: {
    brand: {
      50: "#FED9E2",
      100: "#FCA7BB",
      200: "#FA7493",
      300: "#F8426C",
      400: "#F62044",
      500: "#FF2459", // Main brand color
      600: "#DB1E4F",
      700: "#B91945",
      800: "#95133B",
      900: "#700D31",
    },
  },
  fonts: {
    heading: `'General Sans', 'sans-serif'`,
    body: `'General Sans', 'sans-serif'`,
  },
});

export default themeConfig;
