export const FETCH_CITY_LIST = "FETCH_CITY_LIST";

export const FETCH_SEARCH_DROPDOWN = "FETCH_SEARCH_DROPDOWN";
export const FETCH_TRENDING_ALL_EVENTS = "FETCH_TRENDING_ALL_EVENTS";

export const ADD_JWT_TOKEN = "AUTH/ADD_JWT_TOKEN";
export const REMOVE_JWT_TOKEN = "AUTH/REMOVE_JWT_TOKEN";
export const FETCH_JWT_USER_DATA = "AUTH/FETCH_JWT_USER_DATA";
export const USER_LOGOUT = "AUTH/USER_LOGOUT";
export const USER_UPDATE_DATA = "AUTH/USER_UPDATE_DATA";
export const UNAUTH_ERROR = "AUTH/UNAUTH_ERROR"

export const FETCH_BUYER_FLOW_EVENT = "EVENT/FETCH_BUYER_FLOW_EVENT"
export const FETCH_SELLER_FLOW_EVENT = "EVENT/FETCH_SELLER_FLOW_EVENT"

export const ADD_PRE_POST_TICKET_DATA = "TICKET/PRE_POST_TICKET_DATA"
export const REMOVE_PRE_POST_TICKET_DATA = "TICKET/REMOVE_POST_TICKET_DATA"
export const FETCH_LISTING_TICKETS = "TICKET/FETCH_LISTING_TICKETS";
export const UPDATE_TICKET_SOLD_STATUS = "TICKET/UPDATE_TICKET_SOLD_STATUS";
export const UPDATE_TICKET_DATA = "TICKET/UPDATE_TICKET_DATA";
export const DELETE_TICKET_DATA = "TICKET/DELETE_TICKET_DATA";
export const FETCH_TICKET_LISING_DATA = "TICKET/FETCH_TICKET_LISING_DATA";
export const FETCH_EVENT_TICKETS = "TICKET/FETCH_EVENT_TICKETS";

export const SUBSCRIBE_EVENT = "EVENT/SUBSCRIBE_EVENT"; 
export const UNSUBSCRIBE_EVENT = "EVENT/UNSUBSCRIBE_EVENT"; 

export const GET_IN_TOUCH_CLICK = "ANAYTICS/GET_IN_TOUCH_CLICK";