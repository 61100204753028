import React from "react";
import { LoadingOutlined } from '@ant-design/icons';
import InputWithLabel from "../inputs/InputWithLabel";
import Button from "../Button";

const CreateAccount = ({ email, name, onNameChange, nameValidationMsg, onCreateAccount, isLoading }) => (
  <div className="flex flex-col w-full flex-1">
    <div className="text-2xl font-semibold">Almost there!</div>
    <div className="text-sm sm:text-base pt-2">Finish creating your account to start buying and selling tickets.</div>
    <InputWithLabel className="pt-6" label="Name" key="user-name" type="text" value={name} onChange={onNameChange} validationMsg={nameValidationMsg} />
    <InputWithLabel className="pt-6" label="Email" key="user-email" type={email} value={email} disabled={true} />
    <Button
      className="mt-4 sm:mt-9 h-[48px] sm:h-[56px] bg-main-brand"
      label="Create account"
      onClick={onCreateAccount}
      {...(isLoading && ({ customIcon: (<LoadingOutlined className='text-[#fff]' />), label: '' }))}
    />
  </div>
);

export default CreateAccount;
