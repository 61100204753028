import React from 'react';
import Layout from '../Components/Layout/Layout';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Alert from "../Components/Home/Alert"

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Privacy Policy</title>
        <meta
          name='title'
          content='Your Privacy Matters to Us | Take My Tickets India'
        />
        <meta
          name='description'
          content="Read Take My Tickets' privacy policy to understand how we prioritize and protect your personal information when buying tickets online in India."
        />
      </Helmet>
      <Alert />
      <div className='pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white'>
        <div className='flex flex-justify-between px-6 mx-auto max-w-screen-xl'>
          <div className='mx-auto w-full max-w-2xl'>
            <h1 className='mt-20 text-4xl font-bold font-general-sans lg:mb-9 lg:text-[56px] '>
              Privacy Policy
            </h1>
            <p className='pb-9 font-normal font-general-sans'>
              Last updated on 28 July, 2023
            </p>
            <p className='text-[16px] mb-6 lg:mb-9 font-normal font-general-sans'>
              This privacy policy explains how takemytickets.co.in collects,
              uses, and protects the personal information of users when they
              access and use our ticket reselling services through the website
              takemytickets.co.in. By using the website, you consent to the
              practices outlined in this policy.
            </p>
            <h3 className='mb-5 text-xl font-semibold font-general-sans text-black'>
              Information We Collect
            </h3>
            <ul style={{ listStyleType: 'disc' }}>
              <li className='mb-4 text-[16px] font-normal text-black ml-5'>
                Account Information: When you create an account on our website,
                we may collect personal information such as your Google email or
                WhatsApp number. We may also collect additional information that
                you choose to provide, such as your name and profile picture.
              </li>
              <li className='mb-4 text-[16px] font-normal text-black ml-5'>
                Ticket Listing Information: When you create a ticket listing on
                our website, we may collect information about the event, ticket
                details, and any other relevant information that you provide.
              </li>
              <li className='mb-9 text-[16px] font-normal text-black ml-5'>
                Embedded Scripts and Authentication: To enhance user experience
                and ensure secure login and signup processes, we utilize
                embedded scripts provided by Google and OTPless (WhatsApp).
                Specifically, we implement Google Authentication and WhatsApp
                Authentication OTPless for the login and signup procedures.
                These services enable users to log in or sign up to our platform
                using their Google email or WhatsApp number, without the need
                for One-Time Passwords (OTP).
              </li>
            </ul>
            <h3 className='mb-5 text-xl font-semibold font-general-sans text-black'>
              Use of Information
            </h3>
            <ul style={{ listStyleType: 'disc' }}>
              <li className='mb-4 text-[16px] font-normal text-black ml-5'>
                Provide Services: We use the collected information to facilitate
                the operation of the website and provide our ticket reselling
                services. This includes connecting buyers and sellers, enabling
                communication between users, and managing ticket listings.
              </li>
              <li className='mb-4 text-[16px] font-normal text-black ml-5'>
                Communication: We may use your contact information to
                communicate with you regarding your account or inquiries related
                to the website.
              </li>
              <li className='mb-4 text-[16px] font-normal text-black ml-5'>
                Improvements and Personalization: We may use the information to
                analyze user behavior and preferences to improve our services,
                customize your experience on the website, and develop new
                features and functionalities.
              </li>
              <li className='mb-9 text-[16px] font-normal text-black ml-5'>
                Legal Compliance: We may use your information to comply with
                applicable laws, regulations, legal processes, or enforceable
                governmental requests.
              </li>
            </ul>
            <h3 className='mb-5 text-xl font-semibold font-general-sans text-black'>
              Information Sharing
            </h3>
            <ul style={{ listStyleType: 'disc' }}>
              <li className='mb-4 text-[16px] font-normal text-black ml-5'>
                User Interaction: When you engage in buyer-seller interactions
                on our website, we may share your contact information (such as
                your WhatsApp number) with the respective user(s) to enable
                communication and facilitate the ticket transaction. Please note
                that once your information is shared with another user, it
                becomes subject to their privacy practices. We are not
                responsible for their use or handling of your information.
              </li>
              <li className='mb-4 text-[16px] font-normal text-black ml-5'>
                Liability Disclaimer: Please note that takemytickets.co.in acts
                solely as a platform to connect buyers and sellers, and we do
                not assume responsibility for any transactions or interactions
                that occur between users. While we strive to provide a reliable
                platform, we are not responsible for any issues that may arise,
                including but not limited to:
                <ul style={{ listStyleType: 'lower-alpha' }}>
                  <li className='text-[16px] font-normal text-black ml-5'>
                    Seller Non-Compliance: In the event that a seller fails to
                    deliver the tickets after receiving payment, we cannot be
                    held liable. It is the responsibility of the buyer and
                    seller to reach a satisfactory resolution.
                  </li>
                  <li className='text-[16px] font-normal text-black ml-5'>
                    Fake Tickets: If a seller sells fake tickets, we are not
                    responsible for the authenticity of the tickets listed on
                    the website. We encourage buyers to exercise due diligence
                    when purchasing tickets and to report any fraudulent
                    activity.
                  </li>
                  <li className='text-[16px] font-normal text-black ml-5'>
                    Mishaps: In cases where any mishap occurs during the
                    buyer-seller interaction or the ticket transaction,
                    takemytickets.co.in will not be held responsible. Users
                    engage with each other at their own risk, and any disputes
                    or issues should be resolved directly between the involved
                    parties..
                  </li>
                  <li className='text-[16px] font-normal text-black ml-5'>
                    It is important for users to exercise caution and use their
                    discretion when engaging in ticket transactions through our
                    platform. We recommend thoroughly reviewing the seller's
                    information, communicating effectively, and verifying the
                    authenticity of the tickets before finalizing any
                    transactions.
                  </li>
                  <li className='mb-9 text-[16px] font-normal text-black ml-5'>
                    By using takemytickets.co.in, you acknowledge and agree that
                    any transactions or interactions conducted through the
                    website are solely between the users involved, and
                    takemytickets.co.in shall not be liable for any loss,
                    damages, or disputes arising from such transactions or
                    interactions.
                  </li>
                </ul>
              </li>
            </ul>
            <h3 className='mb-5 text-xl font-semibold font-general-sans text-black'>
              Data Security
            </h3>
            <ul style={{ listStyleType: 'disc' }}>
              <li className='mb-4 text-[16px] font-normal text-black ml-5'>
                Security Measures: We implement appropriate technical and
                organizational measures to protect your personal information
                from unauthorized access, disclosure, alteration, or
                destruction.
              </li>
              <li className='mb-9 text-[16px] font-normal text-black ml-5'>
                User Responsibility: While we work diligently to safeguard your
                personal information and employ the best security practices,
                it's crucial to remember that no method of transmission or
                storage is entirely foolproof. Nonetheless, we are committed to
                doing everything within our power to keep your data safe and
                secure. By using our platform, you can trust that we prioritize
                your privacy and will continue to take every possible measure to
                protect your information from potential mishaps.
              </li>
            </ul>
            <h3 className='mb-5 text-xl font-semibold font-general-sans text-black'>
              Data Retention
            </h3>
            <ul style={{ listStyleType: 'disc' }}>
              <li className='mb-9 text-[16px] font-normal text-black ml-5'>
                We retain your personal information for a period determined by
                the purpose for which it was collected, legal requirements, and
                our legitimate interests. During this time, rest assured that
                your data will be stored securely. We greatly value your privacy
                and assure you that, once the specified retention period expires
                and your information is no longer necessary for our legitimate
                purposes, we will promptly delete or anonymize it. Should you
                have any questions, please don't hesitate to contact our support
                team. Your trust and data security are our utmost priorities.
              </li>
            </ul>
            <h3 className='mb-5 text-xl font-semibold font-general-sans text-black'>
              Your Rights
            </h3>
            <ul style={{ listStyleType: 'disc' }}>
              <li className='mb-4 text-[16px] font-normal text-black ml-5'>
                Access and Updates: You have the right to access, update, and
                correct your personal information stored on our website. You can
                do this through your account settings.
              </li>
              <li className='mb-9 text-[16px] font-normal text-black ml-5'>
                Account Deletion: If you wish to delete your account and
                personal information from our system, please contact us. We will
                process your request as quickly as reasonably possible, subject
                to any legal obligations or legitimate business interests.
              </li>
            </ul>
            <h3 className='mb-5 text-xl font-semibold font-general-sans text-black'>
              Changes to this Privacy Policy
            </h3>
            <ul style={{ listStyleType: 'disc' }}>
              <li className='mb-9 text-[16px] font-normal text-black ml-5'>
                Policy Updates: We reserve the right to update or modify this
                Privacy Policy at any time. Any changes will become effective
                immediately upon their posting on the website. Your continued
                use of the website following such modifications indicates your
                acceptance of the revised Policy.
              </li>
            </ul>
            <h3 className='mb-5 text-xl font-semibold font-general-sans text-black'>
              Contact Us
            </h3>
            <ul style={{ listStyleType: 'disc' }}>
              <li className='mb-9 text-[16px] font-normal text-black ml-5'>
                If you have any questions, concerns, or requests regarding this
                Privacy Policy or our privacy practices, please contact us at{' '}
                <a
                  href='mailto:rescue@takemytickets.co.in'
                  style={{ textDecoration: 'underline' }}
                >
                  rescue@takemytickets.co.in
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
