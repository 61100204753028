import React from 'react';
import { useNavigate } from 'react-router-dom';

import TicketIn from './TicketIn';
import styles from './seller.module.css';

const Event = ({ id, venue, eventName, date, location }) => {

  const navigate = useNavigate();
  const handleClose = () => {
      return navigate('/sell-tickets');
  };

  return (
    <div>
      <div className={styles.tickettContainer} data-id={id}>
        <button className={styles.closeButton} onClick={handleClose}>
          <img src="/images/icons/close.svg" alt="Close" />
        </button>
        <TicketIn eventName={eventName} date={date} venue={venue} />
      </div>
    </div>
  );
};

export default Event;
