import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { SlLocationPin } from 'react-icons/sl';
import { useNavigate } from 'react-router-dom';
import useLocationModal from '../../../Hooks/useLocationModal';
import {
  fetchDropDownSearchData,
  fetchTrendingAllEvents,
} from '../../../redux/actions/event';
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Flex,
  Text,
} from '@chakra-ui/react';
import { ReactComponent as Down_arrow } from './../../../Icons/down_arrow.svg';

const DropDown = ({
  venue: { cityList },
  fetchDropDownSearchData,
  fetchTrendingAllEvents,
  location,
  width,
  isOpen,
  marginTop
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    const savedLocation = localStorage.getItem('selectedLocation');
    if (savedLocation) {
      setSelectedLocation(savedLocation);
    }
  }, []);

  const lc_location = localStorage.getItem('selectedLocation');
  const locationName = capitalizeFirstLetter(lc_location) || 'Select city';
  const [selectedLocation, setSelectedLocation] = useState(locationName);
  const locationModal = useLocationModal();
  const menuButton = useRef(null);

  let cities = [];
  cityList.forEach((ele) => {
    cities.push({ label: ele.cityName, value: ele.cityName });
  });

  function capitalizeFirstLetter(str) {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return null;
  }

  function capitalizeLastLetter(str) {
    if (str) {
      return str.charAt(0).toLowerCase() + str.slice(1);
    }
    return null;
  }

  const handleLocationChange = (location) => {
    setSelectedLocation(location);

    localStorage.setItem('selectedLocation', location);
    location = capitalizeLastLetter(location);

    fetchDropDownSearchData('');
    fetchTrendingAllEvents(true);
    locationModal.onClose();

    // Navigate to the homepage
    navigate('/');
  };

  return (
    <Menu size={{ base: 'sm', md: 'md' }} defaultIsOpen={isOpen}>
      <MenuButton
        ref={menuButton}
        bg='white'
        variant='outline'
        as={Button}
        _active={{
          bg: isOpen ? 'transparent' : 'background-color rgb(240, 240, 240)',
        }}
        rightIcon={<Down_arrow />}
        h="52px"
      >
        <Flex alignItems='center' gap='12px' fontSize={'16px'} fontWeight={'500'}>
          {locationName}
        </Flex>
      </MenuButton>
      <MenuList
        marginTop={marginTop}
        minWidth={{
          base: width === 'navbar' ? '150px' : '270px',
          md: width === 'navbar' ? '150px' : '284px',
          lg: width === 'navbar' ? '150px' : '284px',
          xl: width === 'navbar' ? '150px' : '290px',
        }}
        maxWidth={{
          base: width === 'navbar' ? '150px' : '270px',
          md: width === 'navbar' ? '150px' : '284px',
          lg: width === 'navbar' ? '150px' : '284px',
          xl: width === 'navbar' ? '150px' : '290px',
        }}
        overflow='hidden'
        paddingY={2}
        color='black'
        boxShadow='md'
        bg='white'
        px={{ base: '0', md: '0' }}
        py={{ base: '2', md: '0' }}
      >
        {cities.map((city, index) => (
          <MenuItem
            key={index}
            _hover={{ color: '#FF2459', bg: 'transparent' }}
            px='20px'
            py='10px'
            transition='transform 0.3s ease-in-out'
            onClick={() => handleLocationChange(city.value)}
            style={{ textAlign: 'center' }}
          >
            <Text
              fontSize='16px'
              lineHeight='24px'
              color={city.label === locationName ? '#FF2459' : '#101010'}
              _hover={{ color: '#FF2459', bg: 'transparent' }}
            >
              {city.label}
            </Text>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

const mapStateToProps = (state) => ({
  venue: state.venue,
});

export default connect(mapStateToProps, {
  fetchDropDownSearchData,
  fetchTrendingAllEvents,
})(DropDown);
