import React from 'react';
import Layout from '../Components/Layout/Layout';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Alert from "../Components/Home/Alert"

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Terms of Service</title>
        <meta
          name='title'
          content=' Read Our Terms of Service | Secure Ticket Resale in India| Take My Tickets India'
        />
        <meta
          name='description'
          content="Familiarize yourself with Take My Tickets' terms of service for a secure and reliable event ticket resale experience in India."
        />
      </Helmet>
      <Alert />
      <div className='pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white'>
        <div className='flex flex-justify-between px-6 mx-auto max-w-screen-xl'>
          <div className='mx-auto w-full max-w-2xl'>
            <h1 className='mb-6 lg:mb-9 mt-20 text-4xl font-bold font-general-sans lg:text-[56px] '>
              Terms of service
            </h1>
            <p className='text-[16px] mb-6 lg:mb-9 font-normal font-general-sans text-black'>
              Welcome to Take My Tickets. These Terms of Service outline the
              agreement between you, the user, and takemytickets.co.in regarding
              the use of our ticket reselling services. By accessing or using
              the website, you agree to be bound by these terms. If you disagree
              with any part of these terms, please refrain from using the
              website.
            </p>
            <h3 className='mb-5 text-xl font-semibold font-general-sans text-black'>
              Account Creation and Responsibilities
            </h3>
            <ul style={{ listStyleType: 'disc' }}>
              <li className='mb-4 text-[16px] font-normal text-black ml-5'>
                User Accounts: To utilize certain features of the website, you
                may need to create an account by providing either a Google email
                or a WhatsApp number. You are responsible for maintaining the
                confidentiality of your account information and are liable for
                all activities conducted through your account.
              </li>
              <li className='mb-9 text-[16px] font-normal text-black ml-5'>
                Account Information: You agree to provide accurate, current, and
                complete information during the registration process, and to
                promptly update your account details if there are any changes.
              </li>
            </ul>
            <h3 className='mb-5 text-xl font-semibold font-general-sans text-black'>
              Ticket Listing and Purchasing
            </h3>
            <ul style={{ listStyleType: 'disc' }}>
              <li className='mb-4 text-[16px] font-normal text-black ml-5'>
                Listing Tickets: The website provides a platform for users to
                create and manage listings for their own tickets. You bear sole
                responsibility for the accuracy and legality of the information
                provided in your listings. The company does not guarantee the
                availability, authenticity, or quality of the tickets listed on
                the website.
              </li>
              <li className='mb-9 text-[16px] font-normal text-black ml-5'>
                Buyer-Seller Interaction: The website serves as a medium to
                connect buyers and sellers. The company does not handle payments
                or guarantee any transactions made between users. Any
                communications or interactions between users are solely the
                responsibility of those individuals involved.
              </li>
            </ul>
            <h3 className='mb-5 text-xl font-semibold font-general-sans text-black'>
              Marketing Communication
            </h3>

            <p className='text-[16px] mb-4 lg:mb-4 font-normal font-general-sans text-black'>
              As a registered member of Take My Tickets, we may communicate with
              you through the email or mobile number provided during
              registration. Your email or mobile number will be used to send the
              following types of information:
            </p>

            <ul style={{ listStyleType: 'disc' }}>
              <li className='mb-4 text-[16px] font-normal text-black ml-5'>
                Account-related Emails/SMS: Essential communications related to
                your registered account, such as verification emails, password
                resets, and account notifications.
              </li>
              <li className='mb-4 text-[16px] font-normal text-black ml-5'>
                Newsletter Subscriptions: Periodic updates, promotional offers,
                and relevant information about events and ticketing, if you have
                subscribed to our newsletter.
              </li>
              <li className='mb-4 text-[16px] font-normal text-black ml-5'>
                New Ticket Listings: Notifications about newly posted tickets
                matching your interests and preferences on Take My Tickets.
              </li>
              <li className='mb-4 text-[16px] font-normal text-black ml-5'>
                Updates on Website Usage: Updates about any changes in the terms
                of service or the usage of our website.
              </li>
              <li className='mb-4 text-[16px] font-normal text-black ml-5'>
                Newsletter Subscriptions: Periodic updates, promotional offers,
                and relevant information about events and ticketing, if you have
                subscribed to our newsletter.
              </li>
              <li className='mb-4 text-[16px] font-normal text-black ml-5'>
                New Services and Features: Information about any new services or
                features introduced on the Take My Tickets platform.
              </li>
              <li className='mb-9 text-[16px] font-normal text-black ml-5'>
                Occasional Third-Party Emails: On rare occasions, emails on
                behalf of carefully selected third-party services that might be
                of interest to you. However, we will never share your personal
                information with these third parties without your explicit
                consent.
              </li>
            </ul>
            <h3 className='mb-5 text-xl font-semibold font-general-sans text-black'>
              User Conduct and Content
            </h3>
            <ul style={{ listStyleType: 'disc' }}>
              <li className='mb-4 text-[16px] font-normal text-black ml-5'>
                Prohibited Activities: You agree not to use the website for any
                illegal or unauthorized purposes, including but not limited to
                posting false, misleading, or fraudulent information, infringing
                upon intellectual property rights, or engaging in any form of
                malicious activity.
              </li>
              <li className=' text-[16px] font-normal text-black ml-5'>
                Content Moderation: You represent and agree that you own all
                content you submit to Take My Tickets, including but not limited
                to:
              </li>
              <ul style={{ listStyleType: 'disc' }}>
                <li className='text-[16px] font-normal text-black ml-12'>
                  Tickets: Any ticket listings you create and manage on the
                  platform.
                </li>
                <li className='text-[16px] font-normal text-black ml-12'>
                  Comments: Any comments or feedback you provide on the website.
                </li>
                <li className='text-[16px] font-normal text-black ml-12'>
                  Images: Any images or multimedia content you upload to the
                  website in relation to your ticket listings.
                </li>{' '}
                <li className='text-[16px] font-normal text-black ml-12'>
                  Profile Data: Your personal information and details provided
                  in your user profile.
                </li>{' '}
                <li className='mb-4 text-[16px] font-normal text-black ml-12'>
                  Messages: All messages sent or received during the ticket
                  transfer process.
                </li>
              </ul>
            </ul>

            <p className='text-[16px] mb-4 lg:mb-9 font-normal font-general-sans text-black'>
              You represent that you are the rightful owner of all submitted
              content. If any content violates our Terms or company policies, we
              reserve the right to remove the listing or revoke the violating
              user's account. Content moderation ensures a safe and trusted
              platform for ticket reselling and buying in India. The decision to
              remove content lies solely with Take My Tickets.
            </p>

            <h3 className='mb-5 text-xl font-semibold font-general-sans text-black'>
              Intellectual Property
            </h3>
            <ul style={{ listStyleType: 'disc' }}>
              <li className='mb-9 text-[16px] font-normal text-black ml-5'>
                Ownership: All intellectual property rights related to the
                website, its content, and the company's brand belong to the
                company. You agree not to copy, distribute, or use any
                intellectual property without explicit permission from the
                company.
              </li>
            </ul>

            <h3 className='mb-5 text-xl font-semibold font-general-sans text-black'>
              Disclaimer
            </h3>
            <ul style={{ listStyleType: 'disc' }}>
              <li className='mb-9 text-[16px] font-normal text-black ml-5'>
                The website and its services are provided on an "as is" and "as
                available" basis. The company does not guarantee the accuracy,
                completeness, or reliability of the website or its content. You
                acknowledge and agree that your use of the website is at your
                own risk.
              </li>
            </ul>

            <h3 className='mb-5 text-xl font-semibold font-general-sans text-black'>
              Indemnification
            </h3>
            <ul style={{ listStyleType: 'disc' }}>
              <li className='mb-9 text-[16px] font-normal text-black ml-5'>
                By using the website, you agree to indemnify Take My Tickets
                from any claims, losses, liabilities, damages, or expenses
                arising out of your use of the platform or any violation of
                these Terms. This indemnification protects Take My Tickets from
                any legal or financial consequences arising from your actions
                while using our services.
              </li>
            </ul>

            <h3 className='mb-5 text-xl font-semibold font-general-sans text-black'>
              Modifications to the Terms of Service
            </h3>
            <ul style={{ listStyleType: 'disc' }}>
              <li className='mb-9 text-[16px] font-normal text-black ml-5'>
                Updates: The company reserves the right to modify or update
                these Terms at any time without prior notice. Any changes will
                be effective immediately upon posting on the website. Your
                continued use of the website after such modifications
                constitutes your acceptance of the revised Terms.
              </li>
            </ul>

            <h3 className='mb-5 text-xl font-semibold font-general-sans text-black'>
              Governing Law and Dispute Resolution
            </h3>
            <ul style={{ listStyleType: 'disc' }}>
              <li className='mb-9 text-[16px] font-normal text-black ml-5'>
                Jurisdiction: These Terms are governed by and construed in
                accordance with the laws of India. Any disputes arising out of
                or in connection with these Terms fall under the exclusive
                jurisdiction of the courts of India.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TermsAndConditions;
