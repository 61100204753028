import React from "react"
import styles from './home.module.css'

const MobileBanner = ({id,Announcement=true,heading,subtitle,label,color})=>{ 
    
    return (
        <div className={styles.mb_bannerContainer}>
            <div className={styles.mb_contentContainer}>
                {Announcement && 
                    <div className={styles.mb_newPlatformAnnounce}>
                        <img className={styles.mb_emoji} src='/images/icons/Speaker.svg' alt='speaker'/>
                        <p className={styles.mb_platformText}>Attention - Event ticketing platform in town!</p>
                    </div>
                }
                <div className={styles.mb_newPlatformContainer}>
                    <div className={styles.mb_innerContent}>
                        <div className={styles.mb_textContainer}>
                            <h4 className={styles.mb_heading}>{heading}</h4>
                            <p className={styles.mb_subheading}>{subtitle}</p>
                        </div>
                        <a href='https://primary.takemytickets.co.in/'
                            id={id} 
                            target='_blank' 
                            rel='noopener noreferrer' 
                            className={styles.mb_btn}>
                            {label}
                        </a>
                    </div>
                    <div>
                        <img src='/images/mobile-badge.svg' alt='badge' className={styles.mb_badgeImage}/>
                    </div>
                </div>
                </div>
        </div>
    )
}

export default MobileBanner;