import React from 'react';
import styles from "./profile.module.css";

const Header = () => {
  return (
    <div className={styles.header}>
      <h1>Account info</h1>
      <p>Verify your personal details</p>
    </div>
  );
};

export default Header;
