import React from 'react';
import styles from './seller.module.css';

const Header = ({ head, showParty }) => {
  const party = showParty ? '\u{1F973}' : '';

  return (
    <div className={styles.headContainer}>
      <h1 className={styles.headerr} >
        {head}
        {party} 
       
      </h1>
    </div>
  );
};

export default Header;
