import { getAnalytics, logEvent } from 'firebase/analytics';

export const analyticEventLogger = (eventName, eventData, params = {}) => {
    const analytics = getAnalytics();
    try {
        if (process.env.NODE_ENV === 'production'){
            logEvent(analytics, eventName, eventData);
        }
    } catch (err) {
        console.log(err);
    }
}