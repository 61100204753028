import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Layout from '../Components/Layout/Layout';
import Input from '../Components/inputs/Input';
import { submitRequestEvent } from '../redux/actions/event';
import useLoginModal from '../Hooks/useLoginModal';
import Alert,{notify} from "../Components/Home/Alert"
import { Helmet } from 'react-helmet';

import setMixPanel from '../utils/setMixPanel';


function Request({ submitRequestEvent, auth: {isAuthenticated, jwtToken} ,onRequest}) {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const loginModal = useLoginModal();
  const onSubmit = async (data) => {
    if(!isAuthenticated){
      loginModal.onOpen();
      return;
    }

    
    let modified_data = {
      ...data,
      jwtToken: jwtToken,
    };

    const status = await submitRequestEvent(modified_data);
    if (status === 200) {
      navigate("/?showToast=true");
      
    } else {
      navigate("/?showToast=false");
      
    }
  };


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Layout>
      <Helmet>
          <title>Events Around You</title>
          <meta
            name="title"
            content="Request for Events in India | Buy Tickets on Take My Tickets| Take My Tickets"
          />
          <meta
            name="description"
            content="Request to add to sell and buy tickets for concerts, sports events, music festivals, and more on India's top online ticket marketplace."
          />
        </Helmet>
        <Alert />
        <div className='relative sm:bg-bg-req h-[692px] sm:h-[902px] mt-[96px] sm:mt-0 overflow-show text-left text-[32px] text-black font-general-sans flex justify-center sm:items-center'>
          <div className='absolute rounded-xl bg-white h-[600px] flex flex-col lg:p-9 items-center sm:border-[1px] sm:border-solid sm:border-date-stroke sm:shadow-sm sm:box-border'>
            <div className='flex flex-col items-start justify-start px-[24px] gap-[48px] sm:px-0'>
              <div className='flex flex-col items-start justify-center gap-[24px] '>
                <div className='flex flex-row items-center justify-center'>
                  <div className='relative leading-[140%] font-semibold text-2xl lg:text-3xl sm:px-4 lg:px-0 '>
                    Request to add an event
                  </div>
                </div>
                <div className='flex flex-col items-start justify-start gap-[20px] text-base'>
                  <div className='flex flex-col items-start justify-start gap-[8px] sm:ml-4 lg:ml-0 '>
                    <div className='relative leading-[140%]  lg:px-0 text-sm lg:text-lg'>
                      Paste the URL of the event you want to be added
                    </div>
                    <Input
                      id='url'
                      label='URL'
                      type='text'
                      disabled={false}
                      register={register}
                      required={true}
                      errors={errors}
                      move={false}
                    />
                    {errors.url && (
                      <span className='text-red-500'>
                        Please enter a valid URL, eg. https://www.example.com
                      </span>
                    )}
                  </div>
                  <div className='flex flex-col items-start justify-start gap-[8px]'>
                    <div className='relative leading-[140%] sm:px-4 lg:px-0 text-sm lg:text-lg'>
                      Do you want to buy or sell tickets for this event?
                    </div>
                    <div className='flex flex-row py-1 items-start justify-start gap-[16px] text-lg sm:px-4 lg:px-0'>
                      <div className='flex flex-row items-center text-sm lg:text-lg justify-start gap-[8px]'>
                        <input
                          type='radio'
                          {...register('buySell', {
                            required: 'Please select an option',
                          })}
                          id='buy'
                          value='buy'
                          className='rounded-full  bg-white w-5 h-5 border-[1px] border-solid border-field-stroke shadow-inner'
                        />
                        <label
                          htmlFor='buy'
                          className='relative leading-[140%] font-medium'
                        >
                          Buy
                        </label>
                      </div>
                      <div className='flex text-sm lg:text-lg flex-row items-center justify-start gap-[12px]'>
                        <input
                          type='radio'
                          {...register('buySell', {
                            required: 'Please select an option',
                          })}
                          id='sell'
                          value='sell'
                          className='rounded-full bg-white w-5 h-5 border-[1px] border-solid border-field-stroke shadow-inner'
                        />
                        <label
                          htmlFor='sell'
                          className='relative leading-[140%] font-medium'
                        >
                          Sell
                        </label>
                      </div>
                    </div>
                    {errors.buySell && (
                      <span className='text-red-500'>
                        {errors.buySell.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className='rounded-lg bg-bg-green sm:w-[430px] flex flex-row p-4 box-border items-center justify-start gap-[12px] text-[36px] text-black font-inter'>
                <div className='relative leading-[20px]'>✌️</div>
                <div className='flex-1 flex flex-row items-start justify-start gap-[8px] text-sm text-slateblue'>
                  <div className='flex-1 flex flex-col items-start justify-start'>
                    <div className='self-stretch relative leading-[20px] font-general-sans text-green-text'>
                      Thanks for taking your time to fill this! We will do our
                      best to add this event as soon as possible.
                    </div>
                  </div>
                </div>
              </div>
              <button
                className='w-full mt-52 sm:mt-0 sm:w-[430px] h-[56px] px-4 py-3.5 flex justify-center items-center shadow-sm rounded-lg bg-[#FF3C6B] text-white text-center text-lg font-semibold lg:px-0'
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  submitRequestEvent,
})(Request);
