/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import Lottie from "lottie-react";
import { connect } from "react-redux";
import { toast } from "react-hot-toast";
import { FcOk } from "react-icons/fc";
import { LoadingOutlined } from "@ant-design/icons";

import useLoginModal from "../../Hooks/useLoginModal";
import useFetch from "../../Hooks/useFetch";
import axios from "../../axios-api";
import { updateProfile, whatsappAuth } from "../../redux/actions/auth";
import { analyticEventLogger } from "../../Analytics/eventLogger";
import { LOGIN_EVENT_CLICK } from "../../Analytics/eventLogConstants";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "../Button";
import Mail from "../../Icons/Mail.json";
import styles from "./modalLogin.module.css";
import InputWithLabel from "../inputs/InputWithLabel";
import CreateAccount from "./CreateAccount";
import { set } from "react-hook-form";
import { sendEmail } from "./apiUtils";

import setMixPanel from "../../utils/setMixPanel";

const ModalLogin = ({
  auth: { userData },
  isOpen,
  onClose,
  onSubmit,
  disabled,
  secondaryAction,
  imgSrc,
  whatsappAuth,
  updateProfile,
}) => {
  const [showModal, setShowModal] = useState(isOpen);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [logIn, setLogIn] = useState(false);
  const [isMailClicked, setIsMailClicked] = useState(false);
  const [showGoogleSignIn, setShowGoogleSignIn] = useState(true);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailEmptyMsg, setEmailEmptyMsg] = useState(null);
  const [resendLinkCount, setIsResendLinkCount] = useState(0);
  const [seconds, setSeconds] = useState(30);
  const interval = useRef(null);
  const loginmodal = useLoginModal();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { handleGoogle, loading, error } = useFetch(
    `${axios.defaults.baseURL}/v1/auth/google`
  );

  const modalClose = () => {
    loginmodal.onClose();
  };

  /* This useEffect() does google Authentication */
  useEffect(() => {
    setShowModal(isOpen);
    if (window.google) {
      /* Creates JWT Tokenfor google Authentication*/
      window.google.accounts.id.initialize({
        client_id:
          "16541858401-7f1kud2eu62f3vjasftihb99en2n98dd.apps.googleusercontent.com",
        callback: (response) => {
          handleGoogle(response);
          setLogIn(true);
          analyticEventLogger(LOGIN_EVENT_CLICK, {
            logginSuccess: response?.credential,
          });

        },
      });

      /* This one creates google sign-in button can change css here to change btn looks*/
      window.google.accounts.id.renderButton(
        document.getElementById("signUpDiv"),
        {
          /*
          type: "standard",
          size: "large",
          width: 300,
          text: "continue_with",
          */

          type: "standard",
          size: "extra_large",
          width: 328,
          height: 52,
          theme: "filled", // or "filled"
          shape: "rectangular", // or "pill"
         // text: "continue_with",
        }
      );

      // This is added feature of google sign-in -> google.accounts.id.prompt()
    }

    // const script = document.createElement('script');
    // script.src = 'https://otpless.com/auth.js';
    // script.id = 'otplessIdScript';
    // document.body.appendChild(script);
    // window.otpless = (otplessUser) => {
    //   console.log(otplessUser);
    //   whatsappAuth(otplessUser);
    //   setLogIn(true);
    // };
    // return () => {
    //   let scr = document.getElementById('otplessIdScript');
    //   document.body.removeChild(scr);
    //   // modalClose();
    // };
  }, [isOpen, whatsappAuth,showGoogleSignIn, isMailClicked]);

  useEffect(() => {
    if (logIn) {
      modalClose();
      handleLoginSuccess();
    }
  }, [logIn]);

  useEffect(() => {
    if (userData?.email && params?.get("token")) {
      if (userData?.name) {
        handleLoginSuccess();
        navigate("/");
      } else if (!email) setEmail(userData.email);
    }
  }, [userData]);

  /* This handleLoginSuccess is small pop up at bottom of screen */

  const handleLoginSuccess = () => {
    const notify = (message) =>
      toast(<h5>{message ? message : "Login Succesful"}</h5>, {
        icon: <FcOk />,
        duration: 2000,
        position: "bottom-center",
        style: { fontSize: "13px" },
      });      
    notify("Login Succesful");

    /* added navigate to navigate users once login is done*/
    // navigate("/sell-tickets");
    // window.location.href = "/sell-tickets";
  };

  const handleClose = useCallback(() => {
    if (disabled) {
      return;
    }

    setShowModal(false);
    setTimeout(() => {
      onClose();
    }, 300);
  }, [onClose, disabled]);

  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const handleClick = async (asd) => {
    modalClose();
    await delay(500);
    let ele = document.getElementById("otpless-floating-button");
    ele.style.display = "block";
  };

  const handleSubmit = useCallback(() => {
    if (disabled) {
      return;
    }

    onSubmit();
  }, [onSubmit, disabled]);

  const handleSecondaryAction = useCallback(() => {
    if (disabled || !secondaryAction) {
      return;
    }

    secondaryAction();
  }, [secondaryAction, disabled]);

  // if (!isOpen) {
  //   return null;
  // }
  const handleClickInside = useCallback((event) => {
    event.stopPropagation();
  }, []);

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const onClickContinueWithMail = () => {
    setIsMailClicked(true);
    setShowGoogleSignIn(email?.includes('gmail.com'));
  };
  const onClickContinueWithEmail = () => {
    if (!isLoading && !resendLinkCount) {
      if (email?.trim()?.length && validateEmail(email?.trim())) {
        setIsLoading(true);
        sendEmail(email).then((data) => {
          setIsLoading(false);
          setIsEmailSent(true);
          setIsResendLinkCount((prev) => prev + 1);
          setSeconds(30);
        });

      } else {
        setEmailEmptyMsg("Please enter a valid email to proceed");
      }
    }
  };

  useMemo(() => {
    if (resendLinkCount) {
      interval.current = setInterval(() => {
        if (seconds) setSeconds((prev) => prev - 1);
      }, 1000);
    } else clearInterval(interval.current);
  }, [resendLinkCount]);

  const resendLabel = useMemo(() => {
    if (seconds === 0) {
      setIsResendLinkCount(0);
      return "Resend Link";
    }
    if (resendLinkCount) return `Resend Link in ${seconds} seconds...`;

    return "Resend Link";
  }, [resendLinkCount, seconds]);

  const onNameChange = (e) => {
    setUsername(e?.target?.value);
    if (emailEmptyMsg) setEmailEmptyMsg(null);
  };

  const onCreateAccount = async () => {
    if (!isLoading) {
      if (username?.trim()?.length) {
        let form = new FormData();
        form.append("imageOption", 0);
        form.append("username", username.trim());
        form.append("image", null);
        form.append("isNewUser", true);

        setIsLoading(true);
        updateProfile(form).then(() => {
          setIsLoading(false);
          modalClose();
        });
      } else {
        // reuse same state for username validation as well
        setEmailEmptyMsg("Please enter a name to proceed");
      }
    }
  };

  return (
    <>
      {isOpen && (
        <div
          onClick={() => modalClose()}
          className="
          justify-center 
          items-center 
          flex 
          overflow-x-hidden 
          overflow-y-auto 
          fixed 
          inset-0 
          z-50 
          outline-none 
          focus:outline-none
          bg-neutral-800/70
          font-general-sans
        "
        >
          <div
            className="relative rounded-xl bg-white shadow-sm lg:w-[410px]
          md:w-2/5
          w-5/6
          m-auto 
          overflow-hidden
          text-[32px]"
            onClick={handleClickInside}
          >
            <div className="px-5 py-8 sm:p-9 h-full flex flex-col items-start justify-start gap-[16px] sm:gap-[24px]">
              <div className="flex justify-between items-center w-full pb-5 sm:pb-6">
                <img src="/images/logo.svg" alt="logo" height={41} width={64} />
                <div className="rounded-[42px] w-9 h-9 bg-white shadow-sm overflow-hidden flex flex-row p-2 items-center justify-center border-[1px] border-solid border-date-stroke cursor-pointer">
                  <IoMdClose
                    className="relative w-4 h-4 overflow-hidden shrink-0"
                    onClick={loginmodal.onClose}
                  />
                </div>
              </div>
              {params?.get("token") ? (
                <CreateAccount
                  email={email}
                  name={username}
                  onNameChange={onNameChange}
                  nameValidationMsg={emailEmptyMsg}
                  onCreateAccount={onCreateAccount}
                  isLoading={isLoading}
                />
              ) : isEmailSent ? (
                <div className="flex flex-col flex-1">
                  <Lottie
                    className={styles.sentMailImage}
                    animationData={Mail}
                    loop={true}
                  />
                  <div className="text-2xl font-semibold pt-6">
                    Check your inbox for a link
                  </div>
                  <div className="text-sm sm:text-lg pt-2 sm:pt-3">
                    Click the link we sent to{" "}
                    <span className={styles.subTextEmail}>{email}</span> to sign
                    in.
                  </div>
                  <Button
                    outline={true}
                    className={styles.resendButton}
                    label={resendLabel}
                    disabled={seconds !== 0}
                    onClick={onClickContinueWithEmail}
                    {...(isLoading && {
                      customIcon: <LoadingOutlined className="text-[#000]" />,
                      label: "",
                    })}
                  />
                </div>
              ) : (
                <>
                  <div className={styles.loginHeading}>
                    Login or Sign up to Takemytickets
                  </div>
                  {isMailClicked ? (
                    <>
                      <InputWithLabel
                        className="sm:pt-3 text-[#101010] font-medium"
                        label="Email"
                        key="user-email"
                        type="email"
                        validationMsg={emailEmptyMsg}
                        value={email}
                        onChange={(e) => {
                          if (emailEmptyMsg) {
                            setEmailEmptyMsg(null);
                          }
                          setEmail(e.target.value);
                          const isValidEmail = validateEmail(e.target.value);
                          if(isValidEmail){
                          setShowGoogleSignIn(e.target.value.includes('gmail.com'));


                          }
                          
                          
                        }}
                      />
                      <Button
                        className={styles.continueEmail}
                        label="Continue"
                        onClick={onClickContinueWithEmail}
                        {...(isLoading && {
                          customIcon: (
                            <LoadingOutlined className="text-[#fff]" />
                          ),
                          label: "",
                        })}
                      />
                      {showGoogleSignIn && (
                      <div
                        id="signUpDiv"
                        className="text-[#101010] font-semibold w-full flex items-center justify-center"
                      ></div> )}
                      <Button
                        className={styles.allSignIn}
                        label="All Sign in options"
                        customIcon={<img src="/images/icons/CaretLeft.svg" alt="caret"  />}
                        onClick={() => {
                          setIsMailClicked(false);
                          setShowGoogleSignIn(true);
                          }}
                          
                      />
                    </>              
                  ) : (
                     <>
                    {showGoogleSignIn && (
                      <div
                        id="signUpDiv"
                        className="text-[#101010] font-semibold w-full flex items-center justify-center"
                      ></div> )}
                      <div className="relative w-full h-[22px] text-base text-secondary-grey">
                        <div className="absolute top-[12.5px] left-[-0.5px] box-border md:w-full w-full h-px border-t-[1px] border-solid border-date-stroke" />
                        <div className="absolute left-[42%] md:left-[calc(50%_-_11px)] bg-white flex flex-col py-0 px-1 items-start justify-start">
                          <div className="relative">or</div>
                        </div>
                      </div>
                      <Button
                        className={styles.continueEmailButton}
                        label="Continue with email"
                        onClick={onClickContinueWithMail}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  whatsappAuth,
  updateProfile,
})(ModalLogin);
