import React from 'react';
import styles from "./listing.module.css";

const TicketImage = ({ imageUrl, altText }) => {
  return (
    <div>
      <img alt={altText} className={styles["ticket-img"]} src={imageUrl} />
    </div>
  );
};

export default TicketImage;
