import { Button, HStack, Heading } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useLoginModal from "../../Hooks/useLoginModal";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { analyticEventLogger } from "../../Analytics/eventLogger";
import { SELLER_TICKET_EVENTPAGE_EVENT_CLICK } from "../../Analytics/eventLogConstants";

const TicketSell = ({
  auth: { isAuthenticated }
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // pull event details from redux hook

  const { slug } = useParams();

  const loginModal = useLoginModal();
  const navigate = useNavigate();
  const handleSellTickets = () => {
    analyticEventLogger(SELLER_TICKET_EVENTPAGE_EVENT_CLICK, {
      isAuthenticated: isAuthenticated
    })
    if (!isAuthenticated) {
      loginModal.onOpen();      
    } else {
      navigate(`/seller-form/${slug}`);
    }
  };
  // useEffect(() => {
  //   if (isAuthenticated) {
  //     // Close the login modal if the user is authenticated
  //     loginModal.onClose();
  //   }
  // }, [isAuthenticated, loginModal]);
  return (
    <HStack width="full" justifyContent="space-between" mb={4}>
      <Heading as="h1" fontSize="2xl">
        Tickets
      </Heading>
      <Button size="sm" variant="solid" colorScheme="brand" onClick={handleSellTickets}>
        Sell Tickets
      </Button>
    </HStack>
  );
};

const mapStateToProps = (state) => ({auth: state.auth});

export default connect(mapStateToProps, {})(TicketSell);
