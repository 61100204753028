import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import useLoginModal from '../../Hooks/useLoginModal';
import ModalLogin from './ModalLogin';

const Login = () => {
  const loginModal = useLoginModal();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <ModalLogin
      disabled={isLoading}
      isOpen={loginModal.isOpen}
      imgSrc='/images/login/login.jpg'
    />
  );
};

export default Login;
