/* eslint-disable import/no-anonymous-default-export */
import {
    FETCH_SEARCH_DROPDOWN,
    FETCH_TRENDING_ALL_EVENTS,
    FETCH_BUYER_FLOW_EVENT,
    FETCH_SELLER_FLOW_EVENT
} from '../actions/types';

const initialState = {
    searchDropDownList: [],
    allTrendingEvents: [],
    allTrendingEventsLoading: true,
    buyerFlowEvent: {},
    sellerFlowEvent: {}
};

export default function(state = initialState, action){
    const { type, payload } = action;

    switch(type){
        case FETCH_SEARCH_DROPDOWN:
            return {
                ...state,
                searchDropDownList: payload
            }
        case FETCH_TRENDING_ALL_EVENTS:
            return {
                ...state,
                allTrendingEvents: payload,
                allTrendingEventsLoading: false,
            }
        case FETCH_BUYER_FLOW_EVENT:
            return {
                ...state,
                buyerFlowEvent: payload
            }
        case FETCH_SELLER_FLOW_EVENT:
            return {
                ...state,
                sellerFlowEvent: payload
            }
        default:
            return state;
    }
}