import React from 'react';
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import useLoginModal from "../../Hooks/useLoginModal"
import { analyticEventLogger } from '../../Analytics/eventLogger';
import { SELLER_TICKET_NAVBAR_EVENT_CLICK } from "../../Analytics/eventLogConstants";

import styles from "./home.module.css";

const GetInTouch = ({ auth: { userData } }) => {
    const loginModal = useLoginModal();
    const navigate = useNavigate();
  
    const handleClick = () => {
      analyticEventLogger(SELLER_TICKET_NAVBAR_EVENT_CLICK, {
        isAuthenticated: userData?.name
      });
      if (userData?.name) {
        return navigate("/sell-tickets");
      } else {
        loginModal.onOpen();
      }
    };
  
    return (
        <div className={styles.gitContainer}>
            <div className={styles.gitHeaderContainer}>
                <div className={styles.gitHeader}>
                  <h1 className={styles.gitTitle}>Got extra tickets to an {window.innerWidth<600?<br/>:<></>}event?</h1>
                  <p className={styles.gitSubtitle}>List your tickets now to find buyers soon</p>
                </div>

                <button onClick={handleClick} className={styles.gitButton}>Sell your tickets now</button>
            </div>

            <div className={styles.gitImgContainer}>
                <img src={window.innerWidth > 600 ? '/images/banner/git-pc.svg' : '/images/banner/git-mobile.svg'} alt='get-in-touch' className={styles.gitImg}/>
            </div>    
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
  });

  export default connect(mapStateToProps, {})(GetInTouch);