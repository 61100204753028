import axios from '../../axios-api';

export const sendEmail = async (emailId) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const data = {
      email: emailId
    };

    const resData = await axios.post(`/v1/auth/login`, data, config);

    return resData;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export const authenticateUser = async (token) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const resData = await axios.get(`/v1/auth/login/${token}`, config);

    return resData;
  } catch (err) {
    console.log(err);
    return null;
  }
}