import {
    GET_IN_TOUCH_CLICK
} from './types';

import axios from '../../axios-api';

export const getInTouchClickAnalytics = (ticketID) => async dispatch => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }        
        await axios.put(`/v1/analytics/getintouch/${ticketID}`, config);
    } catch (err) {
        console.log(err);
    }
}