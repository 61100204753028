import { Flex, HStack, Heading, Image, Text, VStack } from '@chakra-ui/react';
import React from 'react';

import { formatDateTime } from '../../utils/datetime';
import { analyticEventLogger } from '../../Analytics/eventLogger';
import { LOCATION_BUYER_PAGE_EVENT_CLICK } from '../../Analytics/eventLogConstants';

const EventHeaders = ({ image, title, location, dateTime, locationURL }) => {
  const openGMap = (url) => {
    analyticEventLogger(LOCATION_BUYER_PAGE_EVENT_CLICK, {});
    window.open(url, '_blank');
  };

  return (
    <>
      <Flex textAlign='center' justify={{ base: 'center', lg: 'flex-start' }}>
        <Image
          src={image}
          alt='Event Banner'
          width={240}
          height={255}
          className='aspect-square  rounded-md '
          rounded='lg'
        />
      </Flex>
      <Heading fontSize={{ base: '2xl', lg: '3xl' }}>{title}</Heading>
      <VStack alignItems='flex-start' py={0} gap={3}>
        <HStack
          alignItems='center'
          onClick={() => openGMap(locationURL)}
          style={{ cursor: 'pointer' }}
        >
          <Text padding={2} bg='gray.200' rounded='md'>
            {/* <CalenderBlank size={20} color='black' />
             */}
            <img
              src='/images/icons/MapPin.svg'
              className='w-5 h-5'
              alt='mapPin'
            />
          </Text>
          <Text fontSize={{ base: '16px', lg: '16px' }} color='#101010'>
            {location ? location : 'Venue to be announced'}
          </Text>
        </HStack>
        <HStack alignItems='center'>
          <Text padding={2} bg='gray.200' rounded='md'>
            {/* <CalendarFilled size={20} color='black' />
             */}
            <img
              src='/images/icons/CalendarBlank.svg'
              className='w-5 h-5'
              alt='calendarBlank'
            />
          </Text>
          <Text fontSize={{ base: '16px', lg: '16px' }} color='#101010'>
            {formatDateTime(dateTime, 'ddd, MMM D [at] h:mm a')}
          </Text>
        </HStack>
      </VStack>
    </>
  );
};

export default EventHeaders;
