import React from "react";
import { HStack, Box, Text } from "@chakra-ui/react";
import { LuTicket } from "react-icons/lu";
import { ChevronRightIcon } from "@chakra-ui/icons";

const EnabledTicket = ({ title, count, startingPrice, handleBuyTicket, category }) => {
  return (
    <HStack
      border="1px"
      borderColor="#F0F0F0"
      rounded="md"
      w="full"
      cursor="pointer"
      justifyContent="space-between"
      p={4}
      _hover={{
        bg: "gray.50",
      }}
      onClick={() => handleBuyTicket(category)}
    >
      <Box>
        <Text fontSize={{ base: "normal", lg: "lg" }} fontWeight="semibold">
          {title}
        </Text>
        <HStack color="black">
          <LuTicket size={18} />
          <Text>{count} tickets available</Text>
        </HStack>
      </Box>
      <HStack gap={{ base: 1, lg: 3 }}>
        <Box fontSize={{ base: "xs", lg: "sm" }} textAlign="center">
          <Text
            fontSize={{ base: "xs", lg: "sm" }}
            color="green.500"
            fontWeight="medium"
          >
            ₹{startingPrice}
          </Text>
          <Text
            fontSize={{ base: "xs", lg: "sm" }}
            color="green.500"
            fontWeight="medium"
          >
            onwards
          </Text>
        </Box>
        <ChevronRightIcon boxSize={6} color="black" />
      </HStack>
    </HStack>
  );
};

export default EnabledTicket;
