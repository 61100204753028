import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Lottie from "lottie-react";

import styles from "./verify.module.css";
import Layout from "../Layout/Layout";
import CopyToClipboard from "react-copy-to-clipboard";
import { useNavigate, useParams } from "react-router-dom";
import {
  verifyTickets,
  addPrePostTicketData,
} from "../../redux/actions/ticket";
import { formatDateTime } from "../../utils/datetime";
import { Skeleton } from "antd";
import axios from "../../axios-api";

import ProgressBar from "../ProgressBar/ProgressBar";
import VerifyPopup from "./VerifyPopup";
import SearchBar from "../../Icons/SearchBar.json";
import PaytmInsider from "../../Icons/PaytmInsider.json";
import BookMyShow from "../../Icons/BMS.json";

import setMixPanel from "../../utils/setMixPanel";

const Verifyticket = ({
  email,
  event: { sellerFlowEvent },
  auth: { userData },
  ticket,
  addPrePostTicketData,
}) => {
  const [gifUrl, setGifUrl] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    
  }, []);
  const navigate = useNavigate();
  const [copiedTicket, setCopiedTicket] = useState(false);
  const [copiedMail, setCopiedMail] = useState(false);
  const [verifyLoad, setVerifyLoad] = useState(false);
  const [error, setError] = useState(false);
  const[errorState,setErrorState] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [showNotifyTeamModal, setShowNotifyTeamModal] = useState(false);
  const [showConfirmScreen, setShowConfirmScreen] = useState(false);
  const [eventDetails, setEventDetails] = useState({});
  const keyword = sellerFlowEvent.eventName;
  const mailtoCopy = "tickets@takemytickets.co.in";
  const [tryAgainClicks, setTryAgainClicks] = useState(0);
  const { selectedPlatform } = useParams();


  const notifyTeam = async () => {

    try {
      const response = await axios.post("/v1/user/notifyteam", {
        userId: userData._id,
        eventId: sellerFlowEvent._id,
      });
    } catch (error) {
      console.error("Error notifying the team:", error.response.data);
    }
  };

  const handleTryAgain = () => {
    if (tryAgainClicks < 2) {
      setTryAgainClicks(tryAgainClicks + 1);
    } else {
      setShowNotifyTeamModal(true);
      setError(false);
      return;
    }
    setVerifyLoad(false);
    setShowPopup(false);
    handleClick(tryAgainClicks + 1);
  };

  const handleCopyTicket = () => {
    setCopiedTicket(true);
    setTimeout(() => setCopiedTicket(false), 3000);
  };

  const handleCopyMail = () => {
    setCopiedMail(true);
    setTimeout(() => setCopiedMail(false), 3000);
  };

  async function verifyWithinTimeframe(data, timeout) {
      const response = await verifyTickets(data);
      return response;
}

  const handleClick = async (tryAgainClicks) => {

    setShowConfirmScreen(false);
    setVerifyLoad(true);
    const data = {
      eventID: sellerFlowEvent?._id,
      userID: userData?._id,
      platform: selectedPlatform,
    };
    let timeout = 1000;
    if (tryAgainClicks < 1) timeout = 25000;
    if (tryAgainClicks === 1) timeout = 15000;

    setTimeout(async () => {
      const response = await verifyWithinTimeframe(data, timeout);

      if (response?.data?.verificationStatus === "success") {
        setEventDetails(response?.data);
        let data = {
          eventID: sellerFlowEvent._id,
          categoryID: response?.data.eventCategory,
          verificationId: response?.data.verificationId,
          categoryPrice: Number(
            response.data.totalPrice / response.data.quantity
          ),
          ticketCount: response.data.quantity,
          maxTicketCount: response.data.quantity,
          selectedPlatform: selectedPlatform
        };
        await addPrePostTicketData(data);
        setError(false);
        setErrorState('');
        setVerifyLoad(false);
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
          navigate("/price-details");
        }, 2000);
      } else {
        setVerifyLoad(false);
        setShowPopup(true);
        setError(true);
        setErrorState(response?.data?.msg);
      }
    }, 10000);
  };

  const handleClose = () => {
    setShowConfirmScreen(false);
  };

  return (
    <Layout className={styles.backgroundLayout} isfooter={window.innerWidth < 600?false:true}>
      <Helmet>
        <title>Verify tickets</title>
        <meta
          name="Sell Tickets Easily in India | Reach Your Audience on Take My Tickets"
          content="Start selling your event tickets hassle-free on Take My Tickets, India's trusted ticket resale platform. Add seller contact details effortlessly."
        />
        <meta charset="UTF-8" />
      </Helmet>
      <div className={styles.verify}>
        <div className={styles.verifyHeaderContainer}>
          <h2 className={styles.verifyHeader}>
            Forward your <span style={{color:'#ff2459'}}>ticket confirmation email</span> to verify
          </h2>
          <p className={styles.verify_cont}>
            To ensure ticket validity and fair pricing, we need your consent to
            verify ticket details. We never use or share your tickets with
            anyone.
          </p>
        </div>

        <div className={styles.verifyMainContainer}>
          <div className={styles.verifyStep1}>
            <img
              className={styles.stepIcon}
              src={"/images/icons/step1.svg"}
              alt="step-1"
            />

            <div className={styles.findTicket}>
              <div className={styles.mailContainer}>
                <h3 className="text-sm font-semibold">
                  {" "}
                  Find your ticket in mail by pasting this keyword{" "}
                </h3>
              </div>

              <div className={styles.inputContainer}>
                <h3 className={styles.tmtmail}>{keyword}</h3>

                <CopyToClipboard text={keyword} onCopy={handleCopyTicket}>
                  <div className={styles.copyDiv}>
                    {!copiedTicket ? (
                      <>
                        <p className="text-brand text-sm font-medium cursor-pointer">
                          Copy
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="text-[#009D64] text-sm font-medium cursor-pointer flex ">
                          <img
                            className="mr-1"
                            src="/images/icons/green_tick.svg"
                            alt=""
                          />{" "}
                          Copied!
                        </p>
                      </>
                    )}
                  </div>
                </CopyToClipboard>
              </div>

              
              <Lottie className={styles.mailImage} animationData={SearchBar} loop={true}/>
            </div>
          </div>

          <div className={styles.verifyStep1}>
            <img
              className={styles.stepIcon}
              src={"/images/icons/step2.svg"}
              alt="step-1"
            />

            <div className={styles.findTicket}>
              <div className={styles.mailContainer}>
                <h3 className="text-sm font-semibold">
                  {" "}
                  Forward mail to this address
                </h3>
                {/* <p className="text-sm font-normal">
                  After forwarding, return to this page to complete the process.
                </p> */}
              </div>

              <div className={styles.inputContainer}>
                <h3 className={styles.tmtmail} style={{ wordWrap: "anywhere" }}>
                  {mailtoCopy}
                </h3>

                <CopyToClipboard text={mailtoCopy} onCopy={handleCopyMail}>
                  <div className={styles.copyDiv}>
                    {!copiedMail ? (
                      <>
                        <p className="text-brand text-sm font-medium cursor-pointer">
                          Copy
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="text-[#009D64] text-sm font-medium cursor-pointer flex ">
                          <img
                            className="mr-1"
                            src="/images/icons/green_tick.svg"
                            alt=""
                          />{" "}
                          Copied!
                        </p>
                      </>
                    )}
                  </div>
                </CopyToClipboard>
              </div>

              {/* <div className={styles.imageContainer}>
                <img
                  className={styles.mailImage}
                  src={"/images/forward.png"}
                  alt="mail"
                />
              </div> */}

              <Lottie 
                className={styles.mailImage} 
                animationData={selectedPlatform === "BookMyShow"? BookMyShow:PaytmInsider} 
                loop={true}
              />
              
            </div>
          </div>

          
          <div className={styles.bottomButton} >
          {!verifyLoad && <ProgressBar className={styles.verifyProgressBar} progress={66.66} bottom={94} />}
          <div
            className={`${
              styles.proceedButton
            } flex relative justify-between m-auto mt-8 sm:m-0 ${
              verifyLoad ? "invisible" : ""
            }`}
          >
            <button
              onClick={()=>setShowConfirmScreen(true)}
              className={styles.nextBut}
            >
              I’ve forwarded my tickets!
            </button>
          </div>
          </div>
        </div>
      </div>

      {showConfirmScreen && <VerifyPopup handleClick={handleClick} tryAgainClicks={tryAgainClicks} handleClose={handleClose}/>}

      {showPopup && (
        <div className={styles.loaderOverlay}>
          <div className={styles.loaderContainer}>
            {error ? (
              <div style={{ maxWidth: "343px", padding: "24px" }}>
                <img
                  className="w-12 h-12 mb-3"
                  src={"/images/icons/error.svg"}
                  alt="error"
                />
                <div className={styles.errorstate}>
                  {errorState === "Already Ticket Exists"?
                  <>
                    <h5 className="text-[black] text-base not-italic font-semibold">
                    These tickets has already been listed
                    </h5>
                    <p className="text-[#918f8f] text-sm">
                     In order to edit price and quantity you can do it in my listings.
                    </p>

                    <button onClick={()=> {
                      setError(false);
                      setShowPopup(false);
                      navigate("/my-listing");
                    }} className={styles.errorBtn}>
                      Take me to my listings
                    </button>
                  </> :
                  <>
                    <h5 className="text-[black] text-base not-italic font-semibold">
                      Please make sure that the email address you used to purchase
                      the tickets is the same you've used to log in to our website
                    </h5>
                    <p className="text-[#918f8f]">
                      Make sure you share tickets for “{" "}
                      <span className="text-black">{keyword}</span>” or select a
                      different event in the previous step.
                    </p>

                    <button onClick={handleTryAgain} className={styles.errorBtn}>
                      Try again
                    </button>
                  </>}
                </div>

                
              </div>
            ) : showNotifyTeamModal ? (
              <div style={{ maxWidth: "343px", padding: "24px" }}>
                <img
                  className="w-12 h-12 mb-3"
                  src={"/images/icons/thinkingFace.png"}
                  alt="error"
                />
                <div className={styles.errorstate}>
                  <h5 className="text-[black] text-base not-italic font-semibold">
                    We’re not able to verify your tickets automatically!
                  </h5>
                  <p>
                    Please notify us and we’ll get your tickets verified within
                    24 hours.
                  </p>
                </div>

                <button
                  onClick={() => {
                    notifyTeam();
                    setShowNotifyTeamModal(false);
                    setShowPopup(false);
                    navigate("/");
                  }}
                  className={styles.errorBtn}
                >
                  Notify the team!
                </button>
              </div>
            ) : (
              <div className={styles.ticketVerifyDiv}>
                <div className={styles.ticketVerifyInternalDiv}>
                  <img
                    style={{ borderRadius: "4px" }}
                    src={eventDetails.imageURL}
                  />
                  <div className={styles.ticketTitle}>
                    {eventDetails.eventName}
                  </div>
                  <div className={styles.ticketDetails}>
                    <div>
                      {formatDateTime(
                        eventDetails.eventDate,
                        "ddd, DD MMM YYYY"
                      )}{" "}
                      | {formatDateTime(eventDetails.eventDate, "h:mm a")}
                    </div>
                    <div>{eventDetails.eventLocation}</div>
                    <div>{eventDetails.quantity} ticket(s)</div>
                  </div>

                  <div className={styles.ticketAmount}>
                    <div>Total Amount</div>
                    <div style={{ fontWeight: "500" }}>
                      ₹ {eventDetails.totalPrice}
                    </div>
                  </div>
                </div>
                <div className={styles.ticketsVerifiedText}>
                  Wohoo! Your tickets have been verified!
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {verifyLoad && (
        <div className={styles.loaderOverlay}>
          <div className={styles.loaderContainer}>
            <div
              style={{ background: "#F5F5F5" }}
              className={styles.ticketVerifyDiv}
            >
              <div className={styles.ticketVerifyInternalDiv}>
                {/* <img style={{borderRadius:"4px"}} src={eventDetails.imageURL} /> */}
                <Skeleton.Input
                  className={styles.imageSkeleton}
                  active={true}
                  size={"large"}
                />
                <div className={styles.ticketTitle}>
                  {eventDetails.eventName}
                </div>
                <div className={styles.ticketDetails}>
                  <Skeleton.Input
                    className={styles.titleSkeleton}
                    active={true}
                    size={"large"}
                  />
                  <Skeleton.Input
                    className={styles.descSkeleton}
                    active={true}
                    size={"large"}
                  />
                  <Skeleton.Input
                    className={styles.descSkeleton}
                    active={true}
                    size={"large"}
                  />
                  <Skeleton.Input
                    className={styles.smallSkeleton}
                    active={true}
                  />
                  {/* <div>{formatDateTime(eventDetails.eventDate, 'ddd, DD MMM YYYY')}  | {formatDateTime(eventDetails.eventDate, 'h:mm a')}</div>
                        <div>{eventDetails.eventLocation}</div>
                        <div>{eventDetails.quantity} ticket(s)</div> */}
                </div>

                <div
                  style={{ background: "var(--Brand-Light, #FFF2F2)" }}
                  className={styles.ticketAmount}
                >
                  <div>Total Amount</div>
                  <div style={{ fontWeight: "500" }}>
                    <Skeleton.Input
                      className={styles.smallSkeleton}
                      active={true}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.skeletonLoadingText}>
                <div>Verifying your ticket </div>
                <div>Keep this tab open for 2-3 mins! </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  event: state.event,
  auth: state.auth,
  ticket: state.ticket,
});

export default connect(mapStateToProps, { addPrePostTicketData })(Verifyticket);
