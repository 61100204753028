import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { GET_IN_TOUCH_EVENT_LOG } from "../../Analytics/eventLogConstants";
import { analyticEventLogger } from "../../Analytics/eventLogger";
import styles from "../Seller/seller.module.css";
import axios from "../../axios-api";
import setMixPanel from "../../utils/setMixPanel";

const PopupScreen = ({
  onGetInTouch,
  title,
  message,
  setTermsCheck,
  onClose,
  isOpen,
  selectedSeller,
  userData,
  ticket: { eventTicket },
  event: { buyerFlowEvent },
}) => {
  useEffect(()=>{
    if (!checked) {
    }
  })

  const navigate = useNavigate();
  const handleClose = () => {
    onClose();
  };
  const [checked, setChecked] = useState(false);

  

  const handleCheckboxChange = (e) => {
    setTermsCheck(e.target.checked);
    setChecked(e.target.checked);
  };

  const handleGetInTouch = () => {
    let data = eventTicket.filter(
      (t) => t.user._id === String(localStorage.getItem("BuyerPopUpUserID"))
    )[0];
    const userAgent = window.navigator.userAgent.toLowerCase();
    const mobileAgents = /iphone|ipad|android/;

    let isMobile = mobileAgents.test(userAgent);
    analyticEventLogger(GET_IN_TOUCH_EVENT_LOG, {
      button_name: "Get_In_Touch",
      isMobile: isMobile,
      environment: process.env.NODE_ENV,
    });
    let { user, ticketCategoryName } = data;

    let { name, mobile } = user;

    let whatsappurl = "";
    if (isMobile) {
      whatsappurl = `https://wa.me/91${mobile}?text=Hi%20${name}%2C%0AI%20found%20your%20listing%20for%20*${ticketCategoryName}%20tickets%20to%20${buyerFlowEvent.eventName}*%20on%20Take%20My%20Tickets%2C%20and%20I’m%20interested%20in%20buying%20it.%0A%0ALooking%20forward%20to%20your%20response.%20Thanks!%20✌%EF%B8%8F`;
      window.open(whatsappurl);
    } else {
      whatsappurl = `https://web.whatsapp.com/send?phone=${mobile}&text=Hi%20${name}%2C%0AI%20found%20your%20listing%20for%20*${ticketCategoryName}%20tickets%20to%20${buyerFlowEvent.eventName}*%20on%20Take%20My%20Tickets%2C%20and%20I’m%20interested%20in%20buying%20it.%0A%0ALooking%20forward%20to%20your%20response.%20Thanks!%20✌%EF%B8%8F`;
      window.open(whatsappurl, "_blank");
    }
    setTimeout(() => {
      onClose();
    }, 1000);
  };
  return (
    <div className={styles["popup-container"]}>
      <div className={styles.popup}>
        <button className={styles.popclose} onClick={handleClose}>
          <img src="/images/icons/x.svg" className="w-6 h-6" alt="Close" />
        </button>
        <h2 className={styles["popup-title"]}>{title}</h2>
        <div className={styles["popup-message"]}>
          <img
            src="/images/icons/tickett.svg"
            alt=""
            className="mt-8 self-center border-8 rounded-full border-white "
          />
          <div>
            <div
              className={`${styles["ss"]} text-base text-[#101010] font-semibold leading-5 mb-2`}
            >
              Say no to screenshots!
            </div>
            <div
              className={`md:text-base text-md text-[#101010] ${styles["popinfo"]}`}
            >
              Kindly ask the sellers to transfer the tickets to either your
              email ID or phone number.
              <a
                className={`text-brand md:text-base text-md inline underline ${styles["popinfo"]} px-1`}
                href="https://linktr.ee/take_my_tickets"
                target="_blank"
                rel="noopener norefferer"
              >
                Learn how to get your tickets transferred.
              </a>
            </div>
          </div>
        </div>

        <div className={styles["popup-checkbox"]}>
          <input
            type="checkbox"
            id="terms"
            className="text-[#FF2459]"
            style={{
              width: "80px",
              height: "25px",
              display: "flex",
              alignSelf: "baseline",
              paddingTop: "24px;",
              marginTop: "4px",
              border: 0,
            }}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="terms" className="text-[#101010] ml-0">
            I acknowledge and accept the and
            <a
              className="text-brand underline px-1"
              href="/terms-of-service"
              target="_blank"
              rel="noopener norefferer"
            >
              Terms of service
            </a>{" "}
            {""}
            and{" "}
            <a
              className="text-brand underline  px-1"
              href="/privacy-policy"
              target="_blank"
              rel="noopenner noreferrer"
            >
              {" "}
              Privacy policy
              <span className="text-black no-underline">.</span>
            </a>
            I understand that TMT connects buyers and sellers and is not
            responsible for any risks or potential scams by sellers.
          </label>
        </div>
        <button
          className={`${styles["popup-button"]} ${styles["sec-button"]} ${
            !checked ? styles.disabled : ""
          }`}
          onClick={handleGetInTouch}
          disabled={!checked}
        >
          <img
            src="/images/icons/whatsapp.svg"
            className="w-5 h-5"
            alt="wpIcon"
          />
          Get In Touch
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ticket: state.ticket,
  event: state.event,
});

export default connect(mapStateToProps, {})(PopupScreen);
