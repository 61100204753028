import React from 'react';

const Button = ({
  label,
  onClick,
  disabled,
  outline,
  centered,
  small,
  icon: Icon,
  customIcon,
  className,
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`
        relative
        disabled:opacity-70
        disabled:cursor-not-allowed
        rounded-lg
        hover:opacity-80
        transition
        w-full
        font-general-sans
        font-semibold
        ${outline ? 'bg-white' : 'bg-rose-500'}
        ${outline ? 'border-black' : 'border-rose-500'}
        ${outline ? 'text-black' : 'text-white'}
        ${small ? 'py-1' : 'py-3'}
        ${small ? 'text-sm' : 'text-sm sm:text-md'}
        ${outline ? 'flex items-center' : ''}
         ${centered ? 'text-center font-semibold' : ''}
        ${centered ? 'bg-white text-black' : ''}
        ${className}
      `}
    >
      {customIcon && customIcon}
      {Icon && <Icon size={24} className={`${outline ? 'mr-2' : ''}`} />}
      {label}
    </button>
  );
};

export default Button;
