import React from 'react';
import styles from "./seller.module.css";
import { formatDateTime } from '../../utils/datetime';

const TicketIn = ({ id, eventName, date, venue, location }) => {
  return (
    <div className={styles.ticketIn} data-id={id}>
      <h3 className={styles.artistt}>{eventName}</h3>
      <p>{formatDateTime(date, 'ddd, MMM')} {formatDateTime(date, 'DD')}, {formatDateTime(date, 'h:mm a')}</p>
    </div>
  );
};

export default TicketIn;
