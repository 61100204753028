import React, { useState, useEffect } from 'react';
import { formatDateTime } from '../utils/datetime';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchDropDownSearchData } from '../redux/actions/event';

import setMixPanel from '../utils/setMixPanel';

const Search = ({ event: { searchDropDownList }, fetchDropDownSearchData }) => {
  useEffect(() => {
    fetchDropDownSearchData('');
  }, [fetchDropDownSearchData]);

  const browserLocation = useLocation();
  const navigate = useNavigate();

  let location = localStorage.getItem('selectedLocation');
  location = location ? location.toLowerCase() : 'all';

  const searchBarTextHandler = (data) => {
    setSearchTerm(data);
    setIsSearching(data.length > 0);
    fetchDropDownSearchData && fetchDropDownSearchData(data);
  };

  const [isSearching, setIsSearching] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isFocused, setFocused] = useState(false);
  const [isSearchItemsHovered, setSearchItemsHovered] = useState(false);

  const handleInputFocus = () => {
    setVisible(true);
    setFocused(true);
    if (
      window.innerWidth <= 768 &&
      !browserLocation.pathname.match(/^\/search$/)
    ) {
      setFocused(true);
      setVisible(true);
      navigate('/search');
    }
  };

  const handleInputBlur = () => {
    if (!isSearchItemsHovered) {
      setVisible(false);
      setSearchTerm('');
      setFocused(false);
    }
  };

  const handleEventClick = (event) => {
    navigate(`/${location}/` + event.eventSlug)
  }

  const sortedSearch = searchTerm
    ? searchDropDownList
    : searchDropDownList.slice().sort((a, b) => {
        const dateA = new Date(a.eventDate);
        const dateB = new Date(b.eventDate);
        return dateA - dateB;
      });

  return (
    <div className='relative flex flex-col text-sm gap-3 w-full md:w-[500px] text-black rounded-[90px] '>
      <div
        className={`relative border ${
          isFocused
            ? 'border-solid border-red-500 shadow-red-500'
            : 'border-solid border-[rgba(16,16,16,0.10)]'
        } rounded-[90px]`}
      >
        <input
          type='text'
          placeholder='Search by events'
          className='p-3 py-4 pl-16 rounded-[90px] w-full max-w-xl text-base sm:text-sm  text-black mx-auto'
          style={ { boxShadow:'0px 1px 6.5px 0px rgba(0, 0, 0, 0.06)'}}
          onFocus={handleInputFocus}
          onBlur={
            window.innerWidth <= 768 ? undefined : () => handleInputBlur()
          }
          onInput={(e) => searchBarTextHandler(e.target.value)}
          value={searchTerm}
        />
        <img
          src='images/icons/search.svg'
          className='absolute left-5 top-3.5 w-6 h-6 object-cover '
          alt='search-events'
        />
      </div>
      <div
        className={`searchItemsContainer absolute md:overflow-y-auto left-0 right-0 top-16 md:top-14 ${
          isVisible ? 'visible' : 'invisible'
        } z-50`}
        onMouseEnter={() => setSearchItemsHovered(true)}
        onMouseLeave={() => setSearchItemsHovered(false)}
      >
        {sortedSearch.length === 0 ? (
          <Link
            to='/request'
            className='block max-h-[360px] overflow-y-hidden bg-white rounded-md z-[9] md:shadow-lg'
          >
            <p className='p-3 text-gray-500 font-medium text-sm bg-white rounded-lg font-general-sans'>
              Can't find your event?
              <span className='text-brand'> Request to add the event.</span>
            </p>
          </Link>
        ) : (
          <div className=' max-h-[80vh] md:max-h-[360px] overflow-y-scroll bg-white rounded-md z-[9] md:shadow-lg'>
            {!isSearching && (
              <p className='p-3 text-[#101010] font-medium text-base bg-white rounded-lg font-general-sans text-left'>
                Trending searches🔥
              </p>
            )}
            {sortedSearch.map((event) => (
              <div
                key={event._id}
                onClick={handleEventClick.bind(this,event)}
                className='flex justify-start gap-4 p-3 rounded-md hover:bg-gray-100'
              >
                <div
                  className='max-w-[64px] max-h-[74px]'
                  style={{ height: '90%' }}
                >
                  <img
                    src={event.imageURL}
                    alt='Event Banner'
                    className='aspect-square  rounded-md w-16'
                  />
                </div>
                <div className='text-left flex-1'>
                  <p>
                    <b>{event.eventName}</b>
                  </p>
                  <p className='text-xs text-gray-500'>
                    {formatDateTime(event.eventDate, 'h:mm a')}
                  </p>
                  <p className='text-xs text-gray-500'>{event.eventCity}</p>
                </div>
                <div className='flex flex-col items-center gap-[2px] h-fit rounded-md shadow-sm border border-inherit p-[6px] w-[44px] leading-[100%]'>
                  <p className='text-accent font-semibold text-sm'>
                    {formatDateTime(event.eventDate, 'MMM')}
                  </p>
                  <p className='text-accent font-semibold text-sm'>
                    {formatDateTime(event.eventDate, 'D')}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  event: state.event,
});

export default connect(mapStateToProps, {
  fetchDropDownSearchData,
})(Search);
