import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "./Logo";
import DropDown from "./DropDown";
import UserDropDown from "./UserDropDown";
import useLoginModal from "../../../Hooks/useLoginModal";
import { HStack, Flex, Button as ChakraButton } from "@chakra-ui/react";
import MobileNavbar from "./MobileNavbar";

import { analyticEventLogger } from '../../../Analytics/eventLogger';
import { SELLER_TICKET_NAVBAR_EVENT_CLICK } from "../../../Analytics/eventLogConstants";

const NavBar = ({ auth: { userData } }) => {
  const loginModal = useLoginModal();
  const navigate = useNavigate();

  const handleSellTickets = () => {
    analyticEventLogger(SELLER_TICKET_NAVBAR_EVENT_CLICK, {
      isAuthenticated: userData?.name
    });
    if (userData?.name) {
      return navigate("/sell-tickets");
    } else {
      loginModal.onOpen();
      // User is not signed in, open the Login modal
      // Implement the logic to open the Login modal here
    }
  };

  return (
    <HStack
      w="100%"
      position="fixed"
      justifyContent="space-between"
      paddingX={{ base: '24px', md: 10, lg: '130px' }}
      zIndex={20}
      backgroundColor={'var(--White, #FFF)'}
      borderBottom={'1px solid rgba(16, 16, 16, 0.10)'}
    >
      <Flex align="center" gap={4} paddingY={2}>
        <Logo />
        <DropDown 
          width="navbar"
          marginTop={'17px'}
        />
      </Flex>
      <Flex>
        <Flex align="center" gap={4} hideBelow="md">
        <ChakraButton
            id="Create_event_PC"
            colorScheme="white"
            color="black"
            variant="solid"
            paddingX={6}
            borderRadius={50}
            _hover={{backgroundColor:"#F3F3F3"}}
            onClick={()=>{window.open('https://primary.takemytickets.co.in/', "_blank", "noreferrer")}}
          >
            Create an event
          </ChakraButton>
          {userData?.name ? (
            <UserDropDown currentUser={userData.name} />
          ) : (
            <ChakraButton 
              bg="#F0F0F0" 
              onClick={loginModal.onOpen} 
              borderRadius={50}
            >
              Login / Signup
            </ChakraButton>
          )}
          <ChakraButton
            id="Sell_Ticket_1"
            colorScheme="brand"
            variant="solid"
            paddingX={6}
            borderRadius={50}
            onClick={handleSellTickets}
          >
            Sell Tickets
          </ChakraButton>
        </Flex>
      </Flex>
      <Flex hideFrom="md">
        <MobileNavbar currentUser={userData.name} />
      </Flex>
    </HStack>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(NavBar);
