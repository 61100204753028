import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "../Layout/Layout";
import Header from "./Header";
import Ticket from "./Tickets";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { fetchListingData } from "../../redux/actions/ticket";
import { notify } from "./Toast";
import Alert from "./Toast";

import styles from "./listing.module.css";
import NoListing from "./NoListing";

import setMixPanel from "../../utils/setMixPanel";

const Listing = ({ fetchListingData, ticket: { listingTickets }}) => {
  useEffect(() => {
    fetchListingData();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [listing, setListing] = useState([
    {
      id: 1,
      artist: "Martin Garrix",
      date: "Fri, 24 Jan 2023 09:00 PM",
      venue: "The Midnight Comedy Club",
      imageUrl: "/images/image.png",
      soldOut: false,
    },
    {
      id: 2,
      artist: "John Mayer",
      date: "Sat, 25 Jan 2023 08:00 PM",
      venue: "The Grand Arena",
      imageUrl: "/images/image2.jpeg",
      soldOut: true,
    },
  ]);
  const currentDate = new Date();
  const activeListings = listingTickets.filter(
    (ticket) => !ticket.isSold && new Date(ticket.event.eventDate) >= currentDate
  );
  const pastListings = listingTickets.filter(
    (ticket) => ticket.isSold || new Date(ticket.event.eventDate) < currentDate
  );

  const handleDelete = (ticketId, index) => {
    const updatedListing = listing.filter((ticket) => ticket.id !== ticketId);
    notify("Delete Successful");
    setListing(updatedListing);
  };
  
  

  return (
    <div>
      <Layout>
        <Helmet>
          <title>My Listings</title>
          <meta
            name="title"
            content="Manage Your Event Ticket Listings in India | Take My Tickets"
          />
          <meta
            name="description"
            content="Easily manage and update your event ticket listings on Take My Tickets. Sell your tickets with confidence to the Indian audience."
          />
        </Helmet>

        <div className={`${styles.listContainer} all`}>
        <Header />
        {activeListings.length === 0  && <NoListing />}
          {activeListings.length > 0 && (
            <div className={styles.ticketCategory}>
                    
            <h2 className={styles.pastHeader} >Active listings</h2>
           
            {activeListings.map((ticket, index)=>(
              <Ticket
                key={ticket._id}
                id={ticket._id}
                eventName={ticket.event.eventName}
                date={ticket.event.eventDate}
                venue={ticket.event.eventLocation}
                soldOut={ticket.isSold}
                imageUrl={ticket.event.imageURL}
                tickets={ticket.tickets}
                ticketPrice={ticket.ticketPrice}
                maxQuantity={ticket.maxQuantity}
                maxPrice={ticket.maxPrice}
                category={ticket.ticketCategoryName}
                onDelete={handleDelete}
                isExpiry={ticket.isExpiry}
              />
            ))}
          </div>
          )}
          {pastListings.length > 0 && (
            <div className={styles.ticketCategory}>
                       
            <h2 className={styles.pastHeader}> Past Tickets</h2>
          
            {pastListings.map((ticket)=>(
              <Ticket
                key={ticket._id}
                id={ticket._id}
                eventName={ticket.event.eventName}
                date={ticket.event.eventDate}
                venue={ticket.event.eventLocation}
                soldOut={ticket.isSold}
                imageUrl={ticket.event.imageURL}
                tickets={ticket.tickets}
                ticketPrice={ticket.ticketPrice}
                maxQuantity={ticket.maxQuantity}
                maxPrice={ticket.maxPrice}
                category={ticket.ticketCategoryName}
                onDelete={handleDelete}
                isExpiry={ticket.isExpiry}
              />
            ))}
          </div>
          )}
         
        </div>
      </Layout>
      <Alert />
    </div>
  );
};

const mapStateToProps = (state) => ({
  ticket: state.ticket,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  fetchListingData,
})(Listing);
