import React from "react";
import { Link, useNavigate } from "react-router-dom";
import useLocationModal from "../../../Hooks/useLocationModal";

const Logo = () => {
  const navigate = useNavigate();

  const logoClickHandler = () => {
    const location = localStorage.getItem("selectedLocation");
    if (location === undefined || location === null) {
      navigate("/");
    } else {
      navigate("/");
      // navigate(`/events/${location}`);
    }
  };

  return (
    // <img
    //   onClick={logoClickHandler}
    //   alt="Logo"
    //   className="cursor-pointer"
    //   height="60"
    //   width="60"
    //   src="/images/logo.png"
    // />
    <Link to="/">
      <img src="/images/logo.svg" alt="India's Premier Ticket Resale Platform | Buy & Sell Event Tickets Online | Take My Tickets" height="60" width={60} className="m-2" loading='lazy' />
    </Link>
  );
};

export default Logo;
