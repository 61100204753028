import React from 'react';
import Avatar from './Avatar';
import styles from "./profile.module.css";

function Display({ imag, name }) {
  return (
    <>
      <div className={styles.picContainer}>
        {imag ? (
          <img 
		  src={imag} 
		  alt='profile-pic' 
		  className={styles.imag} 
		/>
        ) : (
          <Avatar name={name} />
        )}
      </div>
      <div className={`${styles.nameContainer} ${styles.normal}`}>
        <span className={styles.username}>{name}</span>
      </div>
    </>
  );
}

export default Display;
