import React from "react";

import styles from "./verify.module.css";

const VerifyPopup = ({
handleClick,
tryAgainClicks,
handleClose,
}) => {

  
    return (
        <div className={styles.loaderOverlay}>
          <div className={styles.popupContainer}>

            <div className={styles.popupIconContainer}>
                <img className={styles.popupMail}  src="/images/icons/mail.svg" alt='mail'/>
                <button onClick={handleClose}>
                    <img className={styles.popupClose} src="/images/icons/close.svg" alt="Close"/>
                </button>
            </div>

            <div className={styles.popupTextContainer}>
                <div className={styles.popupHeader}>
                    <h1 className={styles.popupH1}>Did you forward the ticket email to</h1>
                    <h1 className={`${styles.popupH2} text-red-400`}>tickets@takemytickets.co.in<span className='text-black'>?</span></h1>
                </div>
                <p className={styles.popupPara}>By forwarding the email, you acknowledge and consent to the verification process.</p>
            </div>

            <div className={styles.popupBtnContainer}>
                <button
                    onClick={() => handleClick(tryAgainClicks)}
                    className={styles.nextBut}
                    style={{width: '100%'}}
                >
                    I’ve forwarded my tickets!
                </button>
                <button
                    onClick={handleClose}
                    className={styles.htfBtn}
                >
                    See how to forward
                </button>
            </div>

          </div>
        </div>
    );
}

  
export default VerifyPopup;

