import React from 'react';
import ProfileCard from './ProfileCard';
import Verified from './Verified';

import styles from './profile.module.css';


const Account = ({ name, email, number, imageURL }) => {
	return (
		<div className={styles.bodyContainer}>
			<ProfileCard 
				imageURL={imageURL}
				name={name} />
			<Verified
				email={email}
				number={number}
			/>
		</div>
	);
};

export default Account;
