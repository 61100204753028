import React, { useState } from 'react';
import Avatar from './Avatar';

import styles from "./profile.module.css";

function Upload({ imag, name, fileHandler, changeHanler, setOption }) {
	
	const [selectedOption, setSelectedOption] = useState('');
	const handleUpload = (e) => {
		const fileInput = document.getElementById('files');
		fileInput.click();
	  };	
	
	const handleDropdown = (event) =>{
		const opValue = event.target.value;
		setSelectedOption(opValue);
		setOption(opValue);
		if(opValue === '2'){
			handleUpload();
		}else{
			changeHanler(event);
		}
	};

	

	
	return (
		<div className={`${styles.fileContainer} ${styles.normal}`}>
      		<div className={styles.picContainer}>
				<label htmlFor='files'>
					{imag ? (
						<img
							src={imag}
							alt='proflie-pic'
							className='imag'
						/>
					) : (
						<Avatar name={name} />
					)}
				</label>
				<input
					id='files'
					style={{
						visibility: 'hidden',
						width: 0
					}}
					type='file'
					onChange={(e) => fileHandler(e)}
				/>
			</div>

			<select
				className={styles.dropdown} 
				value={selectedOption}
				onChange={handleDropdown}
				>
				<option value='0'>Edit Photo</option>
				<option value='1'>Remove Photo</option>
				<option value='2' >Upload photo</option>
			</select>

		</div>
	);
}

export default Upload;
