import React, { useState } from 'react';
import {connect} from 'react-redux';
import Edit from './Edit';
import Display from './Display';
import Upload from './Upload';
import Alert from './Alert';
import {
	updateProfile
} from '../../redux/actions/auth';
import styles from "./profile.module.css";

const ProfileCard = ({ name, imageURL, updateProfile }) => {
	const [btnName, setBtnName] = useState('Edit');
	const [edit, setEdit] = useState(false);
	const [username, setUsername] = useState(name);
	const [imag, setImag] = useState(imageURL);
	const [option, setOption] = useState(0);
	const [prevFile, setPrevFile] = useState('');
	const [file, setFile] = useState('');

	const saveHandler = async (username) => {
		// setImag(file);
		setEdit(false);
		setBtnName('Edit');
		setUsername(username);

		let form = new FormData();
		form.append('imageOption', option);
		form.append('username', username);
		form.append('image', file);

		await updateProfile(form);
	};

  const changeHanler = (e) => {
    if (e.target.value === '1') {
      setImag('');
      setFile('');
      e.target.value = '0';
    }
  };

	const fileHandler = (e) => {
		e.preventDefault();
		const f = e.target.files[0];
		// setImag(f);
		setFile(f);
	};

  const editHandler = (e) => {
    e.preventDefault();

    setBtnName((prevState) => {
      if (prevState === 'Edit') {
        setEdit(true);
        setPrevFile(imag);
        return 'Cancel';
      }
      setEdit(false);

      return 'Edit';
    });
  };

  return (
    <div className={styles.profileCard}>
      <div className={styles.title}>
        <h2>Profile</h2>
        <button onClick={editHandler}>{btnName}</button>
      </div>
      <Alert />
      <div className={styles.displayPic}>
        {!edit && (
			<Display 
				imag={imageURL} 
				name={username} 
			/>
		)}
        {edit && (
          <Upload
            imag={imageURL}
            name={username}
            fileHandler={fileHandler}
            changeHanler={changeHanler}
			setOption={setOption}
          />
        )}
      </div>
      {edit && (
			<Edit
				name={username}
				onSave={saveHandler}
			/>
		)}
    </div>
  );
};

export default connect(null, {updateProfile})(ProfileCard);