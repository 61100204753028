import React, { useEffect , useState} from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

import SellerForm from "./SellerForm";
import SellerPlatform from "./SellerPlatform";
import { fetchIndividualEvent } from "../../redux/actions/event";
import Layout from "../Layout/Layout";
import Loader from "../Loader";

const SellTicketForm = ({
  event: { searchDropDownList,sellerFlowEvent },
  fetchIndividualEvent,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  let { slug } = useParams();
  useEffect(() => {
    const fetchDataTrigger = async (key, isSlug) => {
      setIsLoading(true);
      await fetchIndividualEvent(key, isSlug, false);
      setIsLoading(false);
    };

    const filterData = searchDropDownList.filter(
      (event) => event.eventSlug === slug
    );
    if (filterData.length === 0) {
      fetchDataTrigger(slug, true);
    } else {
      fetchDataTrigger(filterData[0]._id, false);
    }
  }, [fetchIndividualEvent, searchDropDownList, slug]);


  return isLoading? <Layout> <Loader/> </Layout> : <SellerPlatform /> ;
};

const mapStateToProps = (state) => ({
  event: state.event,
});

export default connect(mapStateToProps, {
  fetchIndividualEvent,
})(SellTicketForm);
