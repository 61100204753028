import React from "react";
import NavBar from "./Navbar/NavBar";
import Footer from "./Footer/Footer";

function Layout({ children ,className, isNavbar=true, isfooter=true }) {
  return (
    <>
      <div className="text-white flex flex-col min-h-screen">
        {isNavbar && <NavBar />}
        <div className={`flex-grow ${className}`}>{children}</div>
        {isfooter && <Footer />}
      </div>
    </>
  );
}

export default Layout;
