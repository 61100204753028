import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import Event from './Event';
import Label from './Label';
import Layout from '../Layout/Layout';
import { notify } from '../Listing/Toast';
import styles from './seller.module.css';
import {
  sellTicketDataPost,
  addPrePostTicketData,
  removePrePostTicketData,
} from '../../redux/actions/ticket';
import axios from '../../axios-api'

import ProgressBar from '../ProgressBar/ProgressBar';

const SellerForm = ({
  event: { sellerFlowEvent },
  auth: { userData },
  sellTicketDataPost,
  addPrePostTicketData,
  removePrePostTicketData,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Headerr = ({ head, showParty }) => {
    const party = showParty ? '\u{1F973}' : '';

    return (
      <div className={styles.headdContainer}>
        <h1 className={styles.headerr}>
          {head}
        </h1>
      </div>
    );
  };

  const navigate = useNavigate();
  // const [numberOfTickets, setNumberOfTickets] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  // const [numberOfTicketsError, setNumberOfTicketsError] = useState('');
  const [selectedCategoryError, setSelectedCategoryError] = useState('');
  const head = 'Sell your tickets quickly';

  // const disabled = numberOfTickets && price && selectedCategory;

  const handleCategoryChange = (e) => {
    const categoryData = sellerFlowEvent?.category?.filter(
      (cat) => cat._id === e.target.value
    )[0];
    setSelectedCategory(categoryData);
    setSelectedCategoryError('');
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    let hasError = false;

    // if (numberOfTickets === '') {
    //   hasError = true;
    //   setNumberOfTicketsError('This field is required');
    // } else {
    //   setNumberOfTicketsError('');
    // }

    if (selectedCategory === '') {
      hasError = true;
      setSelectedCategoryError('This field is required');
    } else {
      setSelectedCategoryError('');
    }

    let data = {
      eventID: sellerFlowEvent._id,
      categoryID: selectedCategory.categoryName,
      categoryPrice: selectedCategory.categoryPrice,
    };

    // if (numberOfTickets === '') {
    //   notify('Oops!! some error has occured please try again by refreshing!!');
    // }

    if (true) {
      await addPrePostTicketData(data);
      // if (
      //   userData.mobile &&
      //   userData.mobile != null &&
      //   userData.email &&
      //   userData.email != null
      // ) {
      navigate('/price-details');
      // }
    }
  };

  return (
    <Layout className={styles.priceTicketFormLayout} isfooter={window.innerWidth < 600?false:true} >
      <Helmet>
        <title>Sell tickets</title>
        <meta
          name='Sell Tickets Easily in India | Reach Your Audience on Take My Tickets'
          content="Start selling your event tickets hassle-free on Take My Tickets, India's trusted ticket resale platform. Add seller contact details effortlessly."
        />
        <meta charset='UTF-8' />
      </Helmet>
      <div className={styles.second } style={{height:"640px"}}>
        <div className={styles.formContainer}>
        <Headerr head={head} showParty={true} className='ml-32 ' />
          <Label question='Which event do you want to sell tickets for?' />
          <div className={styles.eventContainer}>
            {sellerFlowEvent && (
              <Event
                id={sellerFlowEvent._id}
                eventName={sellerFlowEvent.eventName}
                date={sellerFlowEvent.eventDate}
              />
            )}
          </div>

          <form onSubmit={handleFormSubmit}>
            <div className={`${styles.sell} flex-col`}>
              {/* <div className={styles.inputContainer}>
                <Label className={styles.label} question='How many tickets?' />

                <select
                  className={`${styles.numberInput} ${styles.frame} ${
                    styles.inputContainer
                  } ${numberOfTicketsError ? styles.error : ''} ${
                    styles.selectCont
                  }`}
                  value={numberOfTickets}
                  onChange={(e) => {
                    setNumberOfTickets(e.target.value);
                    setNumberOfTicketsError('');
                  }}
                  required
                >
                  <option value='' disabled hidden style={{ color: '#A1A1A1' }}>
                    Count
                  </option>
                  {Array.from({ length: 10 }, (_, index) => (
                    <option key={index + 1} value={index + 1}>
                      {index + 1} {index + 1 > 1 ? 'tickets' : 'ticket'}
                    </option>
                  ))}
                </select>

                {numberOfTicketsError && (
                  <div className={styles.errorMessage}>
                    <img
                      src='/images/icons/warn.svg'
                      alt='Error'
                      className={styles.errorI}
                      loading='lazy'
                    />
                    {numberOfTicketsError}
                  </div>
                )}
              </div> */}
              <div className={styles.inputContainer}>
                <Label
                  className={styles.label}
                  question='What is the ticket type?'
                />

                <select
                  className={`${styles.categoryInput} ${styles.frame} ${
                    styles.inputContainer
                  } ${selectedCategoryError ? styles.error : ''}`}
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  required
                >
                  <option value='' disabled hidden style={{ color: '#A1A1A1' }}>
                    Type
                  </option>
                  {sellerFlowEvent.category &&
                    sellerFlowEvent.category.map((category) => (
                      <option key={category._id} value={category._id}>
                        {category.categoryName}
                      </option>
                    ))}
                </select>
                {selectedCategoryError && (
                  <div className={styles.errorMessage}>
                    <img
                      src='/images/icons/warn.svg'
                      alt='Error'
                      className={styles.errorI}
                      loading='lazy'
                    />
                    {selectedCategoryError}
                  </div>
                )}
              </div>
            </div>
          <div className={styles.buttonContainer1} >
            <ProgressBar progress={50} bottom={94} />

            <div className={`${styles.proceedButton} justify-center align-center h-full mt-4 flex`}>
              <input
                type='submit'
                className={styles.proceed}
                value='Proceed'
              />
            </div>
          </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};
//    position: absolute;
// width: 430px;
// bottom: 36px;
const mapStateToProps = (state) => ({
  event: state.event,
  auth: state.auth,
  ticket: state.ticket,
});

export default connect(mapStateToProps, {
  sellTicketDataPost,
  addPrePostTicketData,
  removePrePostTicketData,
})(SellerForm);
