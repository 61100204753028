import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

import TicketImage from './TicketImage';
import TicketInfo from './TicketInfo';
import TicketList from './TicketList';
import EditForm from './EditForm';
import Button from './Button';
import PopupScreen from './PopupScreen';
import styles from './listing.module.css';
import { notify } from './Toast';
import Alert from './Toast';
import {
  updateMarkAsSold,
  updateTicketData,
  deleteTicketData,
  fetchMyListingIndividualLisitingData
} from '../../redux/actions/ticket';
import { analyticEventLogger } from "../../Analytics/eventLogger";
import { 
  TICKET_DELETE_EVENT_CLICK,
  TICKET_UPDATE_EVENT_CLICK,
  TICKET_MARK_AS_SOLD_EVENT_CLICK
} from "../../Analytics/eventLogConstants";

import setMixPanel from '../../utils/setMixPanel';

const Ticket = ({
  id,
  eventName,
  date,
  venue,
  soldOut,
  isExpiry,
  imageUrl,
  onDelete,
  tickets,
  ticketPrice,
  maxPrice,
  maxQuantity,
  category,
  ticket: { listingTickets },
  updateMarkAsSold,
  updateTicketData,
  deleteTicketData,
  fetchMyListingIndividualLisitingData,
}) => {
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isSoldOut, setIsSoldOut] = useState(soldOut);
  const [isPopup, setPopup] = useState(false);

  const handleEditClick = async () => {
    let eventID, categoryID;
    for(let i=0;i<listingTickets.length;i++){
      if(listingTickets[i]._id == id){
        eventID = listingTickets[i].event._id;
        categoryID = listingTickets[i].ticketCategoryId; 
      }
    }

    if(eventID != null && categoryID != null){
      await fetchMyListingIndividualLisitingData(eventID, categoryID);
    }

    setIsEditFormOpen(true);
  };
  const handleMarkSoldClick = () => {
    setPopup(true);
  };
  const handleFormClose = () => {
    setIsEditFormOpen(false);
  };
  const handleSave = (formData) => {
    updateTicketData(
      listingTickets,
      id,
      formData.price,
      formData.numberOfTickets
    );

    setIsEditFormOpen(false);
    analyticEventLogger(TICKET_UPDATE_EVENT_CLICK, {});
    notify('Update Successful');
  };

  const handleFormDelete = () => {
    deleteTicketData(listingTickets, id);
    analyticEventLogger(TICKET_DELETE_EVENT_CLICK, {});

    notify("Delete Successful");
  };

  const handleSoldOutPopupConfirm = () => {
    updateMarkAsSold(listingTickets, id);
    analyticEventLogger(TICKET_MARK_AS_SOLD_EVENT_CLICK, {});
    
    setIsSoldOut(true);
    setPopup(false);
  };

  const handleSoldOutPopupClose = () => {
    setPopup(false);
  };

  const [ isExpired, setIsExpired ] = useState(false);
  useEffect(()=>{
    const eventDate = new Date (date);
    const currentDate = new Date(date);
  })
  
  useEffect(() => {
       if (isSoldOut) {
      setIsExpired(true);
    }
  }, [isSoldOut]);

  return (
    <>
      <div className={styles.ticketContainer} data-id={id}>
        <div className={styles.show}>
          <TicketImage imageUrl={imageUrl} altText='ticket image' />
          <TicketInfo artist={eventName} date={date} venue={venue} />
        </div>
        {isEditFormOpen ? (
          <EditForm
            initialNumberOfTickets={tickets}
            initialPrice={ticketPrice}
            maxPrice={maxPrice}
            maxQuantity={maxQuantity}
            onSave={handleSave}
            onDelete={handleFormDelete}
            category={category}
            onClose={handleFormClose}
          />
        ) : (
          <>
            <TicketList
              ticketCount={tickets}
              category={category}
              ticketCost={ticketPrice}
            />
           {isSoldOut || isExpiry? (
              <div className={styles['sold-out']}>Sold Out ✌️</div>
            ) : (
              <div className={`${styles.buttons} ${styles.main}`}>
                <Button
                  className={`${styles['primary-button']} ${styles.edit}`}
                  text="Edit Listing"
                  onClick={handleEditClick}
                />
                <Button
                  className={`${styles['sec-button']} ${styles.mark}`}
                  text="Mark as Sold"
                  onClick={handleMarkSoldClick}
                />
                <Alert />
              </div>
            )}
          </>
        )}
      </div>
      {isPopup && (
        <PopupScreen
          onClose={handleSoldOutPopupClose}
          onConfirm={handleSoldOutPopupConfirm}
          title='Mark as Sold'
          message='Are you sure you want to mark this post as sold? This action cannot be undone.'
          confirmButtonText='Mark as Sold'
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  ticket: state.ticket,
});

export default connect(mapStateToProps, {
  updateMarkAsSold,
  updateTicketData,
  deleteTicketData,
  fetchMyListingIndividualLisitingData
})(Ticket);
