import React from 'react';

import {
  formatDateTime
} from '../../utils/datetime';
import styles from "./listing.module.css";

const TicketInfo = ({ id, artist, date, venue, location }) => {
  return (
    <div className={styles.ticketInfo} data-id={id}>
      <h3 className={styles.artist}>{artist}{location}</h3>
      <p className={styles.venue}>{formatDateTime(date, 'ddd, DD MMM YYYY, hh:mm a')}</p>
      <p className={styles.venuee} >{venue}</p>
    </div>
  );
};

export default TicketInfo;
