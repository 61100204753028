import {
    FETCH_SEARCH_DROPDOWN,
    FETCH_TRENDING_ALL_EVENTS,
    FETCH_BUYER_FLOW_EVENT,
    FETCH_SELLER_FLOW_EVENT,
    SUBSCRIBE_EVENT,
    UNSUBSCRIBE_EVENT
} from './types';

import axios from '../../axios-api';

export const fetchTrendingAllEvents = (trending = false) => async dispatch => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    
        let location = localStorage.getItem("selectedLocation");
        location = location == null ? "" : location;

        const res = await axios.get(`/v1/event/allTrendingEvents?location=${location}&trending=${trending}`, config);

        dispatch({
            type: FETCH_TRENDING_ALL_EVENTS,
            payload: res.data.data
        });

    } catch (err) {
        console.log(err);
    }
}

export const fetchDropDownSearchData = (searchText) => async dispatch => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    
        let location = localStorage.getItem("selectedLocation");
        location = location == null ? "" : location;

        const res = await axios.get(`/v1/event/eventDropDownList?searchText=${searchText}&location=${location}`, config);

        dispatch({
            type: FETCH_SEARCH_DROPDOWN,
            payload: res.data.data
        });
        
    } catch (err) {
        console.log(err);
    }
}

export const submitRequestEvent = (data) => async dispatch => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const res = await axios.post('/v1/event/request', data, config);

        return res.status;
    } catch (err) {
        console.log(err);
    }
}

export const fetchIndividualEvent = (key, isSlug, isBuyerFlow) => async dispatch => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    
        if(isBuyerFlow){
            const res = await axios.get(`/v1/event/buyerFlow?key=${key}&mode=${isSlug}`, config);
            if(res.status == 404){
                return true;
            }
            dispatch({
                type: FETCH_BUYER_FLOW_EVENT,
                payload: res.data.data
            });
        } else {
            const res = await axios.get(`/v1/event/sellerFlow?key=${key}&mode=${isSlug}`, config);

            dispatch({
                type: FETCH_SELLER_FLOW_EVENT,
                payload: res.data.data
            });
        }

    } catch (err) {
        console.log(err);
        return true;
    }
}

export const subscribeEvent = (eventID) => async dispatch => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const resData = await axios.post(`/v1/alert/subscribe/event/${eventID}`, config);

        dispatch({
            type: SUBSCRIBE_EVENT
        });

    } catch (err) {
        console.log(err);
    }
}

export const unSubscribeEvent = (eventID) => async dispatch => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const resData = await axios.post(`/v1/alert/unsubscribe/event/${eventID}`, config);

        dispatch({
            type: UNSUBSCRIBE_EVENT
        });

    } catch (err) {
        console.log(err);
    }
}