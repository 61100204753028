import {combineReducers} from 'redux';
import venue from './venue';
import event from './event';
import auth from './auth';
import ticket from './ticket';

export default combineReducers({
    venue,
    event,
    auth,
    ticket
});