import React, { useState, useRef, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { ReactComponent as Location_icon } from '../../Icons/location_icon.svg';
import useLocationModal from '../../Hooks/useLocationModal';
import Modal from './LocModal';
import DropDown from '../Layout/Navbar/DropDown';

const LocationModal = ({ venue: { cityList } }) => {
  const locationModal = useLocationModal();
  const [isLoading, setIsLoading] = useState(false);

  const [selectedLocation, setselectedLocation] = useState('');

  const lc_location = localStorage.getItem("selectedLocation");

  const handleLocationChange = useCallback((location) =>{
    setselectedLocation(location);
  }, []);
  
    useEffect(() =>{
      if(selectedLocation){
        setIsLoading(true);
        const timeout = setTimeout(() =>{
          setIsLoading(false);
          locationModal.onClose();
          window.location.replace(`/`);
        },1500);
        return () => clearTimeout(timeout);
      }
    },[selectedLocation, locationModal]);

    useEffect(() => {
      if(!lc_location) {
        locationModal.onOpen()
      }
    },[])

  const bodyContent = (
    <div className='flex flex-col gap-8 z-10 h-fit'>
     <DropDown 
      location
      selectedLocation={selectedLocation}
      handleLocationChange = {handleLocationChange}
      cities={cityList.map((ele) =>({
      label: ele.cityName,
      value: ele.cityName
      }))}
      isOpen={false}  
      marginTop={'-285px'}    
     />
    </div>
  );

  const titleContent = (
    <div>
      <div>
      <Location_icon/>
      </div>
      <div style={{margin:"20px 0px"}} >
        Find Events near you
      </div>
    </div>

  )

  return (
    <Modal
      disabled={isLoading}
      isOpen={locationModal.isOpen}
      title={titleContent}
      onClose={locationModal.onClose}
      body={bodyContent}
      dropdownContentHeight={!selectedLocation ? '96' : '0'}
    />
  );
};

const mapStateToProps = (state) => ({
  venue: state.venue,
});

export default connect(mapStateToProps, {})(LocationModal);
