import React, { useState, useEffect, useRef } from 'react';
import { formatDateTime } from '../../utils/datetime';
import { BsSearch } from 'react-icons/bs';
import { connect } from 'react-redux';
import {
  fetchDropDownSearchData,
  fetchIndividualEvent,
} from '../../redux/actions/event';
import { Link, useNavigate } from 'react-router-dom';

const Search = ({
  event: { searchDropDownList },
  fetchDropDownSearchData,
  fetchIndividualEvent,
  onEventSelect,
  trigger,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchDropDownSearchData('');
  }, []);

  const searchBarTextHandler = (data) => {
    setSearchTerm(data);
    fetchDropDownSearchData(data);
  };

  const [isVisible, setVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isFocused, setFocused] = useState(false);
  const searchRoot = useRef(null);

  const handleInputFocus = () => {
    setVisible(true);
    setFocused(true);
  };

  const handleInputBlur = () => {
    setVisible(false);
    setSearchTerm('');
    setFocused(false);
  };

  document.addEventListener('click', (e) => {
    if (searchRoot && searchRoot.current) {
      if (!searchRoot.current.contains(e.target)) {
        handleInputBlur();
      }
    }
  });

  const navigate = useNavigate();
  const handleEventClick = async (eventId) => {
    const selectedEvent = searchDropDownList.find(
      (event) => event._id === eventId
    );
    if (selectedEvent) {
      onEventSelect(selectedEvent);
      navigate(`/seller-form/${selectedEvent.eventSlug}`);
    }
  };

  const sortedSearch = searchTerm
    ? searchDropDownList
    : searchDropDownList.slice().sort((a, b) => {
        const dateA = new Date(a.eventDate);
        const dateB = new Date(b.eventDate);
        return dateA - dateB;
      });

  return (
    <div className='w-100 mx-auto relative mt-[12px]' ref={searchRoot}>
      <div className='relative flex flex-col text-sm gap-3 text-black'>
        <div
          className={`relative border ${
            isFocused
              ? 'border-red-500 shadow-red-500'
              : 'border-slate-300 shadow-slate-50'
          } rounded-[10px] `}
        >
          <img
            src='images/icons/search.svg'
            className='absolute left-5 top-3 w-6 h-6 object-cover '
            alt='search-events'
          />
          <input
            type='text'
            placeholder='Search by event'
            className='p-3 py-4 pl-16 rounded-xl w-full max-w-xl text-sm sm:text-sm  text-[#717171] mx-auto'
            onFocus={handleInputFocus}
            onInput={(e) => searchBarTextHandler(e.target.value)}
            value={searchTerm}
          />
        </div>
        {isVisible && (
          <div className='absolute left-0 right-0 top-14 bg-white rounded-md z-10 overflow-y-scroll h-[80vh] md:h-[450px]'>
          {sortedSearch.length === 0? (
            <Link
            to='/request'
            className='block max-h-[360px] overflow-y-hidden bg-white rounded-md z-[9] md:shadow-lg'
          >
            <p className='p-3 text-gray-500 font-medium text-sm bg-white rounded-lg font-general-sans'>
              Can't find your event?
              <span className='text-brand'> Request to add the event.</span>
            </p>
          </Link>
          ) : (
            <div className=' max-h-[80vh] md:max-h-[360px] overflow-y-scroll bg-white rounded-md z-[9] md:shadow-lg'>
            {!searchTerm && (
              <p className='p-3 text-[#101010] font-medium text-base bg-white rounded-lg font-general-sans text-left'>
                Trending searches🔥
              </p>
            )}
            {sortedSearch.map((event) => (
              <div
                className='flex justify-start gap-4 py-3 md:p-3 rounded-md hover:bg-gray-100'
                key={event._id}
                onClick={() => handleEventClick(event._id)}
              >
                <div className='' style={{ height: '90%' }}>
                  <img
                    src={event.imageURL}
                    alt='Event Banner'
                    className='aspect-square  rounded-md w-16'
                  />
                </div>
                <div className='text-left flex-1'>
                  <p>
                    <b>{event.eventName}</b>
                  </p>
                  <p className='text-xs text-gray-500'>
                    {formatDateTime(event.eventDate, 'h:mm a')}
                  </p>
                  <p className='text-xs text-gray-500'>{event.eventCity}</p>
                </div>
                <div className='flex flex-col items-center gap-[2px] h-fit rounded-md shadow-sm border border-inherit p-[6px] w-[44px] leading-[100%]'>
                  <p className='text-accent font-semibold text-sm'>
                    {formatDateTime(event.eventDate, 'MMM')}
                  </p>
                  <p className='text-accent font-semibold text-sm'>
                    {formatDateTime(event.eventDate, 'D')}
                  </p>
                </div>
              </div>
            ))}
            </div>
          )}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  event: state.event,
});

export default connect(mapStateToProps, {
  fetchDropDownSearchData,
  fetchIndividualEvent,
})(Search);
