import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';

const PrivateRoutes = ({auth: {isAuthenticated}, children}) => {
  if(!isAuthenticated){
    return <Navigate to="/" />
  }

  return children;
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {})(PrivateRoutes);
