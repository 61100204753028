import { useState } from "react";
import { useDispatch } from 'react-redux';
import { 
    registerClientJWT ,
    loadUser
} from '../redux/actions/auth';

// Pass URL
const useFetch = (url) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const handleGoogle = async (response) => {
    setLoading(true);
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({ credential: response.credential }),
    })
      .then((res) => {
        setLoading(false);
        return res.json();
      })
      .then((data) => {
        if (data?.token) {
            dispatch(registerClientJWT(data.token));
            dispatch(loadUser());
        //   window.location.reload();
        }

        throw new Error(data?.msg || data);
      })
      .catch((error) => {
        setError(error?.msg);
      });
  };
  return { loading, error, handleGoogle };
};

export default useFetch;