import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Alert, { notify } from "../Components/Home/Alert";

import Buyer from "../Components/Buyer/Buyer";
import Layout from "../Components/Layout/Layout";
import AvatarCard from "../Components/Buyer/AvatarCard";
import TicketsAlert from "../Components/Buyer/TicketsAlert";
import { Button, Flex, VStack } from "@chakra-ui/react";
import EventHeaders from "../Components/Buyer/EventHeaders";
import EnabledTicket from "../Components/TicketCard/EnabledTicket";
import DisabledTicket from "../Components/TicketCard/DisabledTicket";
import TicketSell from "../Components/Buyer/TicketSell";
import useLoginModal from "../Hooks/useLoginModal";
import Loader from "../Components/Loader";
import axios from "../axios-api";
import setMixPanel from "../utils/setMixPanel";

import {
  fetchIndividualEvent,
  subscribeEvent,
  unSubscribeEvent,
} from "../redux/actions/event";
import { analyticEventLogger } from "../Analytics/eventLogger";
import {
  EVENT_NOTIFIER_SUBSCRIBE_EVENT_CLICK,
  EVENT_NOTIFIER_UNSUBSCRIBE_EVENT_CLICK,
} from "../Analytics/eventLogConstants";
import { toast } from "react-hot-toast";
import styles from "./event.module.css";

const Event = ({
  auth: { isAuthenticated, userData },
  event: { searchDropDownList, buyerFlowEvent },
  fetchIndividualEvent,
  subscribeEvent,
  unSubscribeEvent,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [toggled, setToggled] = useState(buyerFlowEvent.alertToggle);
  const [triggerUseEffect, setTriggerUseEffect] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const { slug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDataTrigger = async (key, isSlug) => {
      let navigate404 = false;
      setIsLoading(true);
      navigate404 = await fetchIndividualEvent(key, isSlug, true);
      setIsLoading(false);
      if (navigate404) {
        return navigate("/404");
      }
    };

    const filterData = searchDropDownList.filter(
      (event) => event.eventSlug === slug
    );
    if (filterData.length === 0) {
      fetchDataTrigger(slug, true);
    } else {
      fetchDataTrigger(filterData[0]._id, false);
    }
  }, [fetchIndividualEvent, searchDropDownList, slug, triggerUseEffect, navigate]);

  const [isBuyTicketsVisible, setBuyTicketsVisible] = useState(false);
  const [activeTicketGroup, setActiveTicketGroup] = useState(null);
  const handleBuyTicket = (category) => {

    if (isAuthenticated){
    }
    else{
      console.log("UNAUTH");
    }

    setActiveTicketGroup(category);
    setBuyTicketsVisible(true);
  };

  const prevPageNavigator = () => {
    setBuyTicketsVisible(false);
  };

  const loginModal = useLoginModal();
  const handleSellTickets = () => {
    if (!isAuthenticated) {
      loginModal.onOpen();
    } else {
      navigate(`/seller-form/${slug}`);
    }
  };

  const switchHandler = async (e) => {
    if (isAuthenticated) {
      if (e.target?.checked) {
        analyticEventLogger(EVENT_NOTIFIER_SUBSCRIBE_EVENT_CLICK, {
          isAuthenticated: isAuthenticated,
        });
        await subscribeEvent(buyerFlowEvent._id);
        notify("Alert's turned on");

      } else {
        analyticEventLogger(EVENT_NOTIFIER_UNSUBSCRIBE_EVENT_CLICK, {
          isAuthenticated: isAuthenticated,
        });
        await unSubscribeEvent(buyerFlowEvent._id);
        notify("Alert's turned off");
        
      }
      setTriggerUseEffect((prevState) => !prevState);
    } else {
      analyticEventLogger(EVENT_NOTIFIER_SUBSCRIBE_EVENT_CLICK, {
        isAuthenticated: isAuthenticated,
      });
      loginModal.onOpen();
      setToggled(false);
    }
  };

  return (
    <Layout 
      isNavbar={window.innerWidth < 600 && isBuyTicketsVisible ?false:true}
      isfooter={window.innerWidth < 600 && isBuyTicketsVisible ?false:true}>

      {isLoading ? (
        <Loader/>
      ):
        <>
          <Helmet>
            <title>{buyerFlowEvent.eventName}</title>
            <meta
              name="description"
              content={`Buy and sell tickets for the event ${buyerFlowEvent.eventName}`}
            />
          </Helmet>
          <Alert />
          <div className="relative mt-0 sm:mt-20">
            {!isBuyTicketsVisible ? (
              <>
                <Flex
                  direction="column"
                  pt={14}
                  align="center"
                  width="100%"
                  justifyContent="center"
                >
                  <Flex
                    width={{ base: "100%", md: "40%" }}
                    px={{ base: "6", md: "2" }}
                    paddingTop={{ base: "12"}}
                    direction="column"
                    gap={8}
                  >
                    <EventHeaders
                      image={buyerFlowEvent.eventImage}
                      title={buyerFlowEvent.eventName}
                      location={buyerFlowEvent.eventLocation}
                      dateTime={buyerFlowEvent.eventTime}
                      locationURL={buyerFlowEvent.eventLocationURL}
                    />
                    <TicketsAlert
                      toggled={buyerFlowEvent.alertToggle}
                      switchHandler={switchHandler}
                    />
                    <VStack gap={3} alignItems="flex-start" mb={14}>
                      
                      {buyerFlowEvent?.category?.length === 0 ? (
                        <>
                          <div className={styles.ticketsTitle} >Tickets</div>
                          <div className={styles.noTicketAvailable}>
                            No tickets available!
                          </div>
                          <div className={styles.lookingToSellContainer} >
                            <div className={styles.lookingToSellCTA} >
                              <div className={styles.lookingToSellDiv} >
                                <div className={styles.lookingToSellText}>Looking to sell your tickets?</div>
                                <div className={styles.lookingToSellDesc} >List your tickets now and sell them fast.</div>
                              </div>
                              <div>
                                  <img
                                    className="w-12 h-12 mb-3"
                                    src={"/images/icons/thinkingFace.png"}
                                    alt="error"
                                  />
                              </div>
                            </div>
                            <div>
                              <Button
                                size="sm"
                                variant="solid"
                                colorScheme="brand"
                                onClick={handleSellTickets}
                              >
                                Sell your tickets
                              </Button>
                            </div>
                          </div>
                        </>
                      ) : 
                      <>
                      <TicketSell />
                      {(buyerFlowEvent.category ?? []).map((category, index) =>
                        category.availableTicket ? (
                          <EnabledTicket
                            handleBuyTicket={handleBuyTicket}
                            key={index}
                            category={category}
                            title={category.categoryName}
                            count={category.availableTicket}
                            startingPrice={category.startingPrice}
                          />
                        ) : (
                          <DisabledTicket
                            key={index}
                            title={category.categoryName}
                          />
                        )
                      )}
                      </>
                      }
                    </VStack>
                  </Flex>
                  <div
                    className="absolute top-0 left-0 -z-30 right-0 h-[270px] object-cover"
                    style={{
                      backgroundImage: `url("${buyerFlowEvent.eventImage}")`,
                      filter: "blur(8px)",
                    }}
                  />
                </Flex>
                <div
                  className="absolute top-0 left-0 -z-30 right-0 h-[22vh] object-cover"
                  style={{
                    backgroundImage: `url("${buyerFlowEvent.eventImage}")`,
                    filter: "blur(8px)",
                  }}
                />
              </>
            ) : (
              <Buyer
                event={buyerFlowEvent}
                activeTicketGroup={activeTicketGroup}
                handleBuyTicket={handleBuyTicket}
                prevPageNavigator={prevPageNavigator}
                isVerification={buyerFlowEvent.isVerificationEvent}
              />
            )}
          </div>
        </>
      }

    </Layout>
  );
};

const mapStateToProps = (state) => ({
  event: state.event,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  fetchIndividualEvent,
  subscribeEvent,
  unSubscribeEvent,
})(Event);
