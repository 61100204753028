import React from 'react';
import styles from "./home.module.css";

const TestimonialCard = ({srcUrl, username, userId, tweet}) => {

    const words = tweet.split(/\s+/);

    return (
        <div className={styles.testimonialCardContainer}>
            <div className={styles.testimonialHeader}>

                <img src={srcUrl} alt='dp' className={styles.Tdp}/>
                <div className={styles.TuserDetails}>
                    <h4 className={styles.Tusername}>{username}</h4>
                    <p className={styles.TuserId}>{userId}</p>
                </div>
            </div>
            
            <p className={styles.tweet}>
                {words.map((word, index) => {
                  if (word.startsWith('@') || word.includes("co.in")) {
                    return (
                      <span key={index} style={{color:"#1D9BF0"}}>
                        {word+" "}
                      </span>
                    );
                  }
                  else if(word === 'br'){
                    return <span key={index}>
                        <br/>
                        <br/>
                    </span>
                  } else {
                    return <span key={index}>{word + " "}</span>;
                  }
                })}
            </p>
        </div>
    );
}

export default TestimonialCard;