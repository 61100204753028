import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import Layout from '../Layout/Layout';
import styles from './seller.module.css';

const ListingLanding = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate('/my-listing');
  };

  return (
    <>
      <Layout>
        <Helmet>
          <title>Sell tickets</title>
          <meta
            name="Sell Tickets Easily in India | Reach Your Audience on Take My Tickets"
            content="Start selling your event tickets hassle-free on Take My Tickets, India's trusted ticket resale platform. Add seller contact details effortlessly."
          />
          <meta charset="UTF-8" />
        </Helmet>
        <div className={styles.listingContainer}>
          <div className={styles.gifContainer}>
            <div className={styles.gifWrapper}>
              <img
                src="/images/gif/done.gif"
                className={styles.gifEmbed}
                title="Listing GIF"
                allowFullScreen
              ></img>
            </div>
          </div>
          <h1 className={styles.headline}>Ta da! Your listing is created</h1>
          <p className={styles.message}>
            We'll keep you in the loop as soon as we find the perfect buyer for your tickets. Stay tuned!
          </p>
          <button className={styles.primaryButton} onClick={handleButtonClick}>
            Take me to My Listings
          </button>
        </div>
      </Layout>
    </>
  );
};

export default ListingLanding;
