import React, { useState, useEffect } from 'react';
import { Collapse, Select } from 'antd';
import { Button, HStack } from '@chakra-ui/react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useDisclosure } from '@chakra-ui/react';
import setMixPanel from '../../utils/setMixPanel';

import PopupScreen from './PopupScreen';
import TicketsAlert from './TicketsAlert';
import ModalLogin from '../modals/ModalLogin';
import useLoginModal from '../../Hooks/useLoginModal';
import Avatar from '../Profile/Avatar';
import AvatarCard from './AvatarCard';
import { formatDateTime } from '../../utils/datetime';
import {
  fetchTicketData,
  fetchUnAuthTicketData,
} from '../../redux/actions/ticket';
import { getInTouchClickAnalytics } from '../../redux/actions/analytics';
import { analyticEventLogger } from '../../Analytics/eventLogger';
import { GET_IN_TOUCH_EVENT_LOG } from '../../Analytics/eventLogConstants';
import axios from "../../axios-api";

const { Option } = Select;

const Buyer = ({
  event,
  activeTicketGroup,
  handleBuyTicket,
  prevPageNavigator,
  fetchTicketData,
  fetchUnAuthTicketData,
  getInTouchClickAnalytics,
  isVerification,

  ticket: { eventTicket },
  auth: { isAuthenticated, userData },
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const loginModal = useLoginModal();
  const [sortingOption, setSortingOption] = useState('recent');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [termsChecked, setTermsCheck] = useState(false);
  const [selectedSeller, setSelectedSeller] = useState(null);
  const [isGetInTouchClicked, setIsGetInTouchClicked] = useState(false);
  const [IsPopupOpen, setIsPopupOpen] = useState(false);
  const [IsPopupOpened, setIsPopupOpened] = useState(false);
  const BuyerPopUp = "buyerPopUp";
  const BuyerPopUpUserID = "BuyerPopUpUserID";

  useEffect(() => {
    if (isAuthenticated) {
      

      fetchTicketData(event._id, activeTicketGroup._id, sortingOption);
    } else {
      console.log("UNAUTH");
      fetchUnAuthTicketData(event._id, activeTicketGroup._id, sortingOption);
    }
  }, [
    event,
    activeTicketGroup,
    fetchTicketData,
    isAuthenticated,
    fetchUnAuthTicketData,
    sortingOption,
  ]);

  const [activeSeller, setActiveSeller] = useState(null);

  const handleSellerClick = (seller) => {
    console.log("TICK CLICKED")
    setActiveSeller(seller);
  };
  const handlePopupClose = () => {
    setIsGetInTouchClicked(false);
    localStorage.removeItem(BuyerPopUpUserID);
  };
  const handleSortChange = (value) => {
    setSortingOption(value);
  };

  const handleGetInTouch = (
    phone,
    sellerName,
    seller,
    eventName,
    categoryName,
    userID,
    index
  ) => {
    if (!isAuthenticated) {
      localStorage.setItem(BuyerPopUp, true);
      localStorage.setItem(BuyerPopUpUserID, userID);
      loginModal.onOpen();
    } else {      
      if (!localStorage.getItem(BuyerPopUp)) {
      // if (false) {
        setIsGetInTouchClicked(true);
        setIsPopupOpened(true);
        localStorage.setItem(BuyerPopUp, true);
        localStorage.setItem("BuyerPopUpUserID", userID);
      } else {
        setIsGetInTouchClicked(false);
        const userAgent = window.navigator.userAgent.toLowerCase();
        const mobileAgents = /iphone|ipad|android/;

        let isMobile = mobileAgents.test(userAgent);

        analyticEventLogger(GET_IN_TOUCH_EVENT_LOG, {
          button_name: 'Get_In_Touch',
          isMobile: isMobile,
          environment: process.env.NODE_ENV,
        });
        getInTouchClickAnalytics(eventTicket[index]._id)

        let whatsappurl = '';
        if (isMobile) {
          whatsappurl = `https://wa.me/91${phone}?text=Hi%20${sellerName}%2C%0AI%20found%20your%20listing%20for%20*${categoryName}%20tickets%20to%20${eventName}*%20on%20Take%20My%20Tickets%2C%20and%20I’m%20interested%20in%20buying%20it.%0A%0ALooking%20forward%20to%20your%20response.%20Thanks!%20✌%EF%B8%8F`;
        } else {
          whatsappurl = `https://web.whatsapp.com/send?phone=${phone}&text=Hi%20${sellerName}%2C%0AI%20found%20your%20listing%20for%20*${categoryName}%20tickets%20to%20${eventName}*%20on%20Take%20My%20Tickets%2C%20and%20I’m%20interested%20in%20buying%20it.%0A%0ALooking%20forward%20to%20your%20response.%20Thanks!%20✌%EF%B8%8F`;
        }
        window.open(whatsappurl, '_blank');
        setTimeout(() => {
          onClose();
        }, 1000);
      }
    }
  }

  if(String(localStorage.getItem(BuyerPopUp)) == 'true' && isAuthenticated){
    setIsGetInTouchClicked(true);
    setIsPopupOpened(true);
    localStorage.setItem(BuyerPopUp, false);    
  }

  return (
    <div
      className='relative max-w-2xl mx-auto p-8 pt-12 z-[1]'
      style={{ padding: '24px' }}
    >
      <button
        className='flex gap-4 items-center text-accent mb-6 mt-4'
        onClick={() => {
          prevPageNavigator();
          setActiveSeller(null);
        }}
      >
        <ArrowLeftOutlined className='text-[#717171]' />
        <p className={
            window.innerWidth > 600 ?
              "lh-1 text-accent text-base font-bold": 
              "text-black text-sm text-start font-semibold"
            }
        >
          {event.eventName}<br />

          {
            window.innerWidth < 600 &&
              <span className='text-start text-[#717171] font-normal leading-[21px]'>
                {formatDateTime(event.eventTime,'ddd, MMM D [at] h:mm a')}
              </span>
          }
        </p>
      </button>
      <div
        className='flex flex-col justify-between items-start mb-8 lg:flex-row pt-8'
        style={{ gap: '16px' }}
      >
        <p className='font-bold lh-1 text-2xl'>
          {activeTicketGroup?.categoryName.replace(/(tickets|Tickets|ticket|Ticket)$/, '')} Tickets
        </p>
        <div className='flex gap-4'>
          <Select
            defaultValue='recent'
            onChange={handleSortChange}
            className='w-40'
          >
            <Option value='recent'>Sort by Recent</Option>
            <Option value='price'>Sort by Price</Option>
          </Select>
        </div>
      </div>

      <div className='flex flex-col gap-4 mb-5'>
        {(eventTicket ?? []).map((seller, index) => {
          console.log(seller)
          return (
            <>
              <Collapse
                key={seller._id}
                className='mb-4'
                style={{
                  borderRadius: '12px',
                  border:"1px solid #F0F0F0",
                  background: "#FFF",
                }}    
                expandIconPosition={'end'}
                bordered={false}                
                ghost={true}
                items={[
                  {
                    key: '1',
                    // showArrow: false,
                    label: (
                      <button
                        className={`flex w-full`}
                        onClick={() => handleSellerClick(seller)}
                      >
                        <div
                          className={`${!seller.user.picture ||
                              seller.user.picture.length < 10
                              ? ''
                              : 'w-12 mr-2'
                            } h-12 sm:w-8 sm:h-8 rounded-lg `}
                          style={{
                            backgroundImage: `url("${seller.user.picture}")`,
                            backgroundSize: 'cover',
                          }}
                        />
                        {!seller.user.picture ||
                          seller.user.picture.length < 10 ? (
                          <div
                            className={`w-12 h-12 mr-2 rounded-lg overflow-hidden`}
                          >
                            <Avatar name={seller.user.name} size={48} />
                          </div>
                        ) : null}
                        <div className='flex flex-col gap-1 ml-4 flex-grow text-left text-[#101010]'>
                          <p className={` text-base font-bold mb-1`}>
                            {seller.tickets} ticket
                          </p>
                          <p className={`lh-1 text-sm text-[#101010]`}>
                            {seller.ticketCategoryName}
                          </p>
                        </div>
                        <div className='flex flex-col justify-center items-center'>
                          <p className='font-bold text-sm text-[#009D64]'>
                            ₹{seller.ticketPrice}
                          </p>
                          <p className='text-sm'>per ticket</p>
                        </div>
                      </button>
                    ),
                    children: (
                      <div>
                        <div>
                          <div className='flex flex-col gap-1'>
                            <div className='flex justify-between'>
                              <p className='text-sm font-bold'>
                                {seller.user.name}
                              </p>
                              <p className='text-sm text-right'>
                                Listed on{' '}
                                {formatDateTime(seller.createdDate, 'MMM D')}
                              </p>
                            </div>
                            <div className='mt-2 flex flex-row gap-4  md:gap-3 md:flex-row'>
                              {false ? (
                                <div className='flex gap-1'>
                                  <span
                                    className='text-[#009D64]'
                                    style={{ marginTop: '-0.25rem' }}
                                  >
                                    <img
                                      src='/images/icons/verified.svg'
                                      alt='phoneVerified'
                                      loading='lazy'
                                    />
                                  </span>
                                  <p className='text-sm'>Phone verified</p>
                                </div>
                              ) : (
                                <></>
                              )}
                              {isVerification ? (
                              <>
                                <div className='flex gap-1'>
                                  <span
                                    className='text-[#009D64]'
                                    style={{ marginTop: '-0.25rem' }}
                                  >
                                    <img
                                      src='/images/icons/verified.svg'
                                      alt='mailVerified'
                                      loading='lazy'
                                    />
                                  </span>
                                  <p className='text-sm'>User verified </p>
                                </div>
                                 <div className='flex gap-1'>
                                  <span
                                    className='text-[#009D64]'
                                    style={{ marginTop: '-0.25rem' }}
                                  >
                                    <img
                                      src='/images/icons/verified.svg'
                                      alt='mailVerified'
                                      loading='lazy'
                                    />
                                  </span>
                                  <p className='text-sm'>Tickets verified </p>
                                </div>
                              </>
                              ) : (
                                <div className='flex gap-1'>
                                  <span
                                    className='text-[#009D64]'
                                    style={{ marginTop: '-0.25rem' }}
                                  >
                                    <img
                                      src='/images/icons/verified.svg'
                                      alt='mailVerified'
                                      loading='lazy'
                                    />
                                  </span>
                                  <p className='text-sm'>User verified </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <HStack
                          mt={3}
                          fontWeight='semibold'
                          color='white'
                          py={3}
                          rounded='md'
                          cursor='pointer'
                          onClick={() =>
                            handleGetInTouch(
                              String(seller.user.mobile),
                              seller.user.name,
                              seller,
                              event.eventName,
                              activeTicketGroup.categoryName,
                              seller.user._id,
                              index
                            )
                          }
                          gap={2}
                          justifyContent='center'
                          bg='brand.500'
                        >
                          <img
                            src='/images/icons/whatsapp.svg'
                            className='w-5 h-5'
                            alt='wpIcon'
                          />
                          <span> Get in touch </span>
                        </HStack>
                      </div>
                    ),
                  },
                ]}
              />
            </>
          );
        })}
      </div>
      {/* <AvatarCard /> */}

      {isGetInTouchClicked && (
        <PopupScreen
          title="Just a heads up ✨"
          onClose={handlePopupClose}
          onGetInTouch={handleGetInTouch}
          setTermsCheck={setTermsCheck}
          setSelectedSeller={selectedSeller}
          userData={userData}
        />
      )}
    </div>

  );
};

const mapStateToProps = (state) => ({
  ticket: state.ticket,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  fetchTicketData,
  fetchUnAuthTicketData,
  getInTouchClickAnalytics,
})(Buyer);