import {
  Box,
  Button,
  CloseButton,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  HStack,
  IconButton,
  Text,
  VStack,
  useDisclosure,
  Divider,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import useLoginModal from '../../../Hooks/useLoginModal';
import { connect } from 'react-redux';
import { userLogout } from '../../../redux/actions/auth';
import { BsFillCircleFill } from 'react-icons/bs';
import MobileBanner from '../../Home/MobileBanner';

const profile = [
  {
    label: 'Listed Tickets',
    url: '/my-listing',
    icon: "/images/icons/my_listing.svg",
  },
  {
    label: 'Profile',
    url: '/my-profile',
    icon: "/images/icons/profile.svg",
  },
  {
    label: 'Support',
    url: 'mailto:rescue@takemytickets.co.in',
    icon: "/images/icons/support.svg",
  },
];

const MobileNavbar = ({ currentUser, userLogout }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef(null);
  const navigate = useNavigate();
  const loginModal = useLoginModal();

  const handleSellTickets = () => {
    if (currentUser) {
      return navigate('/sell-tickets');
    } else {
      loginModal.onOpen();
      onClose();
      // User is not signed in, open the Login modal
      // Implement the logic to open the Login modal here
    }
  };

  const handleLoginClick = () => {
    loginModal.onOpen();
    onClose();
  };

  const labelStyles = {
    color: 'var(--black, #101010)',
    fontFamily: 'General Sans',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
  };

  const iconStyles = {
    display: 'flex',
    width: '20px',
    height: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px'
  };

  return (
    <>
      <IconButton
        icon={<HamburgerIcon />}
        variant='unstyled'
        color='black'
        onClick={onOpen}
        ref={btnRef}
        size='lg'
      />
      <Drawer
        isOpen={isOpen}
        placement='bottom'
        onClose={onClose}
        finalFocusRef={btnRef}
        size='full'
      >
        <DrawerOverlay />
        <DrawerContent width='100vw' height='86vh'>
          <Box
            zIndex={999}
            paddingY={2}
            paddingX={2}
            height='90vh'
            bg='white'
            textAlign='center'
          >
            <HStack justifyContent='space-between'>
              <img src='/images/logo.png' alt='logo' width={56} height={56} />
              <CloseButton
                size='lg'
                color='brand.500'
                variant='ghost'
                onClick={onClose}
              />
            </HStack>
            {!currentUser ? (
              <Button
                bg='#F0F0F0'
                my={4}
                width='90%'
                onClick={handleLoginClick}
                borderRadius={50}
                paddingX={4}
                paddingY={6}
              >
                Login/Signup
              </Button>
            ) : (
              <VStack px={4} alignItems='flex-start'>
                <Button
                  mb={2}
                  color='brand.500'
                  variant='unstyled'
                  display='flex'
                  alignItems='center'
                  leftIcon={<BsFillCircleFill />}
                >
                  <Text fontWeight='semibold' fontSize='lg'>
                    {currentUser}
                  </Text>
                </Button>
                {profile.map((item, index) => {
                  // If the label is not "Support", use the original Button
                  if (item.label !== 'Support') {
                    return (
                      <HStack
                        key={index}
                        to={item.url}
                        as={RouterLink}
                        mt={2}
                      >
                        <img src={item.icon} style={iconStyles} />
                        <Text style={labelStyles}>
                          {item.label}
                        </Text>
                      </HStack>
                    );
                  }

                  // If the label is "Support", open a mailto link in the same tab
                  else {
                    return (
                      <HStack
                        key={index}
                        to={item.url}
                        as={RouterLink}
                        onClick={() => (window.location.href = item.url)}
                        mt={2}
                      >
                        <img src={item.icon} style={iconStyles} />
                        <Text style={labelStyles}>
                          {item.label}
                        </Text>
                      </HStack>
                    );
                  }
                })}
                <HStack
                  textAlign='left'
                  variant='unstyled'
                  mt={2}
                  mb={4}
                  onClick={() => userLogout()}
                >
                  <img src="/images/icons/logout.svg" style={iconStyles} />
                  <Text style={labelStyles}>
                    Log Out
                  </Text>
                </HStack>
              </VStack>
            )}
            <Button
              colorScheme='brand'
              variant='solid'
              width='90%'
              borderRadius={50}
              paddingX={4}
              paddingY={6}
              onClick={handleSellTickets}
            >
              Sell Tickets
            </Button>
            <MobileBanner
              id="Create_event_Mobile" 
              Announcement={false} 
              heading="Looking to create an event?"
              subtitle="We offer the lowest fees in the market"
              label="Create an event"
            />
          </Box>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default connect(null, {
  userLogout,
})(MobileNavbar);