import React from 'react';
import styles from "./home.module.css";

import FeatureCard from './FeatureCard';

const features = [
    {
        imgUrl: "/images/icons/wallet.svg",
        alt: "wallet",
        title: "Face Value Only",
        text: "We guarantee that tickets are sold strictly for the price they have been bought for or even less."
    },
    {
        imgUrl: "/images/icons/ticket.svg",
        alt: "ticket",
        title: "Authentic Ownership",
        text: "We rigorously check that sellers are the true owners of the tickets, ensuring your peace of mind."
    },
    {
        imgUrl: "/images/icons/shield-search.svg",
        alt: "shield-search",
        title: "Duplication Prevention",
        text: "Our checks ensure that each ticket is listed only once, guaranteeing a safe buying opportunity."
    }
]

const Verification = () =>{
    return (
        <div className={styles.verify_Container}>
            <div className={styles.verify_HeaderContainer}>
                <h1 className={styles.verify_Header}>Authentic tickets at Original Price: {window.innerWidth > 600 ? <br/>:<></>}Secure, Verified, and Fair</h1>
            </div>
            <div className={styles.verify_Main}>

                <div className={styles.verify_features}>
                    {features.map((feature,key) => (
                        <FeatureCard
                            key={key} 
                            imgUrl={feature.imgUrl}
                            alt={feature.alt}
                            title={feature.title}
                            text={feature.text}
                        />
                        ))
                    }   
                </div>

                <div className={styles.verify_ticket}>      
        
                        <div className={styles.verify_success}>
                            <img src="/images/icons/green_tick.svg" alt="green_tick" className={styles.verify_successImg}/>
                            <p className={styles.verify_successText}>Wohoo! Your tickets have been verified!</p>
                        </div>

                        <div className={styles.verify_event}>
                            <img src="/images/banner/Ed.svg" alt="" className={styles.verify_eventImg}/>
                        </div>
                </div>
            </div>
        </div>
    );
}

export default Verification;