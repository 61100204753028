import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import Layout from '../Components/Layout/Layout';
import Banner from '../Components/Home/Banner';
import Banner2 from '../Components/Home/Banner2';
import Trending from '../Components/Home/Trending';
import Verification from '../Components/Home/Verification';
import Transfer from '../Components/Home/Transfer';
import Testimonials2 from '../Components/Home/Testimonials2';
import GetInTouch from '../Components/Home/GetInTouch';
import Alert, { notify } from '../Components/Home/Alert';

import { fetchTrendingAllEvents } from '../redux/actions/event';
import { useLocation, useNavigate } from 'react-router-dom';
import useLoginModal from '../Hooks/useLoginModal';
import { authenticateUser } from '../Components/modals/apiUtils';
import { loadUser, registerClientJWT } from '../redux/actions/auth';

//Import Mixpanel SDK
import mixpanel from 'mixpanel-browser';
 
// Near entry of your product, init Mixpanel
mixpanel.init('9f9100eda75af605c29dd74de8b8ebb9', 
  {debug: true, track_pageview: true, persistence: 'localStorage'}
);

const autenticate = (token) => {

};

const HomeScreen = ({ auth: { userData }, fetchTrendingAllEvents, loadUser, registerClientJWT }) => {
  const location = useLocation();
  const loginModal = useLoginModal();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const showToast = params?.get('showToast');
  const token = params?.get('token');

  useEffect(() => {
    fetchTrendingAllEvents(true);
    if (showToast === 'true') {
      notify('Request Submitted');
    }
  }, [showToast]);

  useEffect(() => {
    if (token) {
      authenticateUser(token).then((data) => {
        if (data?.data?.token) {
          registerClientJWT(data.data.token);
          loadUser();
        } else {
          navigate('/');
        }
      });
    }
  }, [token]);

  useEffect(() => {
    if (userData?.email && token) {
      if (!userData?.name) loginModal.onOpen();
    }
  }, [userData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // const handleRequest=() =>{
  //   notify();
  // }

  return (
    <div>
      <Layout>
        <Helmet>
          <title>TakeMyTickets: Buy and sell concert tickets in India</title>
          <meta name='robots' content='index, follow' />
          <meta
            name='description'
            content='Discover Take My Tickets, the leading ticket resale website in India. Buy and sell event tickets securely and conveniently.'
          />
        </Helmet>
        <Alert />
        <div className='min-h-screen overflow-hidden'>
          <Banner />
          <Banner2/>
          <Trending />
          <Verification />
          <Transfer />
          <Testimonials2 />
          <GetInTouch />
        </div>
      </Layout>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  fetchTrendingAllEvents,
  loadUser,
  registerClientJWT,
})(HomeScreen);
