import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './seller.module.css';
import { analyticEventLogger } from '../../Analytics/eventLogger';
import { SELLER_FLOW_PROCEED_EVENT_CLICK } from '../../Analytics/eventLogConstants';
import axios from '../../axios-api'
import setMixPanel from '../../utils/setMixPanel';

const PopupScreen = ({ onClose, onCreateListing, title, message, setTermsCheck, userData, listTicketPrePostData, sellerFlowEvent}) => {

  const navigate = useNavigate();
  const handleClose = () => {
    onClose();
    // navigate('/account-details');
  };
  const [checked, setChecked] = useState(false);
  const [createListingClicked, setcreateListingClicked] = useState(false);

  const handleCheckboxChange = (e) => {
    setTermsCheck(e.target.checked);
    setChecked(e.target.checked);
  };

  const confirmCreateListing = async () => {   
    setcreateListingClicked(true)    
    analyticEventLogger(SELLER_FLOW_PROCEED_EVENT_CLICK, {
      termsChecked: checked      
    });
    if (checked) {
      const advance = await onCreateListing();      
      if(advance){
        navigate('/listing-landing'); 
      }
    }
  };
  useEffect(() => {
      console.log(createListingClicked);
  }, [createListingClicked]);

  return (
    <div className={styles['popup-container']}>
      <div className={styles.popup}>
        <button className={styles.closeButton} onClick={handleClose}>
          <img src="/images/icons/x.svg" alt="Close" className='object-cover md:w-8 md:h-8 w-6 h-6 ' />
        </button>
        <h2 className={styles['popup-title']}>{title}</h2>
        <div className={styles['popup-message']} >
        <img src="/images/icons/tickett.svg" alt="" className='mt-8 self-center border-8 rounded-full border-white '/>
        <div>
        <div className='flex text-lg text-[#101010] font-semibold leading-5'>Say no to screenshots!</div>
        <div className='md:text-base text-md text-[#101010] font-sm '>Make sure you transfer the tickets instead of screenshots. <a className=' text-brand font-sm text-sm md:text-md inline underline ' href="https://linktr.ee/take_my_tickets" target="_blank" rel = "noopener norefferer">Learn how to transfer tickets.</a></div>
        </div>
        </div>
       
        <div className={styles['popup-checkbox']}>
          <input type="checkbox" id="terms" className='w-6 h-6' style={{width: '150px', height: '24px'}} onChange={handleCheckboxChange} />
          <label style={{fontSize: '14px'}} htmlFor='terms'  className='text-[#101010]'>
          I acknowledge and accept the <a className='text-brand underline ' style={{fontSize: '14px'}} href="/terms-of-service" target="_blank" rel = "noopener norefferer">Terms and Conditions</a>{''} and{' '}
            <a className='text-brand underline' style={{fontSize: '14px'}} href="/privacy-policy" target="_blank" rel="noopenner noreferrer">Privacy Policy</a>  I confirm to transfer tickets to the buyer upon payment; non-compliance may result in legal action.
          </label>
        </div>
        <button className={`${styles['popup-button']} ${styles['sec-button']} ${!checked ? styles.disabled : ''}`} onClick={confirmCreateListing} disabled={!checked || createListingClicked}>
            {createListingClicked ? "Listing..." : "List my tickets"}
        </button>
      </div>
    </div>
  );
};

export default PopupScreen;