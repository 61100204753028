import {
    FETCH_CITY_LIST
} from '../actions/types';

import axios from '../../axios-api';

export const fetchCityList = () => async dispatch => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
            
        const res = await axios.get(`/v1/venue/city`, config);

        dispatch({
            type: FETCH_CITY_LIST,
            payload: res.data.data
        });
    } catch (err){
        console.log(err);
    }
}