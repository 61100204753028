import React from "react";
import Search from "../Search";
import Marquee from "./Marquee";

export const dummySearchData = [
  {
    eventName: "Martin Garrix Bengaluru",
    eventDateTime: "2023-08-27T19:30:00+05:30",
    eventLocation: "Rajaji Hall, Chennai",
    eventImageUrl: "https://placehold.co/400x500?text=New+Event&font=roboto",
    eventSlug: "martin-garrix-bengaluru",
  },
  {
    eventName: "Martin Garrix Bengaluru",
    eventDateTime: "2023-08-27T19:30:00+05:30",
    eventLocation: "Rajaji Hall, Chennai",
    eventImageUrl: "https://placehold.co/400x500?text=New+Event&font=roboto",
    eventSlug: "martin-garrix-bengaluru",
  },
  {
    eventName: "Martin Garrix Bengaluru",
    eventDateTime: "2023-08-27T19:30:00+05:30",
    eventLocation: "Rajaji Hall, Chennai",
    eventImageUrl: "https://placehold.co/400x500?text=New+Event&font=roboto",
    eventSlug: "martin-garrix-bengaluru",
  },
  {
    eventName: "Martin Garrix Bengaluru",
    eventDateTime: "2023-08-27T19:30:00+05:30",
    eventLocation: "Rajaji Hall, Chennai",
    eventImageUrl: "https://placehold.co/400x500?text=New+Event&font=roboto",
    eventSlug: "martin-garrix-bengaluru",
  },
];

function Banner() {
  return (
    <div className="relative bg-[#FFF2F2] w-full h-[420px] md:h-[680px]  text-center  text-white sm:z-10">
      <div className="absolute top-[0px] items-center justify-center w-full h-[535px] md:h-[540px] text-center lg:text-[60px]  ">
        {/* <img
          className="absolute left-0 w-full h-[525px] md:h-[700px] overflow-hidden object-cover"
          alt="India's Premier Ticket Resale Platform | Buy & Sell Event Tickets Online | Take My Tickets"
          src="/images/banner/landingPageBanner.png"
          loading="lazy"
        
        /> */}
        <div className="items-center justify-center">
          <div className="absolute top-23 left-1/2 transform -translate-x-1/2  top-[118px] justify-center flex flex-col items-center gap-[36px] w-full px-5 md:w-auto md:px-0 md:top-1/3">
            <div className="flex flex-col items-center justify-start w-full md:w-auto">
              <div
                className="relative text-center color-white text-black leading:10 tracking-[-0.02em ] leading-[120%] font-semibold  font-general-sans  inline-block xl:w-[700px] lg:w-[650px] w-[320px]  lg:text-6xl text-[32px]"
              >
                <span>
                  <span className="font-architects text-[#FF2459]">Buy</span> and{" "}
                  <span className="font-architects text-[#FF2459]">Sell</span> extra
                </span>
                {/* <span className="lg:hidden">Buy and Sell</span> */}
                {/* <br className="lg:hidden" />
                <span className="lg:hidden ml-6"> extra</span>{" "} */}
                <br/>
                <span className="">
                  tickets in a Snap{" "}
                  <img src='/images/icons/exm.svg' alt='!' style={{display:"inline-block"}} className="w-4 md:w-auto"/>
                </span>
              </div>
              <div className="relative not-italic py-4 color-white lg:text-lg text-sm md:text-[20px] text-[#101010] tracking-[-0.02em] leading-[150.02%] w-[240px] lg:w-full lg:left-4 font-normal font-general-sans">
                Don't skip the fun. It's simple and quick.
              </div>
              <div className="mt-4 w-full md:w-auto">
                <Search />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Marquee 
        text={ ["India’s first and only", "✨"] }
        color="#FF2459" 
      />
    </div>
  );
}

export default Banner;
