import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc)
dayjs.extend(timezone)

export const formatDateTime = (dateTime, formatString) => {
    return dayjs.utc(dateTime).tz(dayjs.tz.guess()).format(formatString)
}
