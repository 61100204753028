import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, Flex, Text, VStack } from '@chakra-ui/react';
import { connect } from 'react-redux';
import useLoginModal from '../../../Hooks/useLoginModal';

const quicklinks = [
  {
    name: 'Browse Events',
    link: '/all-events',
  },
  {
    name: 'Sell tickets',
    link: '/sell-tickets',
  },
];
const help = [
  {
    name: 'Contact Us',
    link: 'mailto:rescue@takemytickets.co.in',
  },
  {
    name: 'Request an Event',
    link: '/request',
  },
];

const about = [
  {
    name: 'Why TMT?',
    link: '/why-tmt',
  },
  {
    name: 'Terms of service',
    link: '/terms-of-service',
  },
  {
    name: 'Privacy policy',
    link: '/privacy-policy',
  },
];

const followUs = [
  {
    name: 'Twitter',
    link: 'https://twitter.com/takemy_tickets',
    icon: <img src='/images/icons/footer_twitter.svg' alt='Twitter' />,
  },
  {
    name: 'Instagram',
    link: 'https://www.instagram.com/takemytickets.co.in/',
    icon: <img src='/images/icons/footer_insta.svg' alt='Instagram' />,
  },
  // {
  //   name: "Linkedin",
  //   link: "https://www.linkedin.com/company/takemytickets/",
  //   icon: <img src="/images/icons/footer_linkedin.svg" alt="LinkedIn" />,
  // },
];

const Footer = ({ auth: { userData } }) => {
  const loginModal = useLoginModal();
  const navigate = useNavigate();
  const handleSellTickets = () => {
    if (userData?.name) {
      console.log(userData.name);
      return navigate('/sell-tickets');
    } else {
      loginModal.onOpen();
    }
  };

  return (
    <Box py={8} width='100%' px={{ base: '24px', md: 12, lg: '130px' }} bg='#101010'>
      <Flex direction={{ base: 'column', lg: 'row' }}>
        <Flex py={4} flex={1}>
          <Link to='/'>
            <img
              className='w-[80px] object-contain h-[80px]'
              style={{ width: '144px', height: '96px' }}
              src='/images/white_logo.svg'
              alt="India's Premier Ticket Resale Platform | Buy & Sell Event Tickets Online | Take My Tickets"
            />
          </Link>
        </Flex>
        <Flex py={4} flex={1}>
          <VStack gap={4} alignItems='flex-start' flex={1}>
            <Text
              style={{
                color: 'var(--secondary-grey, #717171)',
                fontFamily: 'General Sans',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
                letterSpacing: '0.48px',
                textTransform: 'uppercase',
              }}
            >
              QUICK LINKS
            </Text>
            {quicklinks.map((link, index) => (
              <Link
                key={index}
                to={link.link}
                onClick={() => {
                  if (link.link === '/sell-tickets') {
                    handleSellTickets(userData, loginModal, navigate);
                  }
                }}
                style={{
                  color: 'var(--white, #FFF)',
                  fontFamily: 'General Sans',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px',
                }}
              >
                {link.name}
              </Link>
            ))}
          </VStack>
          <VStack gap={4} alignItems='flex-start' flex={1}>
            <Text
              style={{
                color: 'var(--secondary-grey, #717171)',
                fontFamily: 'General Sans',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
                letterSpacing: '0.48px',
                textTransform: 'uppercase',
              }}
            >
              HELP
            </Text>
            {help.map((link, index) => (
              <Link
                key={index}
                to={link.link}
                style={{
                  color: 'var(--white, #FFF)',
                  fontFamily: 'General Sans',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px',
                }}
              >
                {link.name}
              </Link>
            ))}
          </VStack>
        </Flex>
        <Flex py={4} fontWeight='medium' flex={1}>
          <VStack gap={4} alignItems='flex-start' flex={1}>
            <Text
              style={{
                color: 'var(--secondary-grey, #717171)',
                fontFamily: 'General Sans',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
                letterSpacing: '0.48px',
                textTransform: 'uppercase',
              }}
            >
              ABOUT
            </Text>
            {about.map((link, index) => (
              <Link
                to={link.link}
                key={index}
                style={{
                  color: 'var(--white, #FFF)',
                  fontFamily: 'General Sans',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px',
                }}
              >
                {link.name}
              </Link>
            ))}
          </VStack>
          <VStack gap={1} alignItems='flex-start' flex={1}>
            <Text
              style={{
                color: 'var(--secondary-grey, #717171)',
                fontFamily: 'General Sans',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
                letterSpacing: '0.48px',
                textTransform: 'uppercase',
              }}
            >
              FOLLOW US
            </Text>
            {followUs.map((link, index) => (
              <a
                href={link.link}
                target='_blank'
                rel='noopener noreferrer'
                key={index}
              >
                <Button
                  style={{
                    color: 'var(--white, #FFF)',
                    fontFamily: 'General Sans',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  variant='unstyled'
                  leftIcon={link.icon}
                >
                  {link.name}
                </Button>
              </a>
            ))}
          </VStack>
        </Flex>
      </Flex>
    </Box>
  );
};

const mapStateToProps = (state) => ({ auth: state.auth });

export default connect(mapStateToProps, {})(Footer);
