import React from 'react';
import styles from "./listing.module.css";

const TicketList = ({ ticketCount, category, ticketCost }) => {
  return (
    <div className={styles.ticketList}>
      <div className={styles.count}>
        <p className={styles.ticcount}>{ticketCount} ticket{ticketCount > 1 ? 's' : ''}</p>
        <p className={styles.category}>{category}</p>
      </div>

      <div className={styles.cost}>
        <p className={styles.ticketCost}><span>₹</span>{ticketCost}</p>
        <p className={styles.tick}>per ticket</p>
      </div>
    </div>
  );
};

export default TicketList;
