import React from 'react';

import styles from "./home.module.css";

const Marquee = ({text,color,deg,bottom}) => {

    const marqueeText = [];

    if(window.innerWidth < 600 ){
        for(let i = 0; i <= 0; i++) {
            marqueeText.push(text[1]);
            marqueeText.push(text[0]);
            marqueeText.push(text[1]);
        } 
    }else{
        for(let i = 0; i <= 5; i++) {
            marqueeText.push(text[0]);
            marqueeText.push(text[1]);
        }
    }

    return (
        <div 
            className={styles.marquee} 
            style={{background:`${color}`,transform:`rotate(${deg}deg)`, bottom:`${bottom}px`}}
        >
            <div className={styles.marqueeTextContainer}>
                {marqueeText.map((text,index)=>
                    <p key={index} className={styles.marqueeText}>{text}</p>
                )}  
            </div>
            <div className={styles.marqueeTextContainer}>
                {marqueeText.map((text,index)=>
                    <p key={index} className={styles.marqueeText}>{text}</p>
                )}  
            </div>
        </div>
    );
}

export default Marquee;