//Buyer Event logger
export const GET_IN_TOUCH_EVENT_LOG = "get_in_touch";
export const LOCATION_BUYER_PAGE_EVENT_CLICK = "LOCATION_BUYER_PAGE_EVENT_CLICK";
export const EVENT_NOTIFIER_SUBSCRIBE_EVENT_CLICK = "EVENT_NOTIFIER_SUBSCRIBE_EVENT_CLICK";
export const EVENT_NOTIFIER_UNSUBSCRIBE_EVENT_CLICK = "EVENT_NOTIFIER_UNSUBSCRIBE_EVENT_CLICK";

//Auth Event logger
export const LOGIN_EVENT_CLICK = "LOGIN_EVENT_CLICK";

//Seller Flow Event Logger
export const SELLER_TICKET_NAVBAR_EVENT_CLICK = "SELLER_TICKET_NAVBAR_EVENT_CLICK";
export const SELLER_TICKET_EVENTPAGE_EVENT_CLICK = "SELLER_TICKET_EVENTPAGE_EVENT_CLICK";
export const SELLER_TICKET_MYLISTING_EVENT_CLICK = "SELLER_TICKET_MYLISTING_EVENT_CLICK";
export const SELLER_FLOW_PROCEED_EVENT_CLICK = "SELLER_FLOW_PROCEED_EVENT_CLICK"
export const SELLER_FLOW_CREATE_LISTING_EVENT_CLICK = "SELLER_FLOW_CREATE_LISTING_EVENT_CLICK"

//Ticket Lisitng
export const TICKET_MARK_AS_SOLD_EVENT_CLICK = "TICKET_MARK_AS_SOLD_EVENT_CLICK";
export const TICKET_DELETE_EVENT_CLICK = "TICKET_DELETE_EVENT_CLICK"
export const TICKET_UPDATE_EVENT_CLICK = "TICKET_UPDATE_EVENT_CLICK"