import React, { useEffect } from "react";
import { connect } from "react-redux";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import HomeScreen from "./Screens/HomeScreen";
import LocationModal from "./Components/modals/LocationModal";
import { Route, Routes } from "react-router-dom";
import MenuModal from "./Components/modals/MenuModal";
import Profile from "./Components/Profile/Profile";
import Listing from "./Components/Listing/Listing";
import Seller from "./Components/Seller/Seller";
import PriceTicketForm from "./Components/Seller/PriceTicketForm";
import AccountDetails from "./Components/Seller/AccountDetails";
import ListingLanding from "./Components/Seller/ListingLanding";
import Request from "./Screens/Request";
import AllEvents from "./Screens/AllEvents";
import Event from "./Screens/Event";
import { fetchCityList } from "./redux/actions/venue";
import { fetchTrendingAllEvents } from "./redux/actions/event";
import { loadUser } from "./redux/actions/auth";
import Login from "./Components/modals/Login";
import setAuthToken from "./utils/setAuthToken";
import PrivacyPolicy from "./Screens/PrivacyPolicy";
import TermsAndConditions from "./Screens/TermsAndConditions";
import PrivateRoutes from "./utils/PrivateRoutes";
import SellTicketForm from "./Components/Seller/SellTicketForm";
import SellerForm from "./Components/Seller/SellerForm";
import NotFound from "./Screens/NotFound";
import SearchScreen from "./Components/Home/SearchScreen";
import { registerClientJWT } from "./redux/actions/auth";
import WhyTMT from "./Screens/WhyTMT";

import { Navigate, useNavigate } from "react-router-dom";
import Verifyticket from "./Components/Verify/VerifyTicket";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const firebaseConfig = {
  apiKey: "AIzaSyCGYlWZDDyVIwQBcU1NawNrXpPD8ZyEsJY",
  authDomain: "mvp-tmt.firebaseapp.com",
  projectId: "mvp-tmt",
  storageBucket: "mvp-tmt.appspot.com",
  messagingSenderId: "594858045527",
  appId: "1:594858045527:web:b1fd17966bd8fcfe5af312",
  measurementId: "G-K1PV700BXD",
};

const App = ({
  fetchCityList,
  fetchTrendingAllEvents,
  loadUser,
  registerClientJWT,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);

    fetchCityList();
    loadUser();

    if (localStorage.token) {
      registerClientJWT(localStorage.token);
    }
  }, []);
  return (
    <>
      <LocationModal />
      <MenuModal />
      <Login />

      <Routes>
        <Route path="/" element={<HomeScreen />} />
        {/* <Route element={<PrivateRoutes/>}> */}
        <Route
          path="/sellerform"
          element={<PrivateRoutes children={<SellerForm />} />}
        />
        <Route
          path="/seller-form/:slug"
          element={<PrivateRoutes children={<SellTicketForm />} />}
        />
        <Route
          path="/verify-ticket/:selectedPlatform"
          element={<PrivateRoutes children={<Verifyticket />} />}
        />
        <Route
          path="/price-details"
          element={<PrivateRoutes children={<PriceTicketForm />} />}
        />
        <Route path="/search" element={<SearchScreen />} />
        {/* // <Route path='/:city' element={<HomeScreen />} /> */}
        <Route path="/:city/:slug" element={<Event />} />
        <Route
          path="/my-profile"
          element={<PrivateRoutes children={<Profile />} />}
        />
        <Route
          path="/my-listing"
          element={<PrivateRoutes children={<Listing />} />}
        />
        <Route
          path="/sell-tickets"
          element={<PrivateRoutes children={<Seller />} />}
        />
        <Route path="/why-tmt" element={<WhyTMT />} />
        <Route
          path="/account-details"
          element={<PrivateRoutes children={<AccountDetails />} />}
        />
        <Route
          path="/listing-landing"
          element={<PrivateRoutes children={<ListingLanding />} />}
        />
        <Route path="/request" element={<Request />} />
        <Route path="/all-events" element={<AllEvents />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsAndConditions />} />

        {/* </Route> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default connect(null, {
  fetchCityList,
  fetchTrendingAllEvents,
  loadUser,
  registerClientJWT,
})(App);
