import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../Layout/Layout';
import Header from './Header';
import Search from './Search';
import styles from './seller.module.css';

const Seller = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const head = "Select event to sell tickets";

  const handleEventSelection = (event) => {
    setSelectedEvent(event);
  };

  const handleClearSelection = () => {
    setSelectedEvent(null);
  };

  return (
    <Layout className={styles.backgroundLayout}>
        <Helmet>
          <title>Sell tickets</title>
          <meta
            name="title"
            content="Sell Tickets Easily in India | Reach Your Audience on Take My Tickets"
          />
           <meta
            name="description"
            content="Start selling your event tickets hassle-free on Take My Tickets, India's trusted ticket resale platform. Add seller contact details effortlessly."
          />
          <meta charset="UTF-8" />
        </Helmet>

        <div className={`${styles.second}`}  style={{height:`${window.innerWidth > 600? '620px':'auto'}`}}>
          <div className={styles.searchContainer}>
            <Header head={head} showParty={false} />
            <Search key="search" onEventSelect={handleEventSelection} trigger={true}/>
          </div>
        </div>
    </Layout>
  );
};

export default Seller;
