import { Button, Container, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./listing.module.css";
import { analyticEventLogger } from "../../Analytics/eventLogger";
import { SELLER_TICKET_MYLISTING_EVENT_CLICK } from "../../Analytics/eventLogConstants";

const NoListing = () => {
  const navigate = useNavigate();

  return (
    <>
  
    <div className={styles.nolistcontainer}>
   
      <div className={styles.nolisttext}>
        <h4 className={styles.nolisthead}> No active listings!</h4>
        <p className={styles.nolistcontent}>List your spare tickets now and make a fan's day! 🤙</p>
         
      <Button onClick={() => {
          analyticEventLogger(SELLER_TICKET_MYLISTING_EVENT_CLICK, {});
          navigate("/sell-tickets")
          }} colorScheme="brand" className={styles.buttonnolist}>
        Sell Tickets
      </Button>
        </div>
        <img src="images/nolist.png" alt="no list" className={styles.nolistimg}/>
     
    </div>
    </>
   
  );
};

export default NoListing;
