import React from "react";
import { WarningTwoIcon } from "@chakra-ui/icons";
import styles from './Input.module.css';

const InputWithLabel = ({ label, className, key, value, onChange, type, disabled, validationMsg }) => (
  <div className={`flex flex-col w-full gap-[8px] ${className}`}>
    <label className={styles.inputBoxLabel} for={key}>{label}</label>
    <input className={styles.inputBox} type={type} name={key} value={value} onChange={onChange} disabled={disabled} />
    {!!validationMsg && (
      <div className={styles.validationMsg}>
        <WarningTwoIcon boxSize={15} color="#DF1B41" />
        {validationMsg}
      </div>)}
  </div>
);

export default InputWithLabel;