import React from "react";
import { useNavigate } from "react-router-dom";

import Search from "../Search";

const SearchScreen = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <div className="h-full w-full flex flex-col pt-10 px-5">
      <button onClick={handleClick}>
        <img
          src="images/icons/back.svg"
          className=" rounded-md w-6 h-6"
          alt="back-icon"
        />
      </button>
      <div className="h-auto pt-6">
        <Search />
      </div>
    </div>
  );
};

export default SearchScreen;
