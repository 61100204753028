import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Alert,{notify} from "../Components/Home/Alert"

import Search from "../Components/Search";
import EventCard from "../Components/EventCard";
import Layout from "../Components/Layout/Layout";
import { fetchTrendingAllEvents } from "../redux/actions/event";
import styles from '../Components/Home/Trending.module.css'
import setMixPanel from "../utils/setMixPanel";

const AllEvents = ({
  event: { allTrendingEvents },
  fetchTrendingAllEvents,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchTrendingAllEvents(false);
  }, []);

  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const navigate = useNavigate();

  const handleSearchFocus = () => {
    setIsSearchFocused(true);
  };

  const handleSearchBlur = () => {
    setIsSearchFocused(false);
  };

  const navigateHandler = (city, slug) => {
    navigate(`/${city}/${slug}`);
  };

  // useEffect(() => {
  // }, []);

  return (
    <Layout>
      <div className="">
        <Helmet>
          <title>Events Around You</title>
          <meta
            name="title"
            content="Explore a Variety of Events in India | Buy Tickets on Take My Tickets| Take My Tickets"
          />
          <meta
            name="description"
            content="Find and buy tickets for concerts, sports events, music festivals, and more on India's top online ticket marketplace."
          />
        </Helmet>
        <Alert />
        <div className="pt-20 w-full flex flex-col items-center mb-8">
                   
          
          {allTrendingEvents.length > 0 ? (<>
            <div className="bg-white text-black px-4 py-2 rounded-full text-4xl font-semibold">
            Browse events
            <span className="ml-2" role="img" aria-label="eyes-emoji">
              👀
            </span>
          </div> 
            <div className="mt-4">
            <Search
              placeholder="Search by event"
              onFocus={handleSearchFocus}
              onBlur={handleSearchBlur}
              className={`border ${
                isSearchFocused ? "border-red-500" : "border-black"
              }`}
            />
          </div>
          <div className="grid gap-6 grid-cols-1 mt-10 mb-10 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full max-w-screen-xl px-4">
            {allTrendingEvents.map((event) => (
              <EventCard
                key={event.id}
                artistName={event.artistName}
                eventName={event.eventName}
                startPrice={event.startPrice}
                url={event.imageURL}
                eventSlug={event.eventSlug}
                eventDate={event.eventDate}
                eventCity={event.eventCity}
                onChange={navigateHandler}
              />
            ))}
          </div>
          </>
        ) : (
          <div className={styles.noeventscontainer}>
              <img src="/images/notrendingevents.svg" alt="Oops, no trending events near you!" className={styles.noeventsimage} />
              <div style={{margin_bottom: '30px', textAlign: 'center', fontSize: '20px', lineHeight: '140%'}}>Oops, no events near you!</div>
              <button
              className={styles.noeventsbutton}
              onClick={() => navigate('/request')}>
              Request an Event
            </button>
          </div>
        )}
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  event: state.event,
});

export default connect(mapStateToProps, {
  fetchTrendingAllEvents,
})(AllEvents);
