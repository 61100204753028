import React, { useEffect } from 'react';
import Layout from '../Components/Layout/Layout';
import { Link } from 'react-router-dom';
import Alert from "../Components/Home/Alert"

const NotFound = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
    <Alert />
      <div class='lg:px-20 lg:py-40 md:py-30 md:px-15 px-4 pt-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-6'>
        <div class=' w-full xl:w-1/2 relative pb-5 lg:pb-0'>
          <div class='relative'>
            <h1 class=' text-black font-bold lg:text-[56px] text-[28px]'>
              Like Vincent, you're lost. Page not found!
            </h1>

            <Link to='/'>
              <button class='sm:w-full lg:w-auto text-sm my-5 border border-brand  py-4 px-8 text-center text-brand font-semibold rounded-lg'>
                Back to homepage
              </button>
            </Link>
          </div>
        </div>
        <div class='lg:[w-600px] lg:h-[360px]'>
          <img
            src='/images/gif/nf.gif'
            alt='not-found'
            className='w-full h-full object-cover rounded'
          />
        </div>
      </div>
    </Layout>
  );
};

export default NotFound;
